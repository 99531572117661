@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "./scss/variables/variables.scss";

* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $bodyFontSize;
  color: $bodyFontColor;
}

body,
.k-card,
.k-listview,
.k-panelbar,
.k-window,
.k-grid,
.k-input,
.k-calender,
.k-button,
.k-textbox,
.k-dropdown,
.k-dropdowntree,
.k-multiselect {
  font-family: "Open Sans", sans-serif;
  font-size: $bodyFontSize;
}

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea,
input {
  font-size: 16px !important;
}

.k-multiselect {
  height: calc(1.5em + 0.875rem);

  .k-multiselect-wrap {
    >.k-searchbar {
      height: 2.088rem;

      >.k-input {
        padding: 0.294rem 0.75rem
      }
    }
  }
}

.back-icon {
  border-radius: 5px;
  cursor: pointer;
  width: 25px !important;
}

.download-icon.bg-icon-primary {
  background-color: #008db9;
  color: #fff;
  border-color: #008db9;

  &:hover {
    color: #d8d8d8;
  }
}

hr {
  border-top: 1px solid #d9d9d9;
}

.bg-white {
  background-color: #fff;
}

.bz-text-transform-default {
  text-transform: initial !important;
}

.k-form,
.k-form-inline {
  font-size: $bodyFontSize !important;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  background-color: #c9c9c9a6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008db9;

  >.d-flex {
    color: #008db9;
    z-index: 99999999999;

    .k-loader {
      margin-top: 0.25rem;
      color: #008db9;
    }
  }

}

//margin utils
.bz-label-mb-0 {
  margin-bottom: 0 !important;
}

.k-label.bz-label-mb-0 {
  margin-bottom: 0 !important;
}

.btn.btn-link {

  .h5,
  .h6 {
    font-size: $bodyFontSize;
  }
}

//height
.bz-h-20px {
  height: 1.25rem;
}

.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1.5rem;
  padding: 0;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }

  svg {
    font-size: 1.25rem;
    height: 1.25rem;
  }
}

.bz-radius-1 {
  border-radius: 0.325rem !important;
}

.bz-icon-button {
  color: #5a5c5f !important;
  text-decoration: none;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-size: 0.875rem;
  font-weight: 500;
  text-rendering: auto;
  line-height: 1;
  cursor: pointer;
  background: #fafbfb !important;
  padding: 0.54rem;
  vertical-align: middle;
  min-width: auto !important;

  &:hover {
    background-color: #dee2e6 !important;
  }
}

.bz-icon-button:focus {
  outline: none;
}

.bz-icon-button-md {
  font-size: 1.5rem;
}

.bz-icon-button-sm {
  font-size: 1rem;
}

.bz-icon-button {
  background-color: #fafbfb;
  border-color: #ced4da !important;
  border-radius: 0.25rem;
  color: #5a5c5f !important;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 10px !important;
  padding: 0.54rem;
  border-width: 0.0625rem;
  border-style: solid;
  margin: 0 !important;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  margin-left: -1px !important;
  vertical-align: middle;
}

.k-textbox,
.k-datetimepicker {
  height: 35px;
}

.k-maskedtextbox:focus-within,
.k-maskedtextbox:focus,
.k-maskedtextbox.k-state-focus,
.k-maskedtextbox.k-state-invalid:focus-within {
  box-shadow: none;
}

.k-dialog {
  >.k-dialog-titlebar {
    justify-content: space-between;
  }
}

//grid pagination css start
.k-pager.k-grid-pager {
  background-color: #ffffff;
  border: 0;
  padding: 0.307rem 0;
  border-radius: 0;

  .k-link {
    border: 0;
  }

  .k-label {
    display: flex;
    font-size: inherit !important;
    margin-bottom: 0 !important;
    color: #a6a6a6;
  }

  .k-pager-info {
    margin-right: 0;
  }

  .k-state-disabled.k-link {
    opacity: 0.45;
  }

  .k-dropdown-wrap {
    .k-input {
      cursor: pointer;
      padding: 0;
      justify-content: center;
    }

    .k-select {
      padding: 0.25rem;
    }
  }

  .k-link.k-pager-last {
    border-right-width: 0;
  }

  .k-pager-nav.k-pager-first+.k-link {
    border-right: 0;
  }

  .k-state-selected,
  .k-state-selected:link,
  .k-state-selected:visited {
    border: 0;
    box-shadow: none;
    width: 1.4rem;
    height: 1.4rem;
  }

  .k-pager-numbers {
    .k-link {
      border-radius: 0 !important;
    }
  }

  .k-pager-nav {
    border-radius: 0 !important;
  }
}

.k-pager-wrap.k-pager-lg .k-pager-numbers .k-link,
.k-pager-wrap.k-pager-lg .k-pager-numbers .k-state-selected,
.k-pager-wrap.k-pager-md .k-pager-numbers .k-link,
.k-pager-wrap.k-pager-md .k-pager-numbers .k-state-selected,
.k-pager-wrap.k-pager-sm .k-pager-numbers .k-link,
.bz-grid-pager.k-pager-sm .k-pager-numbers .k-state-selected {
  margin-bottom: 0.1rem;
  border-radius: 0 !important;
}

.k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded,
.k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded,
.k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded {
  border-radius: 0;
}

.k-pager-wrap.k-pager-lg .k-pager-numbers>.k-current-page>.k-link {
  border-bottom-width: 0.1875rem;
  border-bottom-style: solid;
  margin-bottom: 0;
}

.k-pager-wrap .k-link:hover,
.k-pager-wrap .k-nav-current.k-state-hover .k-link:hover {
  width: 1.4rem;
  height: 1.4rem;
}

.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-selected {
  border-radius: 0;
}

.k-popup .k-list .k-state-focused>.dropdown-header,
.k-popup .k-list .k-state-selected>.dropdown-header {
  border-radius: 0;
}

.k-popup .k-list .k-state-focused>.dropdown-header .payment-inner-icon,
.k-popup .k-list .k-state-selected>.dropdown-header .payment-inner-icon {
  font-size: 0.8125rem !important;
}

.k-pager-wrap .k-pager-numbers .k-link,
.k-pager-wrap .k-pager-numbers .k-state-selected,
.k-pager-wrap .k-pager-wrap>.k-link {
  min-width: 1.4rem;
  width: 1rem;
  border-radius: 0.2rem !important;
  height: 1.4rem;
}

.k-pager-wrap.k-grid-pager .k-pager-nav {
  min-width: 1.4rem;
  border-radius: 0.2rem !important;
  width: 1.4rem;
}

.k-pager-wrap .k-pager-wrap .k-link,
.k-pager-wrap .k-pager-wrap .k-state-selected {
  width: 1.4rem;
  height: 1.4rem;
}

.k-pager-wrap.k-pager-lg .k-pager-numbers-wrap,
.k-pager-wrap.k-pager-md .k-pager-numbers-wrap,
.k-pager-wrap.k-pager-sm .k-pager-numbers-wrap {
  z-index: 1;
}

.k-pager-wrap.k-pager-md .k-pager-numbers,
.k-pager-wrap.k-pager-sm .k-pager-numbers,
.k-pager-wrap.k-pager-lg .k-pager-numbers {
  border: 0;
  border-radius: 0;
}

.k-pager-numbers .k-link.k-state-selected {
  border-color: #008db9;
  color: white;
  background-color: #008db9;

  &:focus {
    border-color: #008db9;
    color: white;
    background-color: #008db9;
  }
}

.k-pager-numbers .k-link,
.k-pager-nav.k-link {
  color: #000;
}

.k-pager-numbers .k-link:focus,
.k-pager-numbers .k-link.k-state-focus,
.k-pager-nav.k-link:focus,
.k-pager-nav.k-link.k-state-focus {
  background-color: #ffffff;
  box-shadow: none;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover,
.k-pager-nav.k-link:hover {
  border-color: #008db9;
  color: #fff;
  background-color: #008db9;
}

.k-pager-nav+.k-pager-nav,
.k-pager-nav+.k-pager-numbers-wrap,
.k-pager-numbers li+li,
.k-pager-numbers-wrap+.k-pager-nav {
  margin-left: 2px;
}

.k-pager-sizes .k-dropdown-wrap {
  height: auto !important;
}

//grid pagination css end
//agreement window
.bzo-agreement-window {

  table,
  td,
  th {
    border: 0;
    padding: 0;
    font-size: inherit;
  }
}

.bz-text-capitalize {
  text-transform: capitalize;
}

.bz-text-muted {
  color: #a8b0bc !important;
}

.App-header {
  background-color: white;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.table-borderless,
.table-borderless td,
.table-borderless th {
  border: 0;
  color: #fff;
  padding: 0;
  font-size: 12px;
}

.table-borderless td.text-right {
  padding-left: 15px;
}

.k-card {
  box-shadow: none !important;
  background: transparent;
  border: 0;

  >.k-card-header {
    border-bottom: 0;
    background-color: transparent;

    >.k-card-title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 1rem;
      text-transform: capitalize;
      color: #172a3f;
    }
  }
}

.public-layout {
  .App-body {
    padding: 25px 2rem;
  }
}

.default-layout {
  .App-body {
    padding: 25px 2rem;
  }
}

.access-control-layout {
  .App-body {
    padding: 25px 2rem;
  }
}

.bzo-guest-layout {
  .App-body {
    padding: 25px 2rem;
  }
}

.bzo-primary-heading {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.required-asterisk {
  color: #000f;
  font-weight: 600;
}

h5.primary-heading {
  color: $primaryColor;
}

//about page css new design
.bzo-about-section {
  h5.bzo-primary-heading {
    margin-bottom: 1.5rem;
  }

  .card-text {
    >p {
      margin-bottom: 0.5rem;
    }
  }
}

.bz-services-showMore .btn-link,
.bz-showHide-description a,
.bz-services-showMore span {
  color: $primaryColor;
  font-weight: 600;
}

.service-desc {
  margin-top: 0.5rem;
  padding-top: 0.75rem;
}

.bz-online-classes {
  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      padding: 0;
    }

    small {
      display: block;
      font-size: 0.875rem;
    }

    .progressBarRow .k-progressbar {
      width: 8rem;
    }

    .bzc-col-btn {
      display: flex;
      justify-content: flex-end;

      .k-button {
        margin: 0;
      }
    }

    >.bzc-purchase-class-list-row.bz-class-selected {
      .bzc-sessions-list-row {
        grid-template-columns: repeat(3, 1fr) 8rem;
        grid-row-gap: 0.5rem;

        >.bzc-col-btn {
          grid-area: 1/4/2/5;
        }
      }
    }
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 6rem 9rem 8rem;
  }

  .bz-class-info-icon {
    >span {
      width: 2rem;
      height: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 0.25rem;

      &:hover {
        .fa-info-circle {
          color: #008db9;
        }
      }

      .fa-info-circle {
        font-size: 1.3rem;
        margin-right: 0;
        color: #000;
        cursor: pointer;
      }
    }
  }

  .bzc-footer-fixed-btn {
    >.k-fab {
      position: unset !important;
      margin: 0;
    }
  }

  .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 15rem 10rem;
  }

  .bz-packages-search {
    max-width: 16rem;
    padding: 0.2rem;

    >.form-control {
      max-height: 35px;
    }
  }

  .bz-packages-action {
    display: flex;
    justify-content: flex-end;
  }
}

.bz-inhouse-classes {
  >.bz-purchaseclass-scroll {
    >.row {
      >.col-md-12.col-lg-12 {
        .bz-purchase-class-list {
          .k-listview .k-listview-content {
            padding-right: 0.3rem;
          }
        }
      }
    }
  }
}

.bz-purchaseclass-scroll {
  >.row {
    >.col-md-12.col-lg-12 {
      .bz-purchase-class-list {
        .k-listview .k-listview-content {
          padding-right: 0.3rem;
          height: 76vh;
        }
      }
    }
  }
}

.bz-noaboutus-page {
  .bz-purchaseclass-scroll {
    >.row {
      >.col-md-12.col-lg-12 {
        .bz-purchase-class-list {
          .k-listview .k-listview-content {
            padding-right: 0.3rem;
          }

          .bz-packages-action+.k-listview .k-listview-content {
            padding-right: 0.3rem;
          }
        }
      }
    }
  }
}

.bz-classes-in-online {
  .bzc-purchase-class-list-view {
    .k-card-header.h5 {
      display: none;
    }

    .bz-purchase-class-list {
      padding-top: 0;

      .k-listview {

        .k-listview-content {
          padding-right: 0.75rem;
        }
      }
    }
  }

  #GSSelection {
    display: none;
  }

  [for="GSSelection"] {
    color: rgb(255, 255, 255);
    letter-spacing: 1.73px;
    padding: 10px 40px;
    border-radius: 0.25rem;
    border: 1px solid #ff7a0e;
    background-color: #ff7a0e;
  }

  .bz-purchase-class-filter {
    justify-content: start;
  }

  .bzc-footer-unpoitioned-btn {
    >button.k-fab {
      position: unset !important;
      margin: 0;
    }
  }

  .k-content {
    padding-bottom: 0;
  }
}

.bz-inhouse-classes {
  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      padding: 0;
    }

    small {
      display: block;
    }

    .progressBarRow .k-progressbar {
      width: 8rem;
    }

    .bzc-col-btn {
      display: flex;
      justify-content: flex-end;

      .k-button {
        margin: 0;
      }
    }

    >.bzc-purchase-class-list-row.bz-class-selected {
      .bzc-sessions-list-row {
        grid-template-columns: repeat(3, 1fr) 8rem;
        grid-row-gap: 0.5rem;

        >.bzc-col-btn {
          grid-area: 1/4/2/5;
        }
      }
    }

    .bzc-right-side-card {
      max-height: calc(100vh - 5.5rem);
      position: sticky;
      top: 0.25rem;
    }
  }

  .bz-packages-search {
    max-width: 16rem;
    padding: 0rem;

    >.form-control {
      max-height: 35px;
    }
  }

  .bzc-footer-fixed-btn {
    >.k-fab {
      position: unset !important;
      margin: 0;
    }
  }
}

.bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
  height: calc(100vh - 12rem);

  >.bz-cart-card {
    .card-body {
      max-height: 26.3125rem;
    }
  }
}

.bzc-purchase-class-list-view {
  .k-listview-content {
    background-color: transparent;

    .bzc-sessions-list-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 9rem 10rem 8rem;
      grid-column-gap: 1.5rem;
      justify-content: space-between;

      >.bzc-col-provider {
        .bz-provider-img {
          width: 1.5rem;
          margin-right: 0.2rem;
          border-radius: 50%;
          box-shadow: 0px 0px 1px 1px #000000;
        }
      }

      >.bzc-col-btn {
        .k-button {
          min-width: 115px;
        }
      }
    }
  }

  .k-fab {
    margin: 0 1rem;
    bottom: 5px !important;

    &:hover,
    &:focus {
      border-color: #ff8f33;
      color: $whiteColor;
      background-color: #ff8f33;
    }
  }

  .bzc-purchase-class-list-row {
    display: flex;
    justify-content: space-between;

    >.bzc-left-side-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 20rem);
    }

    >.bzc-right-side-card {
      flex: 0 0 18.5rem;
    }
  }

  .bzc-purchase-class-list-row.bz-class-selected {
    >.bzc-left-side-card {
      .bzc-sessions-list-row {
        align-items: flex-start;

        >.bzc-col-btn {
          display: flex;
          align-items: flex-start;
          height: 100%;
        }
      }
    }
  }
}

.bz-checkout-page {
  .payment-panel-form {
    .savedCard .k-button {
      min-width: auto;
    }

    .bz-classes-checkout {
      .card-body {
        padding: 0.5rem;
      }
    }
  }

  .order-summary-form {
    .k-card {
      margin: 0;
      background-color: #fff;

      >.k-card-header {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        .card-heading {
          margin-bottom: 0;
        }
      }

      >.k-card-body {
        padding: 1.25rem;

        .k-primary {
          margin-right: 0;
        }
      }
    }

    .plan-order-summary-card {
      margin-top: 2.4rem;
    }

    .client-order-summary {
      margin-top: 2.4rem;
    }

    .bz-salesPerson-select+.order-summary-card {
      margin-top: 0;
    }
  }

  .bz-inhouse-order-summary {
    .client-order-summary {
      margin-top: 0;
    }
  }

  .bzc-purchase-class-list-view.bz-purchase-checkout-list {
    .k-listview {
      height: auto !important;
      padding-right: 0.5rem;
    }
  }
}

.bz-checkout-page.bz-checkout-service {
  .order-summary-form {
    .plan-order-summary-card {
      margin-top: 0;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #fdfefe;
  box-shadow: 0px 0px 5px #dedede;
}

.bzo-footer {
  position: unset;
  height: auto;
  bottom: 0;
  z-index: 1031;
  width: 100%;
  padding: 2.5rem 3rem 1.5rem;
  line-height: inherit;
  cursor: default;

  h2 {
    color: rgb(0, 0, 0);
  }

  .bzo-primary-heading {
    color: rgb(0, 0, 0);
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  .bzo-footer-address {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    color: #000;
    margin-bottom: 0.75rem;

    >li {
      margin-right: 0.25rem;
      display: inline;
    }
  }

  .bzo-timings-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    >.bzo-timing {
      padding-bottom: 0.5rem;
      border-radius: 0.4rem;
      color: #000;
      text-align: left;
      margin: 0;

      grid-column-gap: 1rem;
      column-gap: 1rem;

      border-right: 0px solid #ccc;
      border-radius: 0;
      margin-bottom: 1rem;

      padding-bottom: 0;
    }
  }

  .bzo-footer-poweredby {
    padding-top: 0;
    color: #000;
  }

  .bz-bussiness-address-row {
    display: flex;
    align-items: flex-start;

    >.section-2 {
      border-left: 1px solid #ccc;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
    }
  }

  .bz-bussiness-hours-row {
    display: block;
    align-items: flex-start;

    >.section-1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      min-width: 6rem;

      >.hours-icon {
        margin-bottom: 0.5rem;

        >.svg-inline--fa {
          font-size: 2.5rem;
          color: black;
        }
      }
    }

    >.section-2 {
      border-left: 0px solid #ccc;
      padding-left: 0;
      margin-left: 0;
    }

    >.bz-vertical-line {
      margin: 0 1.5rem;
    }
  }

  .bz-vertical-line {
    border-left: 1px solid #ccc;
    height: 100%;
    min-height: 5rem;
    margin: 0 1rem;
  }
}

.bzo-review {
  padding-top: 1rem;

  .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  h5.card-title {
    margin-bottom: 1.3rem;
  }

  .card-text {
    .k-rating-container {
      margin-left: 0;

      .k-rating-item {
        padding: 0.2rem;

        &:first-child {
          padding-left: 0;
        }

        .k-icon {
          font-size: 20px;
        }
      }
    }
  }
}

//provider page css
.bzo-provider-sec {
  h5.bzo-primary-heading {
    margin-bottom: 1.5rem;
  }

  .provider-text {
    margin-bottom: 1.75rem;

    .bzo-provider-name {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0rem;
    }

    .bzo-provider-contact-details-sec {
      display: flex;
      margin-top: 0.5rem;

      >div {
        flex: 0 0 40%;
        width: 40%;
      }

      .bzo-provider-contact-details {
        text-transform: lowercase;

        >.fa-envelope,
        .fa-phone-volume {
          font-size: 1.3rem;
          vertical-align: bottom;
        }

        >.fa-phone-volume {
          transform: rotate(-45deg);
        }

        >strong {
          font-family: roboto;
          font-weight: 500;
          margin-left: 0.5rem;

          >a {
            color: #000;
          }
        }
      }
    }
  }

  .provider-img-new {
    >img {
      width: 4.0625rem;
      height: 4.0625rem;
      border: 3px solid #efefef;
    }
  }
}

.default-layout {
  .k-card {
    box-shadow: RGB(0 0 0 / 10%) 0em 0em 4em 0em;
  }

  .k-form .k-label,
  .k-form .k-form-label {
    color: #000 !important;
  }

  .strike-word {
    text-decoration: line-through;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bz-class-list {
    >.k-listview {
      height: calc(100vh - 14rem);
    }
  }
}

.bz-show-packages-checkbox {
  display: flex;
  border-radius: 0.25rem;
  padding: 0.7rem 0rem;
  align-items: center;
  margin-bottom: 0;
  top: 0.2rem;
  z-index: 9;

  .bz-checkbox {
    display: flex;
    align-items: center;
  }

  .k-checkbox-label {
    font-size: 0.875rem !important;
  }
}

.bzc-right-side-card {
  .card {

    .k-listview-content {
      .card {
        border: 0;
      }
    }

    .svg-inline--fa {
      margin-right: 0.5rem;
      color: #008db9;
    }

    .fa-trash-alt {
      color: $mutedTextColor;
    }

    hr.hide {
      display: none;
    }

    .bzc-classes-selected-time {
      position: relative;

      .bz-close-icon2 {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        text-align: center;
        color: #333;
        font-weight: 400;
        border-radius: 0.2rem;
        cursor: pointer;
        position: absolute;
        right: 0rem;
        top: 18%;

        >.fa-trash-alt {
          color: #008db9;
        }
      }
    }

    .card-title {
      font-size: 1rem;
    }
  }
}

.bz-shadow-class {
  box-shadow: 0 2px 18px #00000005 !important;
  border-color: #eaedf2 !important;
  border-width: 0.0625rem !important;
  background: #fff !important;
  border-style: solid !important;
  border-radius: 3px;

  .card-header {
    background: #ffffff;
    color: #000000;
  }
}

.bz-close-icon-new {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  color: #333;
  font-weight: 400;
  border-radius: 0.2rem;
  cursor: pointer;

  >.fa-trash-alt {
    color: #008db9 !important;
  }
}

.bz-close-icon-new:hover {
  color: #89898c;
}

.bz-package-list-container {
  .bz-package-edit-icon {
    float: right;
    font-size: 1rem;
    color: #50cd89;
    margin-right: 1rem;

    &:hover {
      color: #48964b;
    }
  }

  .bz-edit-Package-form {
    .bz-multiSelect {
      max-height: 35px;

      .k-button {
        min-height: calc(1.5em + 0.25rem + 2px);
        background-color: #008db9;
        color: #fff;
        padding: 0 0.5rem;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: normal !important;
        line-height: 1;
      }
    }

    .k-upload {
      .k-upload-action {
        min-width: auto;
        margin: 0;
        padding: 0;

        &:hover,
        &:focus {
          .k-delete {
            color: #333;
          }
        }
      }
    }
  }

  .bz-packages-list-view {
    .bz-package-card {
      >.card-body {
        border-left: 0;
      }

      .bzc-sessions-list-row {
        grid-template-columns: repeat(3, 1fr) 15rem 10rem;
      }
    }
  }

  .bz-packages-search {
    max-width: 15rem;
    padding: 0.2rem;
  }
}

.bzo-leftside-filter-sec {
  position: sticky;
  margin-bottom: 0;

  >h5 {
    margin-bottom: 1.5rem;
    font-weight: 500;
  }

  .bzo-leftside-filter-container-new {
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    padding: 0.75rem 0;
    align-items: flex-end;

    >.bz-check-box-filter-new {
      margin: 10px;
      align-items: center;

      .btn {
        margin: 10px;
        padding-left: 10px;
        text-decoration: none;
        margin-right: 1rem;

        >span {
          display: flex;
        }

        .k-checkbox-label {
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 0.875rem !important;
        }
      }
    }

    >.bzo-search-service {
      max-width: 16rem;
      width: 100%;
    }
  }

  .bzo-leftside-filter-container {
    display: flex;
    border-radius: 0.25rem;
    padding: 0.75rem 0;
    align-items: flex-end;
    justify-content: space-between;

    >.bz-check-box-filter {
      display: flex;
      align-items: center;

      .form-check {
        padding-left: 0;
        margin-right: 1rem;

        >span {
          display: flex;
        }

        .k-checkbox-label {
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 0.875rem !important;
        }
      }
    }

    >.bzo-search-service {
      max-width: 16rem;
      width: 100%;
    }
  }

  .bzo-leftside-filter-container.custom-btn {
    .bz-custom-btn-blk {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      >.k-button {
        margin-right: 0;
      }
    }
  }
}

.bzo-search-service {
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    font-size: 1.1rem;
    margin-left: 0.7rem;
    top: -4px;
  }

  .form-control {
    padding-left: 2.375rem;
    font-size: 0.875rem !important;
  }
}

.bz-default-cursor {
  cursor: default !important;
}

.bz-no-upcoming-appoinments,
.bz-no-available-orders {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.75;
}

.bz-no-upcoming-appoinments {
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: 600;
  color: black;
}

.k-switch-handle {
  width: 20px;
  height: 20px;
}

p {
  color: black;
}

.kendoFormElement {
  max-width: 45em;
}

.bz-row {
  display: flex;
  flex-wrap: wrap;
}

.bz-row2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.bz-row3 {
  .row {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
}

.bz-article {
  color: #202022;
}

span.arrowBackIcon {
  cursor: pointer;
  font-size: 1.5em;
}

.dialogContainer {
  text-align: center;
}

.buzopsWindowBack {
  cursor: pointer;
  color: #3f51b5;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #726b6b;
}

.k-animation-container {
  z-index: 10005 !important;
}

.k-notification-wrap {
  .k-icon {
    margin-top: 2px;
  }
}

.k-tabstrip>.k-content {
  padding: 0.8em 0.8em;
}

//kendo tooltip
.k-tooltip {
  background-color: #1a233a !important;
  color: #fff !important;
  border-color: #1a233a;
  box-shadow: 0px 2px 15px 0px #0000004d;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;

  .k-tooltip-content {
    .table {
      margin-bottom: 0;

      td {
        padding: 0.3rem !important;
        vertical-align: middle;
        line-height: 1rem;
        font-size: 0.75rem;
        text-align: left;
        min-width: 2rem;
        text-transform: capitalize;
      }
    }
  }

  .k-callout {
    color: #1a233a;
  }
}

// statuses colors
//Active
.bz-package-status1 {
  border-left: 0.25rem solid #08ae0f !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #08ae0f !important;
    }
  }

  .bz-text-status {
    color: #08ae0f !important;
  }
}

//In Active
.bz-package-status2 {
  border-left: 0.25rem solid #717171 !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #717171 !important;
    }
  }

  .bz-text-status {
    color: #717171 !important;
  }
}

//In Expired
.bz-package-status3 {
  border-left: 0.25rem solid #ed504f !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #ed504f !important;
    }
  }

  .bz-text-status {
    color: #ed504f !important;
  }
}

//In Cancelled
.bz-package-status4 {
  border-left: 0.25rem solid #f86c6b !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #f86c6b !important;
    }
  }

  .bz-text-status {
    color: #f86c6b !important;
  }
}

//Completed
.bz-package-status7 {
  border-left: 0.25rem solid #f86c6b !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #f86c6b !important;
    }
  }

  .bz-text-status {
    color: #f86c6b !important;
  }
}

//In Suspended
.bz-package-status5 {
  border-left: 0.25rem solid #5957d6 !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #5957d6 !important;
    }
  }

  .bz-text-status {
    color: #5957d6 !important;
  }
}

//In Frozen
.bz-package-status6 {
  border-left: 0.25rem solid #5bc8fa !important;

  .bz-elipsis-icon-btn {
    >.bz-elipsis-icon {
      border-top: 3rem solid #5bc8fa !important;
    }
  }

  .bz-text-status {
    color: #5bc8fa !important;
  }
}

//status label btn
.bz-statuses-label {
  padding: 0.2rem 0.5rem;
  font-size: 0.6rem;
  border-radius: 1rem;
  vertical-align: baseline;
  margin-bottom: 0;
  min-width: 2.6rem;
  text-align: center;
  font-weight: normal;
  line-height: 1.2;
}

.bz-statuses-label.Active {
  background-color: #08ae0f;
  color: #fff;
}

.bz-statuses-label.InActive {
  background-color: #717171;
  color: #fff;
}

.bz-statuses-label.Expired {
  background-color: #ed504f;
  color: #fff;
}

.bz-statuses-label.Cancelled {
  background-color: #f86c6b;
  color: #fff;
}

.bz-statuses-label.Suspended {
  background-color: #5957d6;
  color: #fff;
}

.bz-statuses-label.Frozen {
  background-color: #5bc8fa;
  color: #fff;
}

//border left color
.bzc-status-border {
  border-left-width: 0.1875rem;
  border-left-style: solid;
  border-left-color: $mutedTextColor;
}

.bzc-status-border.Active {
  border-left-color: #08ae0f !important;
}

.bzc-status-border.InActive {
  border-left-color: #717171 !important;
}

.bzc-status-border.Expired {
  border-left-color: #ed504f !important;
}

.bzc-status-border.Cancelled {
  border-left-color: #f86c6b !important;
}

.bzc-status-border.Suspended {
  border-left-color: #5957d6 !important;
}

.bzc-status-border.Frozen {
  border-left-color: #5bc8fa !important;
}

// new css class

.buz-font-600 {
  font-weight: 600 !important;
}

.buz-font-500 {
  font-weight: 500;
}

.buz-text-small {
  font-size: 0.8rem;
}

.buz-text-extrasmall {
  font-size: 0.688rem;
}

.buz-text-medium {
  font-size: 0.9rem !important;
}

.buzops_link {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

a:link {
  color: #007bff;
}

.disable_link {
  cursor: not-allowed;
  color: currentColor;
  opacity: 0.5;
  text-decoration: none;
}

.k-panelbar>.k-item>.k-link:hover,
.k-panelbar>.k-item>.k-link.k-state-hover,
.k-panelbar>.k-panelbar-header>.k-link:hover,
.k-panelbar>.k-panelbar-header>.k-link.k-state-hover {
  background-color: #f5f5f5 !important;
}

.k-panelbar>.k-item>.k-link.k-state-selected,
.k-panelbar>.k-panelbar-header>.k-link.k-state-selected {
  color: #000;
  background-color: #f5f5f5 !important;
}

.k-panelbar>.k-item>.k-link,
.k-panelbar>.k-panelbar-header>.k-link {
  color: #000;
  background-color: #f5f5f5 !important;
}

.k-panelbar>.k-item>.k-link,
.k-panelbar>.k-panelbar-header>.k-link {
  font-size: 1rem !important;
  font-weight: 400;
  text-transform: none !important;
}

.k-widget.k-panelbar {
  border: none;

  .k-item {
    margin-bottom: 0.75rem;
  }
}

.k-panelbar>.k-item+.k-item,
.k-panelbar>.k-panelbar-header+.k-panelbar-header {
  border-top-width: 0px;
}

.k-listview {
  border: none !important;
}

.payment-profile-image {
  width: 1.8rem;
}

.class-upload-image {
  width: 2.2rem;
}

.credit-card-input {
  border: none;
  outline: none;
}

.buz-signature {
  max-width: 177px;
  width: 100%;
  min-width: 177px;
  height: 55px;
  border: 0px solid #d9d9d9;
  cursor: pointer;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buz-signature-type {
  min-width: 177px;
  min-height: 55px;
  max-height: 105px;
  border: 0px solid #d9d9d9;
  cursor: pointer;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bz-type-signature {
  min-height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 2.75rem;
  text-align: center;
  width: 100%;
}

.checkout-page {
  .k-animation-container {
    z-index: 0 !important;
  }
}

.buz-online-list-view-new {
  margin-bottom: 15px;
  border-bottom: 2px solid #e6e6e6;
}

.buz-online-list-view {
  margin-bottom: 15px;
  background: #f8f9fa !important;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.checkmark {
  margin-right: 0.5rem;

  &-no {
    margin-right: 0.5rem;
  }
}

.buz-checkmark-btn {
  background-color: green !important;
  border-color: green !important;
  color: $whiteColor;
}

.buz-checkmark-btn:hover {
  background-color: darkgreen;
  border-color: darkgreen;
  color: $whiteColor;
}

.buz-btn-medium {
  font-size: 0.8rem;
}

.buz-btn-large {
  font-size: 1rem;
}

.buz-signature-pad {
  cursor: pointer;
  color: #726b6b;

  img {
    width: 175px;
    height: 53px;
  }
}

.service-title {
  font-size: 1.25rem;
}

.provider-img-new>img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 3px solid #60a6c6;
}

.provider-text {
  padding-left: 0px !important;
}

button.sq-creditcard {
  background: #ff7a0e;
}

.k-colorpalette .k-item.k-state-selected,
.k-colorpalette .k-item.k-state-selected:hover,
.k-colorpalette .k-item.k-state-focused,
.k-colorpalette .k-item:focus {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 30%), inset 0 0 0 4px #ff7a0e !important;
}

//new design for services-online
.bzc-services-list-new {
  box-shadow: 0px 3px 8px #00000029;
  border: 1px solid #9f9f9f;
  border-radius: 7px;
  opacity: 1;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: #fff !important;
  padding: 35px 20px 15px 20px !important;
  gap: 10px;

  .section-1 {
    border-right: 1px solid #ccc;
  }

  .section-2 {

    .bzo-status-Complimentary-new,
    .bzo-status-success-new {
      border-left: 1px solid lightgray;
    }

    .duration-icon {
      width: 30px;
      margin-left: 12px;
    }
  }

  .two-grid {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .bzc-service-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .bz-name {
      text-transform: capitalize;
      font-size: 0.875rem;

      >h6 {
        color: #393939;
        font-weight: normal;
        letter-spacing: 0px;
        font-size: 0.875rem;
        margin: 0;
      }

      >h6.service-name {
        color: #262727;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 0.875rem;
      }
    }

    .bz-availability-appointments {
      color: #393939;
      font-weight: normal;
      letter-spacing: 0px;
      font-size: 0.875rem;

      .appoinment-icon {
        width: 30px;
        margin-right: 5px;
        margin-left: -5px;
      }
    }

    .bz-availability-status {
      position: absolute;
      top: 0;
      width: 120px;
      text-align: center;
      background-color: #008db9;
      color: #fff;
      font-weight: normal;
      opacity: 0.7;
      padding: 3px 0px;
      border-radius: 0px 0px 5px 5px;
      font-size: 0.875rem;
    }

    .bz-price {
      font-size: 18px;
      color: #ff7a0e;
      display: flex;
      align-items: baseline;
      gap: 8px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        color: #ff7a0e;
      }
    }
  }
}

.bz-membership-rate {
  .bz-planPurchase {
    >span.ratelabel {
      text-transform: capitalize;
      min-width: 2rem;
      display: inline-block;
    }
  }
}

.bz-membership-rate {
  .rateLabelWidth {
    .bz-planPurchase {
      >span.ratelabel {
        min-width: 4.35rem;
        display: inline-block;
        text-transform: capitalize;
      }
    }
  }
}

.text-decoration-underline {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.bzc-sessions-list-card-new {
  border-bottom: 1px solid #9f9f9f;
  opacity: 1;
  cursor: pointer;

  >.card-body {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    padding: 25px 25px 15px 25px;
  }

  .bzc-sessions-list-row-new {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1.5fr 2fr 1fr 0.3fr;
    align-items: center;
    justify-items: center;

    .section-1 {
      justify-self: flex-start;
      min-width: 160px;
    }

    .section-2 {
      border-right: 1px dotted #ccc;
      width: 1px;
      height: 100%;
    }

    .bz-name {
      text-transform: capitalize;
      font-size: 0.875rem;

      >h6 {
        color: #393939;
        font-weight: normal;
        letter-spacing: 0px;
        font-size: 0.875rem;
        max-width: 250px;
        word-break: break-all;
      }

      >h6.service-name {
        color: #262727;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 0.875rem;
      }

      >span.bz-availability-appointments {
        font-size: 14px;
        font-weight: normal;
        color: #707070;
        display: inline-flex;
        min-width: 6rem;
        align-items: center;
      }
    }

    .bz-availability-status {
      position: absolute;
      top: 0;
      width: 120px;
      text-align: center;
      background-color: #008db9;
      color: #fff;
      font-weight: normal;
      opacity: 0.7;
      padding: 3px 0px;
      border-radius: 0px 0px 5px 5px;
      font-size: 0.875rem;
    }

    .bzc-date-sec {
      display: flex;
      font-weight: 600;
      text-transform: capitalize;
    }

    .bzc-time-sec {
      color: $mutedTextColor;
    }

    .svg-inline--fa {
      margin-right: 0.5rem;
      color: #008db9;
    }

    .bzc-arrow-icon {
      flex: 0 0 3.5rem;
      text-align: right;

      .k-icon {
        font-size: 2rem;
        color: #008db9;
      }

      .svg-inline--fa {
        font-size: 1rem;
      }

      .fa-long-arrow-alt-right {
        font-size: 1.5rem;
        color: #008db9;
      }

      .fa-window-close {
        color: #ff7a0e;
        cursor: pointer;
      }

      .fa-times-circle {
        color: #dc3545;
        cursor: pointer;
      }

      .fa-edit {
        cursor: pointer;
        color: #50cd89;
      }
    }

    .bzc-session-price {
      display: flex;
      align-items: center;
      margin: 0;
      gap: 5px;

      small {
        font-size: 0.875rem;
        color: #393939;
      }

      h3 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #393939;
        margin: 0;
      }
    }

    .bzc-session-price-new {
      h5 {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}

.bzc-sessions-list-card-new.bz-noPaid-group {
  .bzc-sessions-list-row-new {
    grid-template-columns: 2.5fr 2.5fr 2fr 1.5fr 2fr auto;
    justify-items: self-start;

    .bzc-session-price {

      .bzo-status-success {
        padding: 2px 10px;
        font-size: 12px;
      }
    }
  }
}



.bzc-packages-list-card-new {
  border: 1px solid rgba(0, 0, 0, .125);
  opacity: 1;
  cursor: pointer;
  border-radius: 5px;

  >.card-body {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    padding: 28px 20px 15px 20px;
  }

  .bzc-packages-list-row-new {
    display: grid;
    grid-template-columns: 1fr 3fr 0.5fr 1fr;
    align-items: center;
    justify-items: flex-start;
    gap: 5px;

    .section-1 {
      grid-row: 1 / span 2;
      width: 100%;
    }

    .section-2 {
      border-left: 1px solid #ccc;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .grid-parent-container {
      padding-left: 15px;
      display: grid;
      grid-template-columns: 13rem 1fr;
      grid-template-rows: 1fr auto;
      row-gap: 5px;
      column-gap: 10px;
      grid-template-areas:
        "area-1 area-2"
        "area-3 area-3";

      .child-1 {
        grid-area: area-1;
      }

      .child-2 {
        grid-area: area-2;
        margin-bottom: 10px;
        border-left: 1px solid #ccc;
      }

      .child-3 {
        grid-area: area-3;
        border-top: 1px dotted #ccc;
      }
    }

    .section-6 {
      grid-area: 2 / 2 / 2 / 5;
      justify-self: flex-start;
      border-top: 1px dotted #9f9f9f;
    }

    .section-4 {
      justify-self: center;
    }

    .section-5 {
      justify-self: center;
      grid-row: 1 / span 2;
    }

    .bz-name {
      text-transform: capitalize;
      font-size: 16px;

      >h6 {
        color: #393939;
        font-weight: normal;
        letter-spacing: 0px;
        font-size: 16px;
      }

      >h6.service-name {
        color: #262727;
        font-weight: 600;
        letter-spacing: 0px;
        font-size: 0.875rem;
      }
    }

    .bz-availability-status {
      position: absolute;
      top: 0;
      width: 120px;
      text-align: center;
      background-color: #008db9;
      color: #fff;
      font-weight: normal;
      opacity: 0.7;
      padding: 3px 0px;
      border-radius: 0px 0px 5px 5px;
      font-size: 0.875rem;
    }

    .bz-availability-packages {
      >h5 {
        color: #8f9bb3;
        font-weight: 500;
        letter-spacing: 0px;
        font-size: 0.875rem;
        text-transform: none;
      }
    }

    .bzc-provider-name {
      small {
        font-size: 0.78rem;
        color: #393939;
      }
    }

    .bzc-session-price {
      display: flex;
      align-items: center;
      gap: 5px;

      small {
        font-size: 0.875rem;
        color: #393939;
      }

      h5 {
        font-size: 1.1rem;
        font-weight: 600;
        color: #393939;
        margin-bottom: 0 !important;
      }
    }
  }
}

.bzc-sessions-list-card-new.bz-classes-checkout {
  box-shadow: 0px 3px 8px #00000029;
  border: 1px solid #393939;
  border-radius: 7px;
  height: auto;

  .bzc-sessions-list-row-new.bzc-sessions-list-row-useCredit {
    display: grid;
    grid-template-columns: auto auto auto auto auto 2rem;
  }

  .bzc-sessions-list-row-new {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2rem auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }

  .bz-main-provider>.bz-provider-img img {
    width: 72px;
    height: 74px;
    border-radius: 50%;
  }

  .bz-main-provider>.bz-attendees-icon img {
    width: 72px;
    height: 74px;
  }
}

.client-name {
  color: #393939;
  text-decoration: none;
  font-size: 1rem;
}

.dFlexBtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6px !important;

  h5 {
    margin-bottom: 0 !important;
  }

  h6 {
    margin-bottom: 0 !important;
  }

  .k-button {
    min-width: auto !important;
  }
}

.bz-planAddon {
  margin-bottom: 0 !important;

  .bz-schedule-btn {
    margin: 0 !important;
  }
}

.coupon-info {
  display: flex;
  flex-direction: column;
  gap: 0;

  .section-coupon-info {
    justify-self: flex-start;

    h6 {
      font-weight: 500;
      font-size: 0.8rem;
    }
  }

  .section-coupon-btn button {
    border: 0px;
    color: red;
    background: transparent;
    line-height: 1;
    display: flex;
    font-size: 0.8rem;
    padding-right: 0;
    margin-left: 1rem;

    &:focus {
      outline: none;
    }
  }

  .coupon-flex {
    display: flex;
    gap: 0;
    align-items: flex-start;
    margin-bottom: 0 !important;
  }

  .plan-coupon-flex {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}

.coupon-list {
  .coupon-info {
    border: 1px dashed lightgray !important;
    padding: 10px;
  }
}

.order-summary-card.k-card>.k-card-body .k-listview {
  height: auto !important;
}

.payment-profile-credit-card-Item {
  display: grid;
  grid-template-columns: 2fr .5fr 3fr 1fr;
  padding: 0.25rem 0.75rem;
  align-items: center;

  small {
    color: $mutedTextColor;
  }

  .section-card-name-details {
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .section-expiry-details {
    align-self: center;
    display: flex;
    flex-direction: column;
  }
}

.payment-profile-bank-Item {
  display: grid;
  grid-template-columns: 3.5fr 2rem 1fr;
  padding: 0.75rem;
}

.ready-to-book-appt-dialog {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  row-gap: 0.55rem;

  .column-button-section {
    grid-area: 4 / 1 /4 /3;
    justify-self: flex-end;
    display: flex;
  }
}

.bz-colon-centered {
  .ready-to-book-appt-dialog {
    grid-template-columns: 15rem 1rem 1fr;

    .column-button-section {
      grid-area: 4 / 1 /4 /4;
    }

    .dialog-btn-blk {
      grid-area: auto/1/auto/4;
      display: flex;
      justify-content: center;
    }
  }
}

.bz-checkout-page .checkout-panelbar-wrapper {
  padding: 1rem;
}

.serviceDetailsPanel-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  font-size: 1rem;

  .section-1 {
    h5 {
      color: #008db9;
      font-size: 1.3rem;
      font-weight: 600;
    }

    h6 {
      font-size: 0.875rem;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    grid-area: 1 / 1 / 1 / 3;

    p {
      font-size: 0.75rem;
    }
  }

  .section-2 {
    justify-self: flex-end;
  }
}

.order-summary-class-details {
  display: grid;
  color: #008db9;
  grid-template-columns: 3fr 1fr;
  font-size: 1rem;
  font-weight: 500;

  .order-summary-class-name {
    justify-self: flex-start;
    text-align: left !important;
  }

  .order-summary-class-cost {
    justify-self: flex-end;
  }
}

.signature-terms {
  display: flex;
  grid-template-columns: 3fr 1fr;
  justify-content: flex-start;
  flex-direction: column;

  >.signature-section.bz-agree-checkbox {
    display: flex;
    align-items: center;
  }

  .button-submit-section {
    align-self: flex-start;
    margin-top: 0.5rem;

    .k-button {
      margin-left: 0;
    }
  }

  .signature-section {
    align-self: flex-start;
  }
}

/*---------- my bookings page Css ---------*/

.my-bookings_upcoming-appointments_body .k-listview-content {
  padding-right: 0.5rem;
}

.my-bookings_booking-body_provider_1 .k-avatar {
  width: 1.5rem;
  height: 1.5rem;
}

.my-bookings_booking-body_provider_2 span {
  font-size: 0.8rem;
}

.k-window {
  .k-window-titlebar {
    color: #000;

    .k-button {
      margin: 0;
      color: #000;
    }

    .k-button:hover::before {
      opacity: 0;
    }

    .k-button:focus::after {
      box-shadow: none;
    }

    .k-button:hover,
    .k-button:focus {
      color: #000;
    }
  }
}

.k-dialog {
  .k-dialog-titlebar {
    .k-dialog-title {
      .k-i-undo {
        vertical-align: baseline;
        cursor: pointer;
      }

      .k-i-undo:hover {
        color: $mutedTextColor;
      }
    }

    .k-dialog-actions {
      .k-dialog-close {
        margin: 0;
      }

      .k-dialog-close:hover::before {
        opacity: 0;
      }

      .k-dialog-close:focus::after {
        box-shadow: none;
      }

      .k-dialog-close:hover,
      .k-dialog-close:focus {
        color: #000;
      }
    }
  }

  .k-dropzone {
    .k-dropzone-hint {
      text-align: left;
    }
  }
}

.bz-privacyPolicy-dialog {
  .k-dialog {
    width: 50%;
    height: 95%;

    .k-dialog-content {
      min-height: 310px;

      h5,
      h4 {
        font-weight: 600;
      }

      article {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.order-summary-card {
  .order-summary-planName {
    font-size: 1rem !important;
  }
}

.bz-plan-order-summary-checkbox {
  display: inline-flex;
  min-height: 35px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.75rem;
}

.order-summary-card .k-card-body p {
  font-size: 0.875rem;
  font-weight: 500;
  color: #262727;
  margin-bottom: 0 !important;

  >a {
    font-size: 0.8rem;
  }
}

.order-summary-card .k-card-body p.service-para.additionalfees {
  margin-bottom: 15px !important;
}

.client-name-label {
  color: #8f9bb3 !important;
}

.count-rate-txt {
  font-size: 16px;
}

.bzo-signaturepad canvas {
  background-color: #f5f5f5;
}

.bzo-services-container {
  padding-top: 1.3rem;
  margin-bottom: 4rem;
  font-family: "Poppins", sans-serif !important;

  .service-title {
    margin-right: 1rem;
    display: inline-block;
  }

  .k-listview-content {
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    //overflow: unset;

    .buz-online-list-view-new {
      border: 1px solid rgba(0, 0, 0, .125);

      border-radius: 5px;
      margin-bottom: 1.5rem !important;

      .bz-service-select-btn-new {
        min-width: 6.5rem;
        border-radius: 0.25rem !important;
        padding: 0.65rem 0.6rem;
        float: right;
      }
    }



    .bz-one-on-one-purchase {
      .service-title {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }


  .k-pager-wrap {
    border: 0;
    background-color: transparent;
    justify-content: center;

    .k-pager-numbers {
      .k-link {
        color: #000;
        background-color: transparent;
        border: 0;
        border-bottom: 0.2rem solid transparent;
        min-width: 1rem;
        padding: 0;
        margin: 0 0.5rem;
        height: auto;

        &:focus {
          box-shadow: none;
        }
      }

      .k-link.k-state-selected {
        color: #008db9;
        border-bottom: 0.2rem solid #008db9;
      }

      .k-link.k-state-focus {
        box-shadow: none;
      }
    }

    .k-pager-info {
      display: none;
    }
  }
}

.location-icon {
  font-size: 1.25rem;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;

    >a {
      display: inline-flex;
      align-items: center;
      height: auto;
    }
  }
}

.bz-aboutus-page {
  min-height: calc(100vh - 22rem);

  .bzo-services-container {
    .k-listview-content {
      padding-right: 0.35rem;
    }
  }
}

.bzo-service-status {
  min-width: 4.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}

.bzo-status-success {
  background-color: #e1ffdb;
  color: #75bc43;
  padding: 0px 8px;
  border-radius: 20px;
}

.bzo-status-success-dark {
  background-color: #1EBA62;
  color: #fff;
  padding: 0.25rem 0.515rem;
}

.bzo-status-Complimentary {
  background-color: #dcf2fc;
  color: #7ca1c5;
  padding: 0px 5px;
  border-radius: 20px;
}

.bzo-status-consultation {
  background-color: #fcf3dd;
  color: #eca11b;
  padding: 0px 5px;
  border-radius: 20px;
}

.bzo-status-consultation.nowshow {
  background-color: #ffd489;
  color: #4d4b47;


}

.bzo-status-paymentRequired {
  background-color: #fcf3dd;
  color: #dc3545;
}

.bzo-contact-navbar.bzo-fixed-phone-icon {
  display: none;
  position: fixed;
  bottom: 50%;
  z-index: 999999999999;
  background-color: #008db9;
  right: -129px;
  padding: 0.6rem;
  border-top-left-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  padding-right: 1rem;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  border: 1px solid #5caec7;
  border-right: 0;

  .fa-phone-volume {
    margin-right: 0.7rem;
  }

  .btn-link {
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }
}

.bzo-contact-navbar.bzo-fixed-phone-icon:hover {
  margin-right: 127px;
}

.bzo-appoinmentBooked-grid {
  .k-grid-header {
    .k-header {
      >.k-cell-inner {
        margin: 0;

        >.k-link {
          padding: 0;
          justify-content: center;
        }
      }
    }
  }

  .k-grid-container>.k-grid-content {
    tr:hover {
      background-color: #fff;
    }
  }

}

.bz-trainers-card {
  min-height: 96px;
  max-height: 120px;

  .card-body {
    padding: 0.75rem !important;
    width: 100%;
  }

  .media {
    width: 100%;
  }

  .media-body {
    width: 75%;

    h5 {
      font-size: 1rem !important;
    }

    .bz-provider-desc {
      max-width: 100%;
      display: inline-block;
      max-height: 42px;
      overflow: auto;
    }
  }
}

.bzo-paymentProfile-noData {
  min-height: 138px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(33, 37, 41, 0.5);
}

.bzo-noData-found {
  min-height: 138px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(33, 37, 41, 0.5);
  padding: 0 0.5rem;
}

/*---------- checkout page Css ---------*/
.bz-checkout-page .k-panelbar,
.bz-checkout-page .checkout-panelbar-wrapper,
.bz-checkout-page .order-summary-form .order-summary-card,
.bz-card {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 0 15px 0 #f9f9f9;
  border-radius: 0.4rem;
}

.bz-checkout-page .k-panelbar>.k-item>.k-link,
.k-panelbar>.k-panelbar-header>.k-link {
  background-color: #ffffff !important;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.bz-checkout-page .k-panelbar>li.k-state-expanded>.k-animation-container {
  border-radius: 0;
}

.bz-checkout-page .k-panelbar>li:first-of-type>.k-link {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.bz-checkout-page .k-panelbar>li:last-child>.k-link,
.bz-checkout-page .k-panelbar>li:last-child.k-state-expanded>.k-animation-container {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.bz-checkout-page .k-panelbar>li:last-child>.k-link.k-state-selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bz-checkout-page .k-panelbar>.k-item>.k-link.k-state-selected,
.k-panelbar>.k-panelbar-header>.k-link.k-state-selected {
  background-color: #f5f5f5 !important;
}

.bz-checkout-page .k-panelbar>.k-item>.k-link.k-state-selected,
.k-panelbar>.k-panelbar-header>.k-link.k-state-focused,
.bz-checkout-page .k-panelbar>.k-item>.k-link.k-state-selected,
.k-panelbar>.k-panelbar-header>.k-link:focus {
  box-shadow: none;
}

.bz-checkout-page .k-state-expanded .k-animation-container {
  border-bottom: 1px solid #d9d9d9;
}

.bz-checkout-page .k-panelbar>li:last-child.k-state-expanded>.k-animation-container,
.bz-checkout-page .k-panelbar>li:last-child>.k-link {
  border-bottom: 0;
}

.bz-checkout-page .k-button {
  margin: 0 0.5rem;
}

.bz-checkout-page .order-summary-form .order-summary-card>.k-card-header {
  background-color: #fff;
  color: #000;

  h6 {
    font-size: 13px !important;
    text-transform: capitalize !important;
  }
}

.capitaltxt {
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
}

.bz-checkout-page .order-summary-form .order-summary-card .k-form-field {
  margin-top: 0;
  flex: 1 0 auto;
}

.bz-coupon-field .k-button {
  margin: 0;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}


.bz-coupon-field .k-textbox {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.bzo-coupon-success {
  color: #58a108;
  background-color: #e6efe5;
  border-color: #e6efe5;
}

.installemt-grid {
  border: 1px solid #d9d9d9;
  margin: 1rem;
}

.installemt-grid .k-grid-header {
  padding-right: 6px !important;
}


.installemt-grid .k-grid-header .k-header {
  font-weight: 500;
}

.reccurencepacakagetable .k-grid-header-wrap {
  border-width: 0;
  margin-right: 0;
}


.bzo-agreement>table {
  background-color: #fff;
  text-transform: uppercase;

  p {
    img {
      width: 160px;
      height: 50px;
    }
  }
}

//common css

.bzo-filter-heading {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}

.btnloading {
  margin-left: 0.5em;
}

.text-muted {
  color: $mutedTextColor !important;
}

.bz-verticalLine {
  color: #bacfd6;
  min-width: 7px;
  text-align: center;
  margin: 0 0.2rem;
}

.bz-login {
  text-transform: capitalize;
  line-height: 1;
}

.bz-cursor-pointer {
  cursor: pointer;
}

.pe-all {
  pointer-events: all;
}

/*---------- Kendo  Css start---------*/
.k-checkbox {
  width: 20px;
  height: 20px;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #008db9;
  color: $whiteColor;
  background-color: #008db9;
}

.k-button,
.k-button-secondary {
  background-color: #d9d9d9;
  border-radius: 3px !important;
  box-shadow: none !important;
  font-weight: 400 !important;
}

.k-button-primary,
.k-button.k-primary,
.k-button-solid-primary {
  border-color: #008db9 !important;
  color: #ffffff !important;
  background-color: #008db9 !important;
  border-radius: 3px !important;
  min-height: 2.188rem;
}

.k-button-primary.k-outline,
.k-button.k-primary.k-outline {
  border-color: #008db9 !important;
  color: #ffffff !important;
  background-color: #008db9 !important;
  border-radius: 3px !important;
  line-height: 1;
  padding: 8px 16px !important;
}

.k-button-primary:hover,
.k-button.k-primary:hover,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary,
.k-button-solid-primary:hover,
.k-button-solid-primary.k-state-hover {
  border-color: #008db9 !important;
  color: $whiteColor;
  background-color: #008db9 !important;
}

.k-button-group {
  box-shadow: none !important;
}

.k-textbox,
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-color: #fff !important;
}

.k-button:disabled,
.k-button.k-state-disabled:hover {
  cursor: not-allowed;
}

.k-button-primary:focus,
.k-button.k-primary:focus,
.k-button-solid-primary:focus,
.k-button-solid-primary.k-state-focus,
.k-button-solid-primary.k-state-focused,
.k-button-primary.k-state-focus,
.k-state-focus.k-button.k-primary,
.k-button-primary.k-state-focused,
.k-state-focused.k-button.k-primary,
.k-panelbar>.k-item>.k-link:focus,
.k-panelbar>.k-item>.k-link.k-state-focus,
.k-panelbar>.k-item>.k-link.k-state-focused,
.k-panelbar>.k-panelbar-header>.k-link:focus,
.k-panelbar>.k-panelbar-header>.k-link.k-state-focus,
.k-panelbar>.k-panelbar-header>.k-link.k-state-focused {
  box-shadow: none;
}

.k-fab {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  box-shadow: none;
  border-color: #008db9;
  color: $whiteColor;
  background-color: #008db9;
}

.k-form .k-label,
.k-form .k-form-label {
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
  color: $mutedTextColor;
  font-weight: 500;
}

.k-tabstrip-top .k-tabstrip-items-wrapper {
  z-index: 1;
}

.k-window-actions {
  .k-button.k-button-icon {
    min-width: initial !important;
    padding: 0 !important;
    width: auto;
    height: auto;
  }
}

/*---------- Kendo  Css End ---------*/
.k-card {
  border-color: #d9d9d9;
  border-radius: 0.4rem;
}

.bz-ol-profile-card {
  border: 0;
}

.bz-ol-inner-card {
  border: 0;
  box-shadow: 0.0025rem 1px 5px #d2d2d2;
}

/*---------- About Pages Css ---------*/
.bz-ol-profile-card .bz-ol-trainer-logo {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.bz-ol-profile-card>.card-body {
  position: relative;
  overflow: hidden;
}

.bz-profile-container {
  position: relative;
  z-index: 1;
}

.bz-curve {
  position: absolute;
  z-index: 0;
  top: -3rem;
  left: -1rem;
  width: 40rem;
  height: 15rem;
  opacity: 1;
  transform: rotate(10deg);
  background-color: #008db9;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.bz-User-profile-card {
  height: calc(100vh - 117px);
  padding-top: 0 !important;

  .k-card-body {
    .logo {
      max-width: 7rem;
      max-height: 1.8rem;
    }

    hr {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    .k-i-marker-pin-target,
    .fa-clock {
      color: #008db9;
    }

    p {
      margin-bottom: 0.5rem;
    }

    .bz-times {
      p {
        width: 14rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.bz-about-desc-card {
  h5 {
    font-size: 1.5rem;
  }
}

/*---------- login Pages Css ---------*/
.bz-ol-login-card {
  height: calc(100vh - 7.5rem);
  border: 0;
}

.bz-ol-login-card h5.h5 {
  font-size: 1.5rem !important;
}

.bz-ol-login-card .k-button-group>.k-button {
  margin: 0 0.75rem;
  border-radius: 0.4rem;
}

.bz-border-radius-1 {
  border-radius: 0.4rem;
}

.k-form-hint {
  color: #6c757d !important;
  font-size: 14px;
}

.bz-ol-muted {
  color: $mutedTextColor;
}

.k-button.bz-service-select-btn {
  min-width: 4.5rem;
  display: inline-block;
  border-radius: 0.4rem !important;
}

.bz-privacyPolicy-dialog.bzo-auto-height {
  height: auto;
  top: 31%;
}

/*----------new  login modal Css start---------*/
.bzo-login-dialog {
  >.k-dialog {
    min-width: 500px;
    max-width: 500px;
  }

  .k-dialog-content {
    padding: 0rem;

    .bzo-login-header {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #d9d9d9;

      >h4 {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .bzo-login-body {
      padding: 0 1.5rem 1.5rem;

      .k-form-field {
        .k-label {
          color: #000 !important;
          margin-bottom: 1rem;
        }
      }
    }

    .k-button-group {
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0.75rem;

      >.k-button {
        margin: 0 0.5rem;
        min-width: 6rem;
        border-radius: 0.2rem !important;
      }

      >.k-group-end {
        margin-right: 0;
      }
    }

    .bz-emailLogin-card {
      .k-button-group {
        width: 100%;
      }
    }

    .bz-other-login-option {
      .btn-link {
        font-size: 0.875rem;
      }

      .bzo-reset-pwd {
        border-right: 1px solid #d9d9d9;
        margin-right: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

// mybooking

.bz_fs_1_5 {
  font-size: 1.2rem;
}

.my-bookings {
  display: flex;
  color: #333;

  &_switch-label {
    padding: 5px;
  }

  &_booking-body {
    flex: 75%;
    padding: 20px;
    grid-gap: 10px;

    &_service_selector {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &_count {
        font-size: 18px;
        align-self: center;
        padding-top: 30px;
        color: black;
      }
    }

    &_hr-tag {
      border-bottom: 2px solid #80808040;
    }

    &_selectedScheduler {
      display: flex;

      &_selectedApointments {
        flex: 50%;
        display: flex;
        flex-direction: column;
      }

      &_selectedSolts {
        flex: 50%;
        display: flex;
        flex-direction: column;
      }
    }

    &_scheduler {
      display: flex;

      &_calendar {
        flex: 50%;
        display: flex;
        flex-direction: column;
      }

      &_hours {
        flex: 50%;
        display: flex;
        flex-direction: column;
      }

      &_label {
        color: #333;
        font-weight: 500;

        &_desc {
          display: grid;
          align-items: center;
          color: #adadad;
          font-size: 14px;

          :nth-child(2) {
            text-align: right;
          }
        }
      }

      &_calendar_align {
        margin-left: 25px;
      }

      &_calendar_options {

        .k-dropdown .k-dropdown-wrap.k-state-focused,
        .k-dropdowntree .k-dropdown-wrap.k-state-focused,
        .k-dropdown .k-dropdown-wrap,
        .k-dropdowntree .k-dropdown-wrap {
          background-color: white;
        }
      }

      &_bodytags {
        height: 360px;
        overflow: auto;
      }

      &_tags {
        display: flex;
        flex-wrap: wrap;
      }

      &_tag {
        border: 1px solid lightgrey;
        padding: 10px 20px;
        margin: 5px;
        border-radius: 5px;
        color: gray;
        cursor: pointer;
        pointer-events: all;
      }

      &_highlight {
        background-color: #008db9 !important;
        color: white !important;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }

      &_disable {
        opacity: 0.7;
        background-color: red !important;
        color: white !important;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }
    }

    &_trainer {
      display: flex;

      &_tags {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        flex-wrap: wrap;
        cursor: pointer;
        pointer-events: all;
        grid-gap: 10px;
      }

      &_tag {
        padding: 15px 30px;
        border-radius: 5px;
        color: gray;
        align-items: center;
        box-shadow: 0 0 15px 0 #84898c66;
      }
    }

    &_provider {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;

      &_2 {
        text-align: right;
        align-self: center;
      }

      &_list {
        cursor: pointer;
        pointer-events: all;
        font-weight: 500;
      }
    }
  }

  &_upcoming-appointments {
    flex: 25%;
    padding: 20px;

    &_body {
      &_label {
        font-weight: 500;
      }

      &_tag {
        border: 1px solid lightgrey;
        padding: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
        color: gray;
        cursor: pointer;
        pointer-events: all;

        &_content {
          display: grid;
          grid-template-columns: 1fr 4fr 1fr;
        }

        &_menu {
          justify-content: flex-end;
        }

      }
    }
  }
}

/* scroll bar css  */
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline-color: slategrey;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-text {
  color: #fff;
}



.bz-upcoming-appoinment-container {
  overflow: auto;
  max-height: calc(100vh - 13.5rem);
  padding-right: 0.5rem;
}

.bz-upcomingappoinment-sec .bz-upcoming-apponments-sm-card:last-of-type {
  margin-bottom: 0 !important;
}

.bz-upcoming-apponments-sm-card img.bz-user-img {
  width: 3.5rem;
  border-radius: 50%;
}

/* service card css */
.bz-service-card {
  cursor: pointer;
  margin-bottom: 30px !important;
  transition: border-color 0.2s linear;
  margin-bottom: 15px !important;

  >.card-body {
    padding: 1.75rem;
  }
}

.bz-service-card:hover {
  border-color: #008db9 !important;
}

.bz-serviceCard-min-height {
  min-height: 140px;
  max-height: 140px;
}

.bz-border-blue {
  border-color: #008db9 !important;
}

.bz-service-card-inner {
  padding-left: 2.3rem;
  padding-right: 1.8rem;
}

.bz-service-card-inner>.card-title {
  word-wrap: break-word;
  word-break: break-all;
}

.bz-schedule-link-btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
  bottom: 6px;

  >.k-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    text-transform: capitalize;
    color: #000;
    min-width: auto;
    margin: 0;
    grid-gap: 0;

    >.svg-inline--fa {
      color: #008db9;
      margin-right: 0.3rem;
    }

    &:hover {
      color: #008db999;

      >.svg-inline--fa {
        color: #008db999;
      }
    }
  }
}

.bz-schedule-link-btn.addon-link {
  .k-button {
    color: #008db9;

    &:hover {
      color: #008db9a1;
    }
  }
}

.bz-shadow {
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 15px 0 #f9f9f9;
  border-radius: 0.5rem;
}

.bz-dot::before {
  content: " ";
  width: 1.8rem;
  height: 1.8rem;
  background-color: #008db9;
  border-radius: 50%;
  margin-right: 1rem;
  border: 5px solid #edfbffba;
  position: absolute;
  left: 1rem;
}

.bz-arrow-icon {
  position: absolute;
  right: 2rem;
  top: 0.8rem;
  font-size: 2.2rem;
  color: #008db9;
}

.bz-button-schedule {
  position: absolute;
  right: 2rem;
  top: 3.8rem;
  font-size: 2.2rem;
}

.bz-elipsisis-icon {
  position: absolute;
  right: 0.7rem;
  top: 0.5rem;

  .k-button.k-button-icon.k-state-disabled {
    cursor: default;
  }

  .k-button.k-button-icon:not(.k-state-disabled) {
    cursor: pointer;
  }
}

.bz-elipsis-container.bz-elipsisis-icon {
  top: 1.15rem;
}

.bz-elipsisis-icon {
  .k-button.k-button-icon {
    padding: 0;
    text-align: center;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    min-width: auto;
    margin: 0;
  }

  .k-button-icon.elipsis-btn-icon {
    padding: 0 !important;

    >svg {
      font-size: 0.875rem;
    }
  }
}

.bz-elipsisis-icon .k-button.k-button-icon:hover {
  background-color: transparent !important;
  color: $mutedTextColor;
}

.bz-elipsisis-icon .k-button.k-button-icon:hover::before,
.bz-elipsisis-icon .k-button.k-button-icon:active::before {
  opacity: 0;
}

.bz-elipsisis-icon .k-button.k-button-icon:focus::after {
  box-shadow: none;
}

.bz-trainers-card .bz-arrow-icon {
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
}

.bz-back-btn,
.buz-back-btn {
  background-color: $whiteColor;
  border-color: $primaryColor;
  color: $primaryColor;
  min-width: 2.5rem;
  padding: 0.2rem;
}

.bz-back-btn:hover,
.bz-back-btn:focus,
.bz-back-btn:active,
.buz-back-btn:hover,
.buz-back-btn:focus,
.buz-back-btn:active {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  color: $whiteColor;
}

.bz-selected-service-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 12.5rem);
}

.bz-close-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  color: #333;
  font-weight: 400;
  border-radius: 0.2rem;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 35%;
}

.bz-close-icon:hover {
  color: #89898c;
}

.bz-calender-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.bz-calender-section {
  flex: 0 0 75%;
}

.bz-slot-section {
  .bz-selected-service-card {
    height: calc(100vh - 159px);
  }

  h5 {
    font-size: 1.5rem;
  }
}

.bz-calender-section {
  display: flex;
  justify-content: center;
}

.bz-calender-section>div {
  flex: 1;
}

.bz-time-card {
  cursor: pointer;
}

.bz-time-card.active {
  background-color: #008db9 !important;
  color: #fff;
}

.bz-ol-form-control {
  border: 1px solid #008db9;
}

.dialog-options {
  &_custom_content {
    padding-top: 1rem;

    &_options {
      display: flex;
      padding-top: 1rem;
    }
  }

  &_recurrence {
    padding-top: 1rem;

    &_repeat {
      background-color: #f1f3f4;
      width: 30px;
      height: 30px;
      display: inline-flex;
      border-radius: 50%;
      justify-content: center;
      color: #646464;
      margin-right: 8px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      align-items: center;
    }
  }
}

.bz-recurence {
  .k-numerictextbox {
    width: auto;
  }
}

.bz-custom-recurrence-box {
  .k-numerictextbox {
    width: 4.4rem;
  }
}

.k-textbox {
  background-color: #f1f3f4;
}

.buzops-row {
  gap: 15px;
}

.my-bookings_upcoming-appointments_body {
  .k-listview-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .k-listview-content::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  .k-listview-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #726b6b;
  }
}

.no-slots {
  height: 100%;
  text-align: center;
  width: 100%;
  padding: 100px;
}

.buzops-highlight-card {
  border-color: #008db9;
  background-color: #008db9;
  color: #fff;

  h2 {
    color: white;
  }

  p {
    color: white !important;
  }
}

.available {
  color: green;
}

.not-available {
  color: red;
}

.float-reset-btn {
  right: 115px !important;
}

.customDropdown .k-input {
  width: 100% !important;
}

.bz-oneonone-dropdown.customDropdown {
  .k-input {
    height: 100%;
  }
}

.bz-upcoming-appoinments-card {
  height: calc(100vh - 117px);

  >.k-card-body>.my-bookings_upcoming-appointments_body>div>.k-listview {
    height: calc(100vh - 13.5rem);
  }
}

.bz-trainer-details-dropdown {
  .k-dropdown.customDropdown {
    min-width: 17rem;
    max-width: 17rem;
  }

  .k-dropdown.customDropdown>.k-dropdown-wrap {
    background-color: #f1f1f1 !important;
    border-radius: 0.4rem;
    padding-right: 1rem;
    border-color: #f1f1f1;

    >.k-input>span {
      font-size: 1.25rem;
      font-weight: 400;
      padding: 0 !important;

      >span {
        font-size: 1rem;
      }
    }
  }

  .k-dropdown.customDropdown>.k-dropdown-wrap.k-state-focused,
  .k-dropdown.customDropdown>.k-dropdown-wrap:hover {
    border-color: #008db9;
    box-shadow: none;
  }
}

.bz-calender-sec {
  z-index: 999999;

  >.k-calendar {
    border: 0;

    >.k-calendar-header {
      .k-calendar-title {
        margin: 0;
      }

      .k-calendar-nav {
        >.k-nav-today {
          display: none;
        }

        >.k-nav-prev,
        >.k-nav-next {
          padding-right: 0;
          padding-left: 0;
          margin: 0;
          min-width: auto;

          .k-icon {
            font-size: 1.2rem;
          }
        }

        >.k-button:hover::before,
        >.k-button:active::after {
          opacity: 0;
        }

        >.k-button:hover>.k-icon,
        >.k-button:active>.k-icon {
          color: #008db9;
        }

        >.k-button:focus::after {
          box-shadow: none;
        }
      }
    }

    >.k-calendar-view {
      border: 0 !important;
      padding: 0;

      .k-calendar-thead .k-calendar-th {
        padding-left: 0;
        padding-right: 0;
        font-weight: 400;
        width: 52px;
        text-align: center;
      }

      .k-calendar-td {
        width: auto;
        height: auto;
        color: $mutedTextColor !important;
        position: relative;

        >.k-link {
          margin-left: auto;
          margin-right: auto;
          border-radius: 50%;
        }
      }

      .k-calendar-td:not(.k-state-disabled)>.k-link {
        background-color: #def7ff;
        color: #008db9;
      }

      .k-calendar-td.k-state-selected .k-link,
      .k-calendar-td:hover .k-link,
      .k-calendar-td.k-state-selected:hover .k-link {
        border-color: #008db9;
        color: $whiteColor;
        background-color: #008db9;
        box-shadow: none;
      }

      .k-calendar-td.k-today>.k-link::before {
        content: "\f111";
        pointer-events: none;
        transition: all 0.18s ease-out 0.18s;
        font-family: sans-serif !important;
        font-weight: normal !important;
        font-style: normal !important;
        text-shadow: none !important;
        font-size: 0.4rem !important;
        background: #008db9;
        border-radius: 50%;
        color: #fff;
        content: attr(data-balloon);
        padding: 0.2rem;
        position: absolute;
        white-space: nowrap;
        z-index: 10;
        top: 2rem;
      }
    }

    >.k-button>.k-icon {
      font-size: 1.2rem;
    }

    >.k-prev-view,
    >.k-next-view {
      position: absolute;
      top: 0.75rem;
      z-index: 9;
      padding-right: 0;
      padding-left: 0;
    }

    >.k-prev-view {
      right: 4.7rem;
    }

    >.k-next-view {
      right: 1rem;
    }

    >.k-button:hover::before,
    >.k-button:active::after {
      opacity: 0;
    }

    >.k-button:hover>.k-icon,
    >.k-button:active>.k-icon {
      color: #008db9;
    }

    >.k-button:focus::after {
      box-shadow: none;
    }
  }

  .k-content .k-today .k-link {
    box-shadow: none;
  }

  .k-content .k-weekend {
    color: $mutedTextColor !important;
  }
}

.colomn-section-heading {
  margin-bottom: 3.5rem;

  h5 {
    font-size: 1.5rem;
  }
}

.caendar-section-heading {
  margin-bottom: 2.5rem;

  h5 {
    font-size: 1.5rem;
  }
}

.bz-recurence {
  div {
    margin: 0 0.25rem;
    padding: 0;
  }

  .k-dropdown {
    width: 100%;
  }

  .k-textbox {
    width: 100%;
  }

  .bz-check-btn {
    flex: 0 0 6.6rem;
  }
}

.k-list-container .k-list>.k-item {
  box-shadow: none;
  padding: 0.375rem 0.75rem;
}

.k-list-container .k-list>.k-item:hover {
  box-shadow: none;
}

.k-list-container .k-list .k-item.k-state-selected {
  color: #008db9;
  background-color: #fff;
}

.bz-duration-place-holder {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.bz-bookings-area {
  height: calc(100vh - 20rem);
}

.bz-save-cancel-btn .k-button {
  margin: 0 0.5rem;
}

.bz-duration-section {
  max-height: calc(100vh - 36rem);
  overflow: auto;
}

.bz-mybookings-dialog-box {
  .k-dialog {
    border-radius: 0.4rem;

    .k-header {

      .k-button:hover::before,
      .k-button:active::before {
        opacity: 0;
      }

      .k-button:focus::after {
        box-shadow: none;
      }
    }

    .k-dialog-content {
      padding: 1.5rem;

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5rem;
      }

      .k-grid {
        margin-top: 1.5rem;

        th {
          font-weight: 500;
        }

        th,
        td {
          padding: 0.5rem;
        }
      }
    }
  }
}

.bz-upcoming-appoinments-card>.k-card-body>.my-bookings_upcoming-appointments_body>.bz-service-upcoming-appoinments.bz-servicebased-appoinments {
  >.k-listview {
    height: calc(100vh - 24rem);
  }
}

.bz-no-schedules {
  color: #d9d9d9;
  font-size: 1.5rem;
  text-align: center;
}

.not-splitted .bz-save-cancel-btn.bz-edit-mode {
  display: flex;
}

.bz-calender-sec {
  padding-top: 0.7rem;

  .k-calendar {

    .k-next-view,
    .k-prev-view {
      width: auto !important;
      min-width: auto;
      margin: 0;
    }
  }
}

.bz-custom-recurrence-box {
  .dialog-options_custom_content_options {
    .k-dropdown {
      width: 100%;
    }
  }
}

.bz-recurence {
  width: 100%;
}

.bz-recurence-row {
  justify-content: center;
}

.bz-recurence-container {
  max-width: 26rem;
  padding: 0;
}

.bz-upcoming-appoinments-card .k-listview .media-body p {
  font-size: 80%;
  line-height: 1.6;
}

.bzo-userProfile-menu {
  font-size: 2rem;
}

.bz-navbar-userDetails {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >h6 {
    margin-bottom: 0;
  }

  >.logout {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    border-radius: 50%;
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1rem !important;
    margin-left: 0.5rem;
    position: relative;
    line-height: 1.375rem;
    color: #000 !important;
    cursor: pointer;
  }
}

.bz-recurence .bz-recurence-count {
  .k-numeric-wrap {
    max-height: 35px;

    >.k-input {
      height: auto;
    }
  }
}

// summary

//common utility css
.bz-fw-6 {
  font-weight: 600;
}

.bz-mt-2rem {
  margin-top: 2rem;
}

.bz-mb-2_5rem {
  margin-bottom: 2.5rem;
}

.bz-mx-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bz-fs-14px {
  font-size: 0.875rem !important;
}

.bz-fs-16px {
  font-size: 1rem !important;
}

.bz-fs-12px {
  font-size: 0.75rem !important;
}

.btn-link {
  padding: 0;
  font-size: 0.875rem;
  color: #008db9;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: #26b7e4;
  }

  &:link {
    color: #26b7e4;
  }
}

.bz-member-icon {
  width: 12px;
  margin-right: 0.25rem;
}

.badge {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  text-align: center;
  font-weight: 400;
  margin: 0 0.5rem;
  font-size: 11px;
  width: fit-content;
}

.badge2 {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-border-radius {
  border-radius: 0.42rem !important;
}

.badge-padding {
  padding: 0.9rem 0.75rem !important;
}

.badge-height {
  min-height: 1.5rem;
}

.margin-left {
  margin: 0 0.375rem;
}

.badge-success {
  background-color: #08ae0f;
  color: #fff;
}

.bz-badge2 {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bz-badge-btn.bz-badge-info {

  &:hover,
  &:focus {
    border: 1px solid #008db9;
  }
}

.bz-min-w-7rem {
  min-width: 7rem;
}

.bz-badge-warning {
  color: #C7997C;
  background-color: #FEF5F0;
  border: 1px solid #FEF5F0;
}

.bz-badge-info {
  color: #008db9;
  background-color: #D7F2F9;
  border: 1px solid #D7F2F9;
}

.bz-warning-text {
  color: #C7997C;
}

.bz-info-text {
  color: #008db9;
}

.badge-sm {
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  border-radius: 50%;
}

.bz-credit-badge {
  width: 1.1rem;
  height: 1.1rem;
  padding: 0;
  line-height: 1.6;
  background-color: #008db9;
  color: #fff;
}

/*==================== plans & Addons statuses label color ============*/

.badge-status-Active {
  background-color: #08ae0f;
  color: #fff;
  width: fit-content;
  text-transform: none;
}

.badge-status-In-Active,
.badge-status-InActive,
.badge-status-DeActivated {
  background-color: #717171;
  color: #fff !important;
}

.badge-status-Freezed,
.badge-status-Frozen {
  background-color: #5bc8fa;
  color: #fff !important;
}

.badge-status-Expired,
.badge-status-Completed {
  background-color: #ed504f;
  color: #fff !important;
}

.badge-status-Cancelled {
  background-color: #f86c6b;
  color: #fff !important;
}

.badge-status-Cancel-in-Progress {
  background-color: #f5c6cb;
  color: #7b282f !important;
}

.badge-status-AboutToExpire {
  background-color: #ffc107;
  color: #fff !important;
}

.badge-status-Suspended {
  background-color: #5957d6;
  color: #fff !important;
}

.badge-status-Future {
  background-color: #4ba1ff;
  color: #fff !important;
}

.badge-status-Future-Not.Paid,
.badge-status-Future-Paid,
.badge-status-FuturePaidSignUp,
.badge-status-FutureNonPaidSignUp {
  background-color: #4ba1ff;
  color: #fff !important;
}

//badge status text
.badge-text-Active {
  color: #08ae0f;
}

.badge-text-In-Active,
.badge-text-InActive,
.badge-text-DeActivated {
  color: #717171 !important;
}

.badge-text-Freezed,
.badge-text-Frozen {
  color: #5bc8fa !important;
}

.badge-text-Expired,
.badge-text-Completed {
  color: #ed504f !important;
}

.badge-text-Cancelled {
  color: #f86c6b !important;
}

.badge-text-AboutToExpire {
  color: #ffc107 !important;
}

.badge-text-Suspended {
  color: #5957d6 !important;
}

.badge-text-Future {
  color: #4ba1ff !important;
}

.badge-text-Future-Not.Paid,
.badge-text-Future-Paid,
.badge-text-FuturePaidSignUp,
.badge-text-FutureNonPaidSignUp {
  color: #4ba1ff !important;
}

//utility css ends

.bz-online-main-row {
  display: block;
  width: 100%;
  float: left;

  >.bz-main-menu-area {
    width: 18rem;
    float: left;
    height: calc(100vh - 117px);
    position: sticky;
    top: 1.58rem;

    .k-card-body {
      padding: 0;
    }

    .main-menu {
      .bz-back-btn {
        margin-top: 1rem;
        margin-left: 1rem !important;
      }

      h6 {
        padding: 1rem 1rem 0 1rem;
      }

      hr {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .mini-menu {
      .k-menu-link {
        padding-left: 0 !important;

        .svg-inline--fa {
          margin-right: 0 !important;
        }
      }
    }

    .k-menu {
      margin-left: 1rem;
      margin-right: 1rem;

      >.k-menu-item {
        .k-menu-link {
          padding: 0.35rem 0 0.35rem 0.5rem;
          justify-content: space-between;

          a {
            display: inline-flex;
            align-items: center;
            width: 100%;
          }

          .bz-menu-text {
            font-size: 0.875rem;
          }

          .svg-inline--fa {
            margin-right: 0.75rem;
            width: 1.1rem;
            height: 1.1rem;
            color: #008db9;
          }

          >div {
            width: calc(100% - 2rem);
          }

          >.bz-menu-arrow-icon {
            width: 1.5rem;
          }
        }
      }

      >.k-menu-item.active {
        .bz-menu-arrow-icon {
          >.svg-inline--fa {
            width: 1rem;
            margin-right: 0;
            height: 1rem;
          }
        }
      }
    }
  }

  .bz-menu-list {
    height: calc(100vh - 6rem);
    overflow-y: auto;
  }

  >.bz-content-area {
    width: calc(100% - 19.5rem);
    float: left;
    margin-left: 1.5rem;

    h5 {
      font-size: 13px;
      font-weight: 600;
    }

    .bz-appoinments-info {
      max-height: 27rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
      --bs-gutter-x: 0;
      padding: 0.1rem;
      padding-right: 0.35rem;

      >.col-md-6.col-xxl-4 {
        width: 100%;
        padding: 0;

        >.bz-appoinments-info-card {
          margin-bottom: 0 !important;
          box-shadow: 0px 0px 3px 0 #e2e5e8 !important;
          border: 0 !important;

          .card-body {
            padding: 0.75rem;

            p {
              font-size: 0.875rem;
            }
          }

          img.bz-user-img {
            width: 2.5rem;
            align-self: self-start !important;
          }

          .bzo-service-status {
            font-size: 0.75rem;
          }
        }
      }

      .bz-norecords-available {
        grid-column: 1 / 3;
      }
    }

    .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: repeat(2, 1fr);
    }

    .bz-payment-info-grid {

      tbody tr:not(.k-detail-row):hover,
      tbody tr:not(.k-detail-row).k-state-hover {
        background-color: #fff;
      }
    }
  }
}

//sub section row
.bz-subsection-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >.left-section {
    flex: 0 0 calc(100% - 25rem);

    .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  >.right-section {
    flex: 0 0 20rem;
  }
}

.bz-overview-cards {
  background-color: #fff3f4;
  border: 0 !important;

  .bz-upcoming-apponments-sm-card:last-of-type {
    margin-bottom: 0 !important;
  }
}

.bz-dateTime {
  small {
    font-size: 11px;
  }
}

.bz-decliened-payment-card {
  border: 0;
  background-color: #fff3f4;
  margin-right: 0.25rem;
  border-radius: 0.5rem;

  p {
    margin-bottom: 0;
    line-height: 1.55rem;
  }

  .k-button {
    margin: 0.5rem 0;
    font-size: 13px;
    text-transform: capitalize;
    padding: 0.4469rem 0.5rem !important;
    min-width: auto;

    .btnNotloading {
      display: none;
    }
  }
}

.bz-quick-insight {
  min-height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bz-content-text-row {
    grid-template-columns: 25% 2.5rem calc(75% - 2.5rem) !important;

    .bz-content-body {
      min-width: auto !important;
      width: auto !important;
      max-width: 100% !important;
    }

    .bz-payment-options {
      grid-gap: 0.5rem;
    }
  }
}

.bz-summary-menus-card {

  .k-card-body {
    .k-menu:not(.k-context-menu) {
      background-color: transparent !important;

      .k-menu-item.active {
        color: #008db9 !important;
      }

      .k-menu-item.inactive {
        color: black !important;
      }
    }

    .k-menu:not(.k-context-menu)>.k-item:hover,
    .k-menu:not(.k-context-menu)>.k-item.k-state-hover {
      color: #008db9 !important;
    }

    .k-menu:not(.k-context-menu)>.k-item {
      color: black !important;
    }

    .k-menu:not(.k-context-menu)>.k-item:focus,
    .k-menu:not(.k-context-menu)>.k-item.k-state-focused {
      box-shadow: none;
    }
  }
}

.bz-overview-cards {
  max-height: 14.3rem;
}

.bz-content-child-text-row {
  display: grid;
  grid-template-columns: 50% 2.5rem calc(70% - 2.5rem);
  align-items: center;
  margin-bottom: 0.75rem;
  width: 100%;

  .bz-content-body {
    align-items: center;

    .bz-card-details {
      align-items: center;
    }

    .btn-link {
      padding: 0;
      font-size: 0.875rem;
      color: #008db9;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: #26b7e4;
      }
    }
  }
}

.bz-content-text-row {
  display: grid;
  grid-template-columns: 30% 2.5rem calc(70% - 2.5rem);
  align-items: center;
  margin-bottom: 0.75rem;
  width: 100%;

  .bz-content-title {
    cursor: default;
  }

  .bz-content-body {
    align-items: center;
    max-width: 600px;

    .bz-card-details {
      align-items: center;
    }

    .btn-link {
      padding: 0;
      font-size: 0.875rem;
      color: #008db9;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: #26b7e4;
      }
    }
  }
}

.applied-coupons {
  .bz-content-body {
    width: auto !important;
    display: flex;

    >.subscription-name {
      display: inline-block;
      min-width: 7rem;
    }

    .bz-payment-options {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 5rem 3rem 5.5rem;
      grid-column-gap: 1rem;

      >.subscription-name {
        display: inline-block;
        min-width: 8rem;
        word-wrap: break-word;
      }

      >.badge {
        margin-right: 0;
      }

      .bz-close-icon2 {
        cursor: pointer;
      }
    }
  }

  .bz-coupon-details {
    .bz-content-body {
      display: inherit;
    }
  }
}

.bz-inner-row {
  display: flex;

  >.col-2 {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .k-dropdown-button.btn-link {
    >.k-button {
      margin: 0;
      padding: 0;
      text-align: left;
      justify-content: flex-start;
      text-transform: capitalize;

      &:hover::before {
        opacity: 0;
      }

      &:focus::after {
        box-shadow: none;
      }
    }
  }
}

.bz-appoinments-overview-card {
  background-color: #eff4f7 !important;
  max-height: initial !important;

  .bz-content-text-row {
    grid-template-columns: 45% 10% 45%;
  }
}

.bz-upcomingappointments-fullcard {
  height: 100vh;
}

.bz-summary-profile {
  .bz-row {
    >.user-pic {
      flex: 0 0 2.5rem;
      margin-right: 0.5rem;
      height: 2.5rem;
    }

    >.user-details {
      flex: 0 0 calc(100% - 3rem);

      h6 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.35rem;
        word-wrap: break-word;
      }

      .username-family-member {
        display: flex;
        gap: 0.25rem;
        justify-content: flex-start;

        .bz-family-member-icon {
          margin-left: 0.8rem;

          .k-badge-edge.k-top-end {
            transform: translate(70%, -70%);
            z-index: 9;
          }

          .k-badge-solid.k-badge-primary {
            border-color: #ff7a0e;
            color: $whiteColor;
            background-color: #ff7a0e;
          }
        }
      }

      .contact {
        display: inline-flex;
        align-items: center;

        .svg-inline--fa {
          width: 1.1rem;
          height: 1.1rem;
          margin-right: 0.25rem;
        }

        .fa-phone-volume {
          transform: rotate(317deg);
        }
      }
    }
  }
}

.bz-summary-profile.emergency-contact-info {
  >.bz-row {
    align-items: flex-start !important;
  }

  .emergency-blk {}
}

.bz-subMember-overview {
  .bz-summary-profile {
    .user-details {
      h6 {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
  }
}

.bz-service-selection {
  .bz-service-dropdown {
    .k-dropdown.customDropdown>.k-dropdown-wrap {
      background-color: #f1f1f1 !important;
      border-radius: 0.4rem;
      padding-right: 1rem;
      border-color: #f1f1f1;
      line-height: 1.2;

      >.k-input>span {
        font-size: 1rem;
        font-weight: 400;
        padding: 0 !important;
        line-height: 25px;
        width: 100% !important;

        >span {
          font-size: 1rem;
        }
      }
    }

    .k-dropdown.customDropdown>.k-dropdown-wrap.k-state-focused,
    .k-dropdown.customDropdown>.k-dropdown-wrap:hover {
      border-color: #008db9;
      box-shadow: none;
    }
  }
}

.bz-summary-page-row {
  display: grid;
  grid-template-columns: 342px auto;
  grid-column-gap: 1.5rem;

  h5 {
    font-size: 1.5rem;
  }

  >.bz-summary-content-column {
    overflow: hidden;
  }
}

.bz-schedule-btn-containet {
  display: flex;
  align-items: flex-end;

  >.k-button {
    margin: 0;
    background-color: #008db9 !important;
    border-color: #008db9 !important;

    &:hover {
      border-color: #008db9 !important;
      background-color: #008db9 !important;
      color: #c4f1ff !important;
    }
  }
}

.bz-schedule-btn-containet-oneonone {
  >.bz-schedule-btn {
    margin-right: 0.25rem !important;
  }

  .bz-schedule-btn {
    >.k-button {
      margin-right: 0 !important;
      margin-left: 0;
    }
  }
}

.bz-installment-details {
  border: 0;

  colgroup {
    >col:nth-child(1) {
      width: 10rem;
    }

    >col:nth-child(2) {
      width: 5rem;
    }
  }

  .k-grid-header {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #d9d9d9;

    >.k-grid-header-wrap {
      border-right: 0;
      margin: 0;
    }

    th.k-header {
      background-color: #fff;
      border: 0;
      padding: 0 0.5rem 0.5rem 0.5rem;

      >.k-cell-inner {
        margin: 0;
      }

      .k-link {
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0;
      }
    }

  }

  .k-grid-content {
    overflow: auto;
    max-height: 10.5rem;

    td {
      background-color: #fff;
      border: 0;
      padding: 0.4rem 0.5rem;
      font-size: 0.875rem;
      border-bottom: 1px solid #d9d9d9;

      &:hover {
        background-color: #fff;
      }
    }
  }

  colgroup {
    col {
      width: 130px;
    }
  }
}

@media screen and (max-width: 768px) {

  .mobile-onsite-checkins {
    display: block !important;
  }

  .mobile-onsite-checkins .section-1 {
    width: 100% !important;
  }

  .toolcontainerbuzpss {
    display: block !important;
    text-align: right;
    padding-right: 25px;
  }

  .bz-summary-menus-card {
    margin-bottom: 1.5rem;
  }



  .livestream_client>.media .bz-user-img {
    width: 45px;
    height: 45px;
  }

  .liststrem .livestream_time,
  .liststrem .ba-date,
  .liststrem .ba-date-month {
    width: auto;
    display: inline-block;
    font-size: 14px;
  }

  .liststrem .ba-date,
  .liststrem .ba-date-month {
    margin-left: 5px;
  }

  .mobile-align {
    justify-content: inherit !important;
  }

  .border-mobile {
    border: 1px solid #eee;
    border-width: 1px 0;
  }

  .liststrem .border-mobile .mt-3.customername,
  .liststrem .border-mobile .checkedinfor {
    margin: 0 !important;
  }

  .liststrem .livestream_info_walk,
  .liststrem .media.livestream_info .bz-user-img {
    width: 40px;
    height: 40px;
  }

}

.bz-appoinments-info-card {
  box-shadow: 0 2px 18px #00000005 !important;
  border-color: #eaedf2 !important;
  border-width: 0 !important;
  background: #fff !important;
  border-style: solid !important;
  border-radius: 3px;
  cursor: default;

  h6 {
    text-transform: capitalize;
  }

  .bz-top-row {
    display: grid;
    grid-template-columns: 3rem 1fr 3rem;
    align-items: flex-start;
    margin-bottom: 1rem;
    min-height: 40px;

    >.column-1 {
      img.bz-user-img {
        width: 2.5rem;
      }
    }

    >.column-2 {
      h5 {
        font-weight: 600;
        text-transform: capitalize;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }

  .bz-bottom-row {
    p {
      margin-bottom: 0;
      color: $mutedTextColor;

      .svg-inline--fa {
        margin-right: 0.5rem;
        color: #008db9;
      }
    }
  }

  .bzc-date-sec {
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;

    >.svg-inline--fa {
      margin-right: 0.5rem;
      color: #008db9;
    }
  }

  .bzc-time-sec {
    color: $mutedTextColor;
    font-size: 0.875rem;

    .svg-inline--fa {
      margin-right: 0.5rem;
      color: #008db9;
    }
  }
}

.k-menu-vertical>.k-menu-item>.k-menu-link {
  padding: 3px 0.5rem;
}

.menu-row {
  display: flex;
  height: 100%;
  width: 100%;

  .main-menu {
    width: 100%;
  }
}

.bz-main-menu-area {
  .main-menu {
    padding-bottom: 1rem;
  }
}

.mini-menu {
  border-right: 1px solid lightgrey;
}

.bz-Manage-User-Img>div {
  position: relative;
  width: 7rem;
}

.bz-Manage-User-Img>div>img {
  width: 7rem;
  height: 7rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.bz-Manage-User-Img>div>img {
  border-color: lightgrey;
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.bz-Manage-User-Img span.bz-delete-userImg {
  color: #fff;
  border-color: #f3afaf;
  background: #fa0909;
}

.bz-Manage-User-Img span.bz-delete-userImg {
  position: absolute;
  left: 0.5rem;
  bottom: 0rem;
  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 5rem;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  font-size: 0.6rem;
  text-align: center;
}

.bz-Manage-User-Img span.bz-upload-userImg {
  color: #626684;
  border-color: #3a98ff;
  background: #3a98ff;
  color: #fff;
}

.bz-Manage-User-Img span.bz-upload-userImg {
  position: absolute;
  right: 0.6rem;
  bottom: 0rem;

  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 5rem;
  padding: 0.2rem 0.3rem;
  font-size: 0.6rem;
  cursor: pointer;
}

.bz-add-card-field {
  >.payment-card-input {
    >.col-md-10 {
      padding: 0;
      max-width: 32rem;

      #field-wrapper {
        >.row {
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          padding: 0.4rem 0;

          label {
            margin: 0;
          }

          .credit-card-input {
            font-size: 0.875rem !important;
            padding: 0;
          }

          >.col-2.col-sm-1 {
            flex: 0 0 2.5rem;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 1.5rem;
              height: auto;
            }
          }

          >.col-8.col-sm-5 {
            flex: 0 0 calc(100% - 16rem);
            padding: 0;
          }

          >.col-6.col-sm-2 {
            flex: 0 0 5.5rem;
            padding: 0;
          }

          >.col-6.col-sm-3 {
            flex: 0 0 7rem;
            padding: 0;
          }
        }
      }
    }
  }
}

.bz-add-bank {
  .k-textbox {
    font-size: 0.875rem !important;
  }
}

.bz-add_showmore {
  align-items: center;

  >.col-2 {
    flex: 0 0 6rem;
  }
}

.bz-associated-groups {
  background-color: #fff;
  box-shadow: none;

  >.card-body {
    padding: 0px !important;

    >.row {
      margin: 0;
    }

    .bz-packages-tags {
      padding: 0;

      .k-chip-list {
        display: grid;
        display: -ms-grid;
        display: -moz-grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .k-chip:first-of-type {
        margin-left: 0.25rem;
      }
    }
  }
}

.bz-content-area {
  .bz-payment-info-grid {
    overflow: auto;
    max-width: 67.3rem;
  }
}

.bz-payment-info-grid {
  .k-grid-table {
    td {
      .k-button[label="Refund"] {
        min-width: initial !important;
      }

      .k-button[label="PayNow"] {
        min-width: initial !important;
      }
    }
  }
}

.btnNotloading {
  display: none;
}

.bz-service-card.bz-plans-card {
  h5 {
    justify-content: space-between;
    align-items: flex-start !important;

    .bz-plan-title {
      display: inline-block;
      min-width: 7.5rem;
      font-size: 0.875rem !important;
      max-width: 15rem;

      .tax-name-clip {
        max-width: 95%;
      }

      div {
        &:first-child {
          display: inline-flex;
          width: calc(100% - 1.6rem);
        }

        overflow: hidden !important;

        >a {
          max-width: 13rem !important;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  p.card-subtitle {
    font-size: 0.75rem;
  }

  p.card-subtitle.flex-txt {
    align-items: self-start;
    padding-right: 3rem;
  }
}

.bz-plans-card.bz-arrow-right .bz-arrow-icon {
  top: 2.5rem !important;
}

.bz-service-card .bz-service-card-inner {
  padding-right: 0 !important;
}

//dot color css
.bz-plans-card.Active {
  .bz-dot::before {
    background-color: #08ae0f;
  }
}

.bz-plans-card.Cancelled {
  .bz-dot::before {
    background-color: #f86c6b;
  }
}

.bz-plans-card.Completed {
  .bz-dot::before {
    background-color: #ed504f;
  }
}

.bz-plans-card.Expired {
  .bz-dot::before {
    background-color: #ed504f;
  }
}

.bz-plans-card.In-Active {
  .bz-dot::before {
    background-color: #717171;
  }
}

.bz-plans-card.InActive {
  .bz-dot::before {
    background-color: #717171;
  }
}

.bz-plans-card.Freezed {
  .bz-dot::before {
    background-color: #5bc8fa;
  }
}

.bz-plans-card.Frozen {
  .bz-dot::before {
    background-color: #5bc8fa;
  }
}

.bz-plans-card.AboutToExpire {
  .bz-dot::before {
    background-color: #ffc107;
  }
}

.bz-plans-card.Suspended {
  .bz-dot::before {
    background-color: #5957d6;
  }
}

.bz-plans-card.Future {
  .bz-dot::before {
    background-color: #4ba1ff;
  }
}

.bz-menu-btn.k-button {
  display: none;
  margin: 0;
  padding: 0.2rem 0.5rem;
  min-width: auto;
  background-color: #fff;
  border-radius: 0.2rem !important;
  text-transform: uppercase;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.5rem;
}

.bz-menu-close-icon {
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  float: right;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #84858c;
  margin: 0.25rem;

  &:hover {
    color: #000;
  }
}

.k-overlay.bz-overlay-menu {
  display: none;
}

.bz-familyMember-dialog {
  .k-grid-header-wrap {
    >table {
      width: 100% !important;
    }
  }

  .k-grid-table {
    width: 100% !important;
  }
}

.bz-payment-options,
.bz-applied-coupon-details {
  background-color: #f1f1f1;
  padding: 0.35rem 1rem;
  border-radius: 0.25rem;
}

.bz-plan-note {
  .k-notification-container {
    margin: 0;
  }

  .k-notification {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}

.bz-plan-note.bz-cancellation-notification {
  .k-notification {
    padding: 0;
    border: 0;
    color: #ff7a0e;
  }

  .bz-black-font {
    .k-notification {
      color: #000;
    }
  }
}

.bz-plan-note.bz-planFreeze-notification {
  .k-notification {
    color: #ff7a0e;
  }
}

.bz-addons-hr:last-of-type {
  display: none;
}

.bz-addon-cancellation {
  .bz-mb-2_5rem.Plan {
    margin-top: 0;
  }

}

//media query
@media screen and (min-width: 1800px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(4, 1fr);
  }

  .bz-service-appoinment-info.bz-subsection-row {
    >.left-section .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: 1500px) {
  .bz-payment-options {
    max-width: 35rem;
  }
}

@media screen and (max-width: 1460px) {
  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1366px) {
  .bz-online-main-row>.bz-content-area .bz-payment-info-grid colgroup>col:nth-child(4) {
    width: 100px !important;
  }

  .bz-online-main-row>.bz-content-area .bz-payment-info-grid colgroup>col:nth-child(3) {
    width: 100px !important;
  }

  .bz-online-main-row>.bz-main-menu-area .main-menu .k-menu {
    padding: 0;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1200px) {
  .bz-service-appoinment-info.bz-subsection-row {
    flex-wrap: nowrap;

    >.left-section .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 1300px) {

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-6.col-sm-2,
  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-6.col-sm-3 {
    flex-basis: 4rem;
    width: 4rem;
  }

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-8.col-sm-5 {
    flex-basis: calc(100% - 12rem);
    width: calc(100% - 12rem);
  }

  .bz-service-overview-page {
    .bz-subsection-row {
      >.left-section {
        .bz-appoinments-info {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.viewdownalodinpreset {
  padding: 4px 24px;
}

@media screen and (max-width: 1249px) {
  .bz-subsection-row.bz-myprofile-container {
    >.left-section {
      flex-basis: 95%;
    }
  }

  .bz-subsection-row.bz-overview-page-row {
    >.right-section {
      flex-basis: 95%;
    }
  }

  .bz-subsection-row.bz-plan-overview {
    >.left-section {
      flex-basis: 95%;
    }
  }

  .viewdownalodinpreset {
    padding: 4px 12px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1249px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1199px) and (min-width: 991px) {
  .bz-service-appoinment-info.bz-subsection-row {
    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }

  .bz-appoinments-overview-card {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 1100px) {}

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  .bz-online-main-row {
    >.bz-content-area {
      .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
        grid-template-columns: repeat(2, 1fr) 9rem;

        >.bzc-close-icon {
          grid-area: 1/3/2/4;
          grid-row: 1 / span 2;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {}

@media screen and (max-width: 1024px) {

  .bz-menu-close-icon,
  .bz-menu-btn.k-button {
    display: inline-flex;
  }

  .bzo-mobile-responsive {
    >.bz-main-menu-area {
      display: none;

      .bz-summary-menus-card {
        border-radius: 0;
      }
    }

    >.bz-content-area {
      width: 100%;
      margin-left: 0;
    }
  }

  .bzo-mobile-responsive.active {
    >.bz-main-menu-area {
      display: block;
      position: fixed;
      top: 64px;
      left: 0;
      bottom: 0;
      height: 100%;
      z-index: 99999;
      background-color: #fff;
    }

    .k-overlay.bz-overlay-menu {
      display: block;
      z-index: 9;
    }

  }
}

@media screen and (max-height: 991px) {
  .bz-service-card.bz-plans-card {
    max-height: inherit;
  }
}

@media screen and (min-width: 621px) and (max-width: 991px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-add-card-field>.payment-card-input>.col-md-10>.k-form-field>.credit-card-input-form {
    max-width: 80%;
  }
}

@media screen and (min-width: 601px) and (max-width: 991px) {
  .bz-service-appoinment-info.bz-subsection-row {
    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .mble-subscription-badges {
    display: block;
    margin: 5px 0;
  }

  .marappts {
    margin-top: 0 !important;
    padding: .375rem 0;
  }

  .appointment-rates-table .row.col-md-12.m-0 {
    padding: 0;
  }

  .deleteappointmentrates {
    text-align: right;
  }

  .signature-blk .k-window-content {
    width: 100% !important;
  }

  .signature-blk .card-img-top {
    width: 150px;
    display: block;
    margin: auto;
  }

  .signature-blk .k-window .k-window-titlebar {
    justify-content: space-between;
  }

  .bz-service-card.bz-plans-card {
    max-height: inherit;
  }

  .bz-service-overview-page {
    .bz-subsection-row {
      >.left-section {
        flex-basis: 100%;
      }

      >.right-section {
        flex-basis: 100%;
      }
    }
  }

  .bz-service-overview-page .bz-subsection-row>.left-section .bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-back-btn {
    position: unset !important;
    color: #000;
  }

  .applied-coupons .col-md-4 {
    text-align: left !important;
  }

  .service-titlename {
    display: inline-block;
    width: 145px;
  }

  .service-titlename2 {
    display: inline-block;
    width: 200px;
  }

  .service-para {
    display: flex !important;
    justify-content: space-between;
  }

  .clientdetailslivestream {
    justify-content: start !important;

  }

  .livestream_client-padding-mobile {
    padding: 10px 0;
  }


}

.mble-ver-txt {
  font-size: 0.78rem !important;
}

@media screen and (min-width: 601px) and (max-width: 720px) {
  .bz-content-text-row .bz-payment-options.bz-content-body {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 601px) {
  .bz-addon-cancellation {
    .k-dropdown-width-100 {
      max-width: 16rem;
    }

    .k-dropdown {
      max-width: 16rem;
    }

    textarea {
      max-width: 16rem;
    }

    p.notificxation-blue {
      max-width: 16rem;
      font-size: 80%;
    }

    .bz-plan-note .k-notification-container {
      max-width: 16rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .mble-ver-txt {
    display: none;
  }

  .bz-online-main-row>.bz-main-menu-area .main-menu .bz-back-btn {
    position: unset;
  }

  .Booking-Main {
    .second-panel {
      .calendar-main {
        flex-wrap: wrap;

        .availableTimes-Item {
          .render-time-duration.prospect-user {
            grid-template-columns: 1fr !important;
          }
        }
      }
    }
  }

  .bz-content-text-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .bz-colon {
      display: none;
    }

    .bz-content-body {
      width: 100%;
      flex-wrap: wrap;

      >.subscription-name {
        max-width: 5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .bz-content-title {
      margin-bottom: 0.25rem;
      text-align: left !important;
    }
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-info,
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: 1fr;
    padding-right: 0.5rem;
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    max-height: calc(100vh - 250px);
  }

  .bz-subsection-row {
    flex-direction: column;

    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }

  .general-info-blk {
    flex-wrap: unset !important;
  }

  .bz-package-audit-history-list {
    >span {
      display: flex !important;
      flex-direction: column;

      .bz-activity-dis-div {
        padding: 0.25rem 0 0 !important;
      }
    }
  }

  .bz-content-area {
    .bz-mt-2rem.bz-mb-2_5rem {
      margin: 1rem 0;
    }
  }

  .bz-online-main-row>.bz-content-area h5 {
    padding-top: 0 !important;
  }

  .bz-profile-overview {
    .btn-link {
      margin-left: 3.6rem;
    }
  }

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row {
    display: grid;
    grid-template-columns: 2rem calc(100% - 12rem) 4rem 4rem;
    grid-column-gap: 0.5rem;
    align-items: center;

    >.col-2.col-sm-1 {
      width: 100%;

      img {
        height: 1rem;
      }
    }
  }

  .bz-service-overview-page .bz-subsection-row>.left-section .bz-appoinments-info {
    grid-template-columns: 1fr;
  }

  .bz-appoinments-overview-card {
    .bz-content-text-row {
      display: grid;

      .bz-colon {
        display: block;
      }
    }
  }

  .bz-service-payments-page {
    .installemt-grid.bz-installment-details {
      width: 100% !important;
    }
  }

  .payment-profile-credit-card-Item {
    grid-template-columns: 2fr 1fr !important;
    gap: 7px;
  }

  .add-payment {
    align-items: flex-start !important;

    input[type="radio"] {
      margin-top: 0.5rem;
    }
  }
}

p.CancelInfo {
  color: #ff7a0e;
  font-size: 12px;
}

.CancelInfo.notificxation-blue {
  color: blue;
}

.bzc-failedTransactionGrid {
  colgroup>col {
    width: 6.5rem;
  }
}

//classes css

.bzo-PoweredBy {
  display: none;
}

.bz-fs-1rem {
  font-size: 1rem !important;
}

.bz-fw-600 {
  font-weight: 600;
}

.bz-fw-700 {
  font-weight: 700;
}

.form-control:focus {
  box-shadow: none;
  border-color: #008db9;
}

.k-dialog-wrapper .k-dialog {
  position: fixed;
  border: none;
}

@media screen and (max-width: 479px) {
  .k-widget.k-window.k-dialog {
    width: 98% !important;
    min-width: auto;
  }
}

.qrcodescanner {
  max-width: initial !important;
  width: 100%;
  min-height: 400px;
  height: 100%;
}

.bz-classes-layout {
  background-color: #fff;
}

.bz-classList-container.k-card {
  box-shadow: none !important;
  background: transparent;
  border: 0;

  >.k-card-header {
    border-bottom: 0;
    background-color: transparent;

    >.k-card-title {
      font-size: 1rem;
      line-height: 1.2;
      font-weight: 600;
      margin: 0;
      color: #172a3f;
    }

    >.bz-classes-action {
      flex: 0 0 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .bz-classes-search {
        margin: 0 0.5rem;
        position: relative;

        >.form-control {
          min-width: 15rem;
          font-size: 14px !important;
          height: 34px;
          padding-left: 2rem;

          &:focus {
            box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
            -webkit-box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
            -moz-box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
            border-color: #b5b5b5;
          }
        }

        .fa-search {
          position: absolute;
          top: 0.6rem;
          left: 0.6rem;
          color: $mutedTextColor;
        }
      }
    }

    .bz-classes-searchicon {
      margin: 0 .5rem;
      position: relative;

      .form-control {
        min-width: 15rem;
        font-size: 14px !important;
        height: 34px;
        padding-left: 2rem;
      }

      .fa-search {
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        color: $mutedTextColor;
      }
    }

    >.bz-classes-action.group-event-flex {
      flex: 0 100%
    }

    .bz-btn-type2 {
      >.bz-white-btn {
        border-width: 0.0625rem;
        border-style: solid;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        vertical-align: middle;
        border-color: #e4e4e4;
        color: #5a5c5f;
        background-color: #fafbfb !important;
        text-decoration: none;
        outline: none;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        cursor: pointer;
        border-radius: 0.25rem !important;
        background: transparent;
        margin: 0 0.5rem;
        height: 34px;

        &:hover {
          background-color: #008db9 !important;
          color: #fff;
          border-color: #008db9;

          .svg-inline--fa {
            color: #fff;
          }
        }
      }

      .svg-inline--fa {
        margin-right: 0.5rem;
        font-weight: 300;
        color: $mutedTextColor;
      }
    }
  }

  >.k-card-body {
    .k-listview {
      background-color: transparent;
      height: calc(100vh - 8rem);

      .buz-online-list-view {
        box-shadow: 0 2px 18px #00000005 !important;
        border-color: #eaedf2 !important;
        border-width: 0.0625rem !important;
        background: #fff !important;
        border-style: solid !important;
        border-radius: 3px;

        .buzopsListItem {
          font-size: 0.875rem;
          font-weight: 600;
          text-transform: capitalize;
        }

        .buzopsListArrow {
          font-size: 1.5rem;
          color: $mutedTextColor;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.k-dropdown-button.bz-btn-type2 {
  >.k-button {
    border-width: 0.0625rem;
    border-style: solid;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    vertical-align: middle;
    border-color: #e4e4e4;
    color: #5a5c5f;
    background-color: #fafbfb !important;
    text-decoration: none;
    outline: none;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    cursor: pointer;
    border-radius: 0.25rem !important;
    background: transparent;
    margin: 0 0.5rem;
    height: 34px;

    &:hover {
      background-color: #008db9 !important;
      color: #fff;
      border-color: #008db9;

      .svg-inline--fa {
        color: #fff;
      }
    }
  }

  .svg-inline--fa {
    margin-right: 0.5rem;
    font-weight: 300;
    color: $mutedTextColor;
  }
}

.bz-classSessionList-container.k-card {
  >.k-card-body {
    .k-listview {
      background-color: transparent;
      height: calc(100vh - 14.375rem);
    }
  }
}

.sessionListArrow {
  margin-top: 0.8em;
  font-size: 2em;
  float: right;
}

.k-content .k-state-active .k-animation-container {
  width: -webkit-fill-available;
}

.sessionListRow {
  margin-bottom: 10px;
  font-weight: bold;
}

.sessionDetailsBlock {
  vertical-align: "middle";
  text-align: "center";
  font-size: "small";
  width: "50px";
}

.sessionInstructorBlock {
  white-space: nowrap;
  vertical-align: "middle";
  text-overflow: "ellipsis";
  overflow: "hidden";
  padding-right: "10px";
}

.sessionInstructorBlock {
  table {
    border: none;

    td {
      border: none;
      padding: 0;
    }
  }
}

td.sessionDetails {
  text-align: "right";
  padding-right: "10px";
}

span.sessionTime {
  font-size: larger;
}

span.fontSmaller {
  font-size: smaller;
}

span.fontSmall {
  font-size: small;
}

.progressBarRow {
  vertical-align: "middle";
  text-align: "center";

  .k-progressbar {
    width: 14em;
  }
}

.arrowIconRow {
  vertical-align: middle;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .sessionListRow {
    font-size: smaller;
  }

  .progressBarRow {
    .k-progressbar {
      width: 10em;
    }
  }
}

.edit-session-body {
  .k-colorpalette {
    padding: 0.5em 0em;
  }

  .switch-label {
    padding-left: 1.2em;
    font-weight: 500;
  }

  .k-numerictextbox {
    width: 5.2em;
  }

  h6.session-header {
    padding-top: 0.5em;
  }

  p.selected-data {
    font-size: 1.2em;
  }
}

.session-table {
  .select-sessions-button {
    display: flex;
    justify-content: flex-end;
  }

  .edit-events-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    background-color: #fff;
  }

  td.capitalize {
    text-transform: capitalize;
  }

  p.dialogText {
    text-align: center;
  }

  .filter-row {
    padding-bottom: 1.2em;

    .k-dropdown {
      width: 8em;
    }
  }
}

div.update-session-page {
  p.selected-data {
    font-size: 1.2em;
  }

  div.row.selected-color {
    color: #ff7a0e;
  }
}

.k-grid-content::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #d9d9d9;
}

.k-grid-content::-webkit-scrollbar {
  background-color: #f5f5f5;
}

.k-grid-content::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #726b6b;
}

.bz-classes-layout {
  .k-chip-list {
    .k-chip {
      width: 38px;
      height: 38px;
      padding: 0;
      border-radius: 50%;
      background-color: #d9d9d9;

      >.k-chip-content {
        text-align: center;
      }

      .k-selected-icon-wrapper {
        display: none;
      }
    }

    .k-chip-filled.k-chip-selected {
      background-color: #ff7a0e;
      color: #fff;
    }
  }

  .k-datepicker .k-select,
  .k-timepicker .k-select,
  .k-datetimepicker .k-select,
  .k-dateinput .k-select,
  .k-numerictextbox .k-select {
    background-color: #fff;
  }

  .k-dropdown .k-dropdown-wrap {
    height: 32px;

    >.k-input {
      height: 100%;
    }
  }

}

.k-switch-on:focus,
.k-switch-on.k-state-focused,
.k-switch-off:focus,
.k-switch-off.k-state-focused {
  box-shadow: none;
}

.k-switch-container {
  width: 48px;
  flex-grow: 0;

  .k-switch-handle {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }

  .k-switch-on .k-switch-handle {
    left: calc(100% - 18px);
  }
}

.k-switch-on .k-switch-container {
  border-color: #008db9;
  background-color: #008db9;
}

.k-switch-off .k-switch-container {
  border-color: #008db9;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 16px);
}

/*-------------- calendar related css start---------*/
.bz-on-top {
  position: absolute;
  z-index: 99;
  right: 0;
  font-size: 0.75rem;
}

.bz-onsite-Access-date .bz-on-top {
  position: relative;
}

.bz-on-top:focus {
  outline: 0;
}

.bz-unstyled-list {
  list-style-type: none;
}

.bz-option-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.bz-date-filter-options {
  padding: 0.3rem 0.6rem;
  text-align: left;
  font-size: 0.75rem;
  cursor: pointer;
}

.bz-date-filter-calendar {
  float: left;
  clear: left;
  border-width: 0.0625rem;
  border-style: solid;
  position: relative;
  padding: 0;
}

.bz-date-filter-calendar .k-calendar-range .k-content td,
.bz-date-filter-calendar .k-calendar-range .k-content th {
  padding: 0 !important;
}

.bz-date-filter-calendar .k-calendar-range .k-calendar-view {
  min-height: 11.5rem;
}

.bz-border-left-1 {
  border-left-width: 0.0625rem;
  border-left-style: solid;
}

.bz-date-filter-textBox {
  cursor: pointer;
  padding: 0.3125rem 0.625rem;
  border-width: 0.0625rem;
  border-style: solid;
  width: auto;
  position: relative;
  float: left;
  border-radius: 0.25rem;
}

.bz-date-filter-textBox .fa-caret-down {
  font-size: 0.9rem;
}

.bz-date-filter-textBox:focus {
  outline: 0;
}

.bz-date-filter-btn {
  width: 100%;
  padding: 0.25rem !important;
  font-size: 0.75rem;
  border-bottom-left-radius: 0.3rem;
}

.bz-date-filter-calendar {
  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 0.3rem;
  position: relative;
  top: 0.25rem;
  display: flex;
  z-index: 9999;
  max-width: 480px;
  border-color: #ccc;

  .bz-filter-container {
    .bz-unstyled-list {
      >li {
        &:hover {
          background-color: #ebebeb;
        }
      }

      >li.bz-option-selected {
        background-color: #008db9;
      }
    }
  }

  .bz-calender-sec {
    padding-top: 0;
  }

  .k-calendar {
    .k-calendar-header {
      .k-calendar-title {
        background-color: #fff !important;
        color: #000;
        border: 0;
        position: absolute;

        &:hover {
          color: #008db9;

          &::before {
            opacity: 0;
          }
        }

      }

      .k-calendar-nav {
        >.k-button {
          background-color: #fff !important;
          color: #000;
          width: calc(1.4285714286em + 10px);
          height: calc(1.4285714286em + 10px);
        }
      }

    }

    .k-calendar-view {
      .k-calendar-content {
        .k-calendar-th {
          width: 38px;
          height: 29px;
          font-size: 0.75rem;
          font-weight: 600;
          color: #0a0a0a;
          padding: 3px !important;
          line-height: 1.5;
          border: 0;
        }
      }
    }
  }

}

.bz-date-filter-calendar .k-calendar-range .k-content {
  width: 100%;
}

.bz-filter-container>ul {
  width: 6.75rem;
  height: calc(100% - 1.5rem - 0.4rem);
  margin-bottom: 0;
  margin-top: 0.4rem;
}

.bz-date-filter-calendar .k-calendar-header {
  padding: 0.4rem 0 0.2rem 0 !important;
  justify-content: center;
}

.bz-date-filter-calendar .k-calendar-header .k-button {
  padding: 0.4rem 0.5rem;
}

.bz-date-filter-calendar .k-calendar-header .k-title,
.bz-date-filter-calendar .k-calendar-range .k-content th {
  font-weight: 600;
  text-align: center;
  opacity: 1;
}

.bz-date-filter-calendar #multiViewCalendar {
  border-width: 0.0625rem;
  border-style: solid;
  border-left-width: 0.0625rem;
  border-left-style: solid;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  min-width: 315px;
  padding: 0.5rem;
  overflow: inherit;
  border-color: transparent;
  border-left-color: lightgrey;
}

.bz-filter-container {
  padding-top: 0.5rem;
}

.bz-date-filter-calendar .k-calendar-range .k-calendar-nav {
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 0.4rem;
  margin-right: 1.2rem;
}

.bz-date-filter-calendar .k-calendar-monthview .k-content .k-link {
  font-size: 0.75rem;
}

.bz-option-selected {
  background-color: #008db9;
  color: #fff;
}

.bz-date-filter-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.bz-date-filter-close>i {
  font-size: 1rem;
}

.bz-Plans-Date-Filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.bz-Plans-Date-Filter .bz-on-top {
  position: unset;
}

.bz-Plans-Date-Filter .bz-date-filter-textBox {
  padding: 0.5rem;
}

.bz-date-filter-main {
  display: inline-block;

  >.bz-dateFilter-container {
    display: inherit;
  }

  .bz-date-filter-textBox {
    padding: 0.363rem 0.625rem;
    min-width: 9.45rem;
    background: #fff;
    border-color: #ccc;
    color: #717171;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bz-date-filter-textBox>span {
    display: inline-block;
    min-width: 68%;
    margin: 0 0.5rem;
  }
}

.bz-attendeesList-container.k-card {
  >.k-card-body {
    .k-listview {
      background-color: transparent;
    }
  }

  .k-tabstrip {
    >.k-content {
      padding-top: 0;
    }
  }
}

.bz-cancellation-page.bz-attendeesList-container.k-card {
  >.k-card-body {
    .k-listview {
      max-height: calc(100vh - 18rem);
      height: auto;
    }
  }
}

.enroll-client-btn-sec {
  >.k-form-buttons {
    margin-top: 0;
  }
}

.bzc-viewDesc {
  >span.btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.bzo-class-window {
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  background-color: #fff;
  border-top: none;
  border: 0;

  >.k-window-titlebar {
    background-color: transparent;
    border-bottom: 0;
    padding: 0.5rem 1.5rem;

    .k-button {
      min-width: auto;
    }

    .buzops-window-title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      text-transform: capitalize;
      color: #172a3f;
      padding-top: 10px;

      img {
        border-radius: 5px;
        cursor: pointer;
        width: 25px !important;
      }
    }
  }

  >.k-window-content {
    padding: 0.5rem 1.5rem;
    padding-top: 0;

    >.k-card {
      border-radius: 0;
    }
  }

  .k-card {
    >.k-card-header {
      padding: 0;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: #172a3f;
    }

    >.k-card-body {
      padding: 0;
    }
  }


  //buttons

  .k-button-primary,
  .k-button.k-primary,
  .k-button-solid-primary {
    font-size: 0.75rem;
    font-weight: 600 !important;
  }

  .k-button {
    font-size: 0.75rem;
    font-weight: 600 !important;
    background-color: #d9d9d9;

    &:hover {
      background-color: $mutedTextColor;
      color: #fff;
    }
  }
}

.bz-btn-type2 {
  >.bz-white-btn {
    border-width: 0.0625rem;
    border-style: solid;
    padding: 0.5rem 1rem;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    vertical-align: middle;
    border-color: #e4e4e4;
    color: #5a5c5f;
    background-color: #fafbfb !important;
    text-decoration: none;
    outline: none;
    -webkit-font-smoothing: antialiased;
    display: inline-flex;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    cursor: pointer;
    border-radius: 0.25rem !important;
    background: transparent;
    margin: 0 0.5rem;
    height: 34px;
    text-transform: capitalize;

    &:hover {
      background-color: #008db9 !important;
      color: #fff;
      border-color: #008db9;

      .svg-inline--fa {
        color: #fff;
      }
    }
  }

  .k-button {
    text-transform: capitalize;
  }

  .svg-inline--fa {
    margin-right: 0rem;
    font-weight: 300;
    color: $mutedTextColor;
  }
}

.bz-dropdown-btn {
  padding: 0;

  .k-button {
    &:hover {
      &::before {
        opacity: 0;
      }
    }

    &:focus {
      &::after {
        box-shadow: none;
      }
    }
  }

  .k-button-icontext {
    position: relative;
    margin: 0;
    padding-right: 2rem;

    .fa-chevron-down {
      position: absolute;
      right: 0.5rem;
    }
  }
}

.bz-dropdown-btn.bz-arrow-right {
  >.k-button-icontext {
    padding-right: 1.5rem !important;
    border-width: 0.0625rem;
    border-style: solid;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    vertical-align: middle;
    text-decoration: none;
    outline: none;
    -webkit-font-smoothing: antialiased;
    display: inline-flex;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    cursor: pointer;
    border-radius: 0.25rem !important;
    background: transparent;
    text-transform: capitalize;
  }
}

.k-tabstrip {
  >.k-tabstrip-items {
    border-bottom: 0;
    justify-content: center;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    >.k-item {
      border-radius: 0;
      min-width: auto;
      border: 0;

      >.k-link {
        padding: 0.25rem 0.5rem;
        font-size: 1rem;
        border-bottom: 0.0625rem solid transparent;
        color: #555;
        min-width: auto;
        text-align: center;
        justify-content: center;

        &:hover {
          border-color: #eee #eee #ddd;
        }
      }
    }

    .k-item.k-state-active {
      background-color: transparent;

      >.k-link {
        border-bottom: 0.1875rem solid !important;
        color: #555 !important;
        background: none;
        border-color: #008db9 !important;
        font-weight: 600;
      }
    }
  }

  >.k-content {
    border: 0;
    padding: 1rem 0;
    background-color: transparent;

    .bz-classes-action-btn {
      margin-bottom: 0.75rem;

      .k-form-buttons {
        justify-content: flex-end;
      }
    }

    .k-listview {
      background-color: transparent;
    }
  }
}

.bzc-sessions-list-card {
  box-shadow: 0 2px 18px #00000005 !important;
  border-color: #eaedf2 !important;
  border-width: 0.0625rem !important;
  background: #fff !important;
  border-style: solid !important;
  border-radius: 7px;
  cursor: pointer;

  >.card-body {
    border-left: 0.4rem solid #008db9;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    padding: 0.8rem 1rem;
  }

  .bzc-sessions-list-row {
    display: flex;
    align-items: center;
    justify-content: space-around;

    >.bzc-col-1 {
      flex: 0 0 15%;

      small {
        display: block;
        color: $mutedTextColor;
      }
    }

    h5 {
      font-size: 0.875rem;
      font-weight: 600;
    }

    .bzc-date-sec {
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: capitalize;
    }

    .bzc-time-sec {
      color: $mutedTextColor;
    }

    .svg-inline--fa {
      margin-right: 0.5rem;
      color: #008db9;
    }

    .bzc-arrow-icon {
      flex: 0 0 3.5rem;
      text-align: right;

      .k-icon {
        font-size: 2rem;
        color: #008db9;
      }

      .svg-inline--fa {
        font-size: 1rem;
      }

      .fa-long-arrow-alt-right {
        font-size: 1.5rem;
        color: #008db9;
      }

      .fa-window-close {
        color: #ff7a0e;
        cursor: pointer;
      }

      .fa-times-circle {
        color: #dc3545;
        cursor: pointer;
      }

      .fa-edit {
        cursor: pointer;
        color: #50cd89;
      }
    }
  }
}

.k-progressbar .k-state-selected {
  color: white;
  background-color: #008db9;
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 1rem 1rem;
}

.bz-vl {
  border-left: 1px solid #d9d9d9;
  height: 45px;
  width: 5rem;
}

.bzc-attendees-list {
  background-color: transparent;

  .bzc-attndees-card {
    margin-bottom: 0.5rem;

    >.card-body {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .k-form-buttons {
        margin-top: 0;
      }

      .buzopsListItem {
        color: #000;
        display: flex;
        align-items: center;
      }

      .fa-user-circle {
        font-size: 2rem;
        color: #008db9;
        margin-top: 0.25rem;
      }

      .k-avatar {
        margin-right: 0.5rem;
        width: 35px;
        height: 35px;
        flex-basis: auto;
        align-items: center;
        background-color: transparent;
      }

      .buzopsListArrow {
        display: inline-flex;
        align-items: center;
      }

      .fa-sticky-note {
        font-size: 1rem;
        cursor: pointer;
      }

      .bz-elipsis-icon {
        >.k-button-icon {
          width: 100px;
          height: auto;
          min-width: auto;
          justify-content: right;

          &:hover::before,
          &:active::before {
            opacity: 0;
          }

          &:focus::after {
            box-shadow: none;
          }

          &:hover {
            .fa-ellipsis-v {
              color: $mutedTextColor;
            }
          }
        }

        .fa-ellipsis-v {
          font-size: 1.1rem;

          &:hover {
            color: $mutedTextColor;
          }
        }
      }

      .bz-attendees-row {
        display: grid;
        grid-template-columns: 1fr 12rem;
        grid-column-gap: 1rem;

        .bz-badge {
          width: auto;
          height: auto;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .bzc-attndees-card.bz-session-roaster-attndees {
    .column-2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .k-dropdown-button {
        >.k-button-icon {
          width: auto;
          margin: 0;
        }
      }
    }

  }
}

.bz-statuses {
  padding: 0.3rem 0.75rem;
  display: inline-block;
  line-height: 1.1;
  border-radius: 2rem;
  font-size: 0.75rem;
  min-width: 5rem;
  text-align: center;
  margin: 0 0.2rem;
}

.bz-statuses.Notes {
  min-width: auto;
  padding: 0;
  text-align: center;
  margin: 0 0.5rem;
}

.Show {
  color: #57d797 !important;
  background-color: #d8ffda;
}

.Dispute,
.NoShow {
  color: #717171 !important;
  background-color: #e4e6ef;
}

.Failed {
  color: white !important;
  background-color: red;
}

.bz-failed {
  color: #f44336;
  background-color: #ffe0de;
}

.k-form {
  .k-label {
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.3px;
    display: block;
    margin-bottom: 8px !important;
    line-height: 16px;
    padding: 0;
  }

}

.k-label.k-label-empty {
  display: none;
}

.bzc-input-group {
  >.k-form-field {
    margin-top: 0;
    width: calc(100% - 8rem);

    .k-numeric-wrap {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  >.input-group-append {
    >.k-form-field {
      margin-top: 0;
      min-width: 8rem;

      .k-dropdown-wrap {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      .k-dropdown-wrap.k-state-focused {
        box-shadow: none;
      }
    }
  }
}

.k-checkbox-label,
.k-radio-label {
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px;
  display: block;
  margin-bottom: 0px;
  line-height: 16px;
  padding: 0;
}

.k-dropdown .k-dropdown-wrap {
  background-color: #fdfefe !important;
  border-color: #dae1ed !important;
}

.k-button {
  text-transform: uppercase;
  min-width: 5.722rem;
  margin: 0 0.375rem;
}

.bzc-grid {
  background-color: transparent;
  border: 0;

  >.k-grid-toolbar {
    border-width: 0;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .k-grid-header {
    border-width: 1px;
    border-color: #dae1ed;
    background-color: whitesmoke;

    tr {
      height: 2.1rem;
    }

    .k-header {
      border-color: #dae1ed !important;
      background-color: #f5f5f5 !important;
      color: #181c32;
      font-weight: 600;
      letter-spacing: inherit;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 0.875rem;
      padding: 6px 6px 6px 10px;

      >.k-link {
        padding: 4px 6px 4px 10px;
      }
    }
  }

  .k-column-title {
    white-space: break-spaces;
  }

  .k-grid-content {
    min-height: 2rem;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #dae1ed;
    background-color: #fff;

    tr:hover,
    tr.k-alt {
      background-color: #fff;
    }

    td {
      padding: 8px 6px 8px 10px;
      border-bottom-width: 1px;
      font-size: 0.8125rem;
      line-height: 14px;
      white-space: unset;
      vertical-align: middle;
      word-wrap: normal;

      .k-label-empty {
        display: none;
      }

      .k-form-field {
        margin-top: 0;
      }
    }
  }

  .bz-grid-btn-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .bz-remove-icon {
    font-size: 1rem;
    color: #dc3545;
    padding: 0 1rem;

    >.fa-trash-alt {
      cursor: pointer;
    }
  }
}

.bzc-specificDayGrid {
  colgroup>col {
    width: 13rem;
  }
}

.k-grid th.k-state-selected,
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
  background-color: #b5edfe;
}

.session-table {
  .k-grid {
    .k-header {
      &:first-child {
        text-align: center;
      }
    }

    .k-grid-content {
      td:first-child {
        text-align: center;
      }
    }
  }
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active,
.k-dateinput .k-dateinput-wrap.k-state-focused,
.k-dateinput .k-dateinput-wrap.k-state-active,
.k-textarea:focus-within {
  box-shadow: none;
  border-color: #89848c;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus,
.k-checkbox:focus,
.k-checkbox.k-state-focus {
  box-shadow: none;
}

.k-listview {
  background-color: transparent;

  .k-listview-content {
    background-color: transparent;
  }
}

.k-listview-content {
  background-color: transparent;

  .bz-classes-checkout {

    .bzc-sessions-list-row {
      justify-content: space-between;

      >.bzc-col-time {
        flex: 0 0 15rem;

        span {
          display: block;
        }
      }

      >.bzc-col-provider {
        .bz-provider-img {
          width: 1.5rem;
          margin-right: 0.2rem;
        }

        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 25%;
      }

      >.bzc-col-price {
        flex: 0 0 4rem;
      }
    }
  }
}

.bz-classes-checkout .bz-close-icon2 {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  cursor: pointer;

  >.fa-times {
    color: $mutedTextColor;

    &:hover {
      color: #ff7a0e;
    }
  }
}

.heading2 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0 0 1.5rem;
  color: #172a3f;
}

.bz-specificDayGrid-col-width colgroup>col:last-child {
  width: 4rem;
}

.bzc-edit-session-options-row {
  display: flex;
  align-items: center;
  min-height: 38px;

  >.column-1 {
    -ms-flex: 0 0 16rem;
    flex: 0 0 16rem;
    max-width: 16rem;
  }

  >.column-2 {
    -ms-flex: 0 0 25rem;
    flex: 0 0 25rem;
    max-width: 25rem;

    >.k-dropdown {
      width: 15rem;
    }

    >.k-numerictextbox {
      width: 15rem;
    }

    >.k-timepicker {
      width: 15rem;
    }

    .bzc-input-group {
      .k-dropdown {
        width: 8.5em;

        >.k-dropdown-wrap {
          border-radius: 0 0.25em 0.25em 0;
        }
      }

      .k-numerictextbox {
        width: 7.6em;
      }

      .k-numerictextbox .k-numeric-wrap {
        border-radius: 0.25em 0 0 0.25em;
      }
    }
  }

  .k-checkbox-label {
    font-size: 0.875rem !important;
    padding-left: 1.2em;
    font-weight: 500 !important;
    color: black;
  }
}

.k-label {
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.3px;
  display: block;
  margin-bottom: 8px !important;
  line-height: 16px;
  padding: 0;
  color: #000;
}

.bzc-notice {
  padding: 0.5rem 0;
  font-size: 1rem !important;

  >p {
    margin-bottom: 0;
    color: #ff7a0e;
  }
}

.k-widget.k-window.k-dialog {
  .k-dialog-actions {
    >.k-dialog-close {
      min-width: auto;
    }
  }

  .k-dialog-content {
    .buzopsListValidateItem {
      .bzc-date-sec {
        font-size: 1rem;
        font-weight: 600;
        text-transform: capitalize;
      }

      .bzc-time-sec {
        color: $mutedTextColor;
        font-size: 0.875rem;
      }

      .svg-inline--fa {
        margin-right: 0.5rem;
        color: #008db9;
      }
    }
  }

  .k-dialog-buttongroup {
    padding: 1rem;

    >.k-button {
      margin: 0;
    }
  }
}

.bz-norecords-available {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  background-color: #f4f6f7;
  color: #84898c;
  width: 100%;
  font-size: 0.875rem;
}

.k-grid-norecords {
  &:hover {
    background-color: #fff !important;
  }

  >td {
    color: #84898c;
    width: 100%;
    padding: 7rem !important;
    font-weight: 0.95rem !important;
  }
}

.k-upload .k-dropzone .k-dropzone-hint {
  font-size: 14px;
  margin-right: 1rem;
}

.k-upload {
  border-radius: 0.25em;
}

.bzc-sessions-list-card.bz-sessionRoaster-card {
  .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 8rem 11rem 4rem;
    grid-column-gap: 1rem;
    justify-content: space-between;
    grid-column-gap: 1.5rem;
    align-items: self-start;

    >.bz-vl {
      display: none;
    }

    >.bzc-arrow-icon {
      display: flex;
      height: 100%;
      justify-content: flex-end;

      .fa-times-circle {
        margin-right: 0;
      }
    }

    .bzo-viewDetails {
      margin-top: 1rem;

      small {
        color: #008db9;
      }
    }
  }
}

.bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row>.bzc-arrow-icon {
  position: unset;
}

.bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
  padding-right: 0;
}

.bz-purchase-class-filter {

  .k-dropdown,
  .k-datepicker {
    width: 15rem;
    max-height: 35px;
  }

  .k-autocomplete {
    width: 15rem;
    max-height: 35px;

    &:focus-within {
      box-shadow: none;
      border-color: #89848c;
    }
  }
}

.bz-purchase-checkout-list {
  .bz-classes-checkout.bzc-sessions-list-card {
    .bzc-sessions-list-row {
      grid-template-columns: repeat(2, 1fr) 6rem;
      padding: 0 0.5rem;
    }

    .bzc-sessions-list-row-useCredit {
      grid-template-columns: repeat(2, 1fr) 6rem 5rem;
      padding: 0 0.5rem;
    }
  }

  .class-title {
    color: #008db9;
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 0.875rem;
  }
}

.bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog {
  height: auto;
}

.bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog .k-dialog-content p {
  font-size: 1rem;
  line-height: 28px;
}

.k-button.bz-btn-icon {
  min-width: auto;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;

  .svg-inline--fa:hover {
    color: #000;
  }

  .fa-edit {
    color: #008db9;
  }

  .fa-plus-square {
    color: #008db9;
  }

  .fa-check-square {
    color: #28a745;
  }

  .fa-window-close {
    color: #ff7a0e;
  }
}

.bz-payout-grid.bzc-grid {
  colgroup {
    >col:last-child {
      width: 5.5rem;
    }

    >col {
      &:nth-child(1) {
        width: 3.5rem;
      }

      &:nth-child(2) {
        width: 11rem;
      }

      &:nth-child(3) {
        width: 150px;
      }

      &:nth-child(4) {
        width: 150px;
      }

      &:nth-child(5) {
        width: 380px;
      }

      &:nth-child(6) {
        width: 100px;
      }

      // &:nth-child(7) {
      //   width: 100px;
      // }
    }
  }

  .k-multiselect {
    >.k-multiselect-wrap {
      height: 1.7rem;

      .k-button {
        justify-content: space-between;
        font-size: 0.75rem;
        background-color: #008db9;
        color: #fff;
        min-width: auto;
        margin: 0;
      }
    }
  }

  .k-dropdown .k-dropdown-wrap,
  .k-numerictextbox {
    height: 1.7rem;
  }

  .k-input {
    height: auto;
  }
}

.bz-payout-grid.bzc-grid.bz-group-payouts-grid {
  colgroup {
    >col {
      &:nth-child(1) {
        width: 3.5rem;
      }

      &:nth-child(2) {
        width: 11rem;
      }

      &:nth-child(3) {
        width: 8rem;
      }

      &:nth-child(4) {
        width: 9rem;
      }

      &:nth-child(5) {
        width: 9rem;
      }

      &:nth-child(6) {
        width: 10.2rem;
      }

      &:nth-child(7) {
        width: 10.5rem;
      }

      &:nth-child(8) {
        width: 6.5rem;
      }
    }
  }

  .action-icon {
    .k-button:hover {
      background-color: transparent;

      svg {
        color: #43c2e9 !important;
      }
    }

    svg {
      &:hover {
        cursor: pointer;
        color: #43c2e9 !important;
      }
    }
  }
}

.bz-payout-history-grid {
  colgroup {
    >col {
      &:nth-child(1) {
        width: 11rem;
      }

      &:nth-child(2) {
        width: 11rem;
      }

      &:nth-child(3) {
        width: 8rem;
      }

      &:nth-child(4) {
        width: 6rem;
      }

      &:nth-child(5) {
        width: 8rem;
      }

      &:nth-child(6) {
        width: 7rem;
      }

      &:nth-child(7) {
        width: 10.5rem;
      }

      // &:nth-child(8) {
      //   width: 8rem;
      // }
    }
  }
}

.bz-configure-payout-filter {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 13rem;
  grid-column-gap: 1rem;
  align-items: center;

  .k-dropdown,
  .k-numerictextbox {
    width: 100%;
  }

  .k-multiselect {
    max-height: 35px;

    .k-button {
      background-color: #008db9;
      color: #fff;
      min-width: auto;
      margin: 0.25rem 0 0 0.25rem;
    }

    .k-clear-value {
      max-height: 35px;
    }
  }

  .k-multiselect.k-state-focused>.k-multiselect-wrap {
    border-color: #ccc;
    box-shadow: none;
  }

  >.btn-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;

    .k-button {
      min-height: 35px;
      margin: 0;
    }

    .k-primary {
      margin-left: 0.75rem;
    }
  }
}

.bzc-grid.bz-selectSession {
  max-height: calc(100vh - 13rem);
  padding-bottom: 3rem;

  colgroup {
    >col:first-child {
      width: 3rem;
    }

    >col {
      width: 10rem;
    }
  }
}

.bz-selectSession.edit-event-tbl {
  max-height: calc(100vh - 15.5rem);
}

.bzc-border-left {
  border-left-color: #dee2e6;
  border-left-width: 0.1rem;
  border-left-style: solid;
}

.bz-card-inhouse {
  border-color: #eaedf2 !important;
  box-shadow: 0 2px 18px #00000005 !important;
  border-width: 0.0625rem !important;
  border-style: solid !important;
  border-radius: 0.5rem;
}

.bz-card-heading-inhouse {
  font-size: 0.875rem !important;
  font-weight: 600;
  word-break: break-all;
}

.bz-no-background {
  background: transparent !important;
}

.bz-inhouse-class-details {
  padding: 1rem 1.5rem;

  .k-tabstrip>.k-tabstrip-items {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .k-tabstrip>.k-tabstrip-items>.k-item>.k-link {
    font-size: 0.875rem;
    padding: 10px 15px;
    font-weight: normal;
  }
}

.bz-article-headline {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
  color: #172a3f;
}

.bz-article-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
  color: #262727 !important;
  padding-bottom: 5px;
}

.bz-article-caption {
  font-size: 0.78rem;
  letter-spacing: 0;
  line-height: 1rem;
  max-width: 43.75rem;
  color: #262727 !important;
}

.bz-group-service-details {
  .bz-custom-row {
    display: grid;
    grid-template-columns: 50% 2rem calc(50% - 2rem);
  }
}

.bz-classes-layout {

  .k-datepicker,
  .k-timepicker {
    height: 34px;

    .k-input {
      height: 100%;
    }
  }
}

.bz-class-description {

  h6 {
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 0.25rem;
    margin-bottom: 0;
  }

  .bzc-class-desc {
    font-size: 90%;
    color: $mutedTextColor;
    margin-top: 0.5rem;
    border-top: 1px solid #d9d9d9;
    padding-top: 0.75rem;
  }

  .bz-shareLink-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    display: block;
    margin-bottom: 8px;
    line-height: 16px;
    padding: 0;
  }
}

.bz-class-list .k-listview-content {
  padding-right: 0.5rem;
}

.bz-addClass-form {

  .k-datepicker,
  .k-timepicker {
    height: 38px;
  }

  .k-datepicker .k-input,
  .k-timepicker .k-input {
    font-size: 0.875rem !important;
    height: unset;
  }

  overflow: auto;
}

.bz-add-group-service {
  margin-bottom: 1rem;

  .k-textbox,
  .k-datetimepicker {
    height: 38px;
  }

  .k-dropdown .k-select,
  .k-dropdowntree .k-select {
    height: 36px;
  }

  .input-group.bzc-input-group {
    .input-group-append {
      .k-dropdown {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

.hide-no-labels label {
  display: none !important;
}

.hide-no-labels .k-widget.k-dropdown {
  height: 34px !important;
}

.bz-date-filter {
  >.k-picker-wrap {
    .k-dateinput {
      order: 2;

      .k-dateinput-wrap {
        border-radius: 0 0.25em 0.25em 0;
      }
    }

    .k-select {
      order: 1;
      border-left-width: 0;
      border-right-width: 1px;
      border-radius: 0.25em 0 0 0.25em;
    }
  }
}

.bz-classes-layout {

  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea,
  input {
    font-size: 0.875rem !important;
  }
}

.k-list-container.k-popup {
  padding: 0;
  border: 0;
  box-shadow: 0 0.375rem 0.75rem #0000002d;

  >.k-list[role="menu"] {

    >.k-item {
      padding: 0.25rem 0.4rem;
      padding-left: 1.2rem;
      border: 0;
      color: #000;
      font-size: 0.875rem;
      line-height: 1.025rem;

      &:hover {
        background-color: #f5f3f3;
      }
    }
  }
}

.k-popup.k-child-animation-container {
  .k-list {
    .k-item[role="option"] {
      font-size: 0.875rem;
      cursor: pointer;
    }
  }
}

.k-popup.k-list-container.k-child-animation-container.k-group {
  .k-list[role="menu"] {
    li.k-item[role="menuItem"] {
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }
}


.k-popup.k-list-container.k-child-animation-container.k-group.oneononepresetdropdown {
  .k-list[role="menu"] {
    li.k-item[role="menuItem"] {
      font-size: 0.765rem;
      padding: 0.25rem 0.4rem;
      cursor: pointer;
    }
  }
}



.k-animation-container {
  .k-timeselector {
    padding: 0;

    .k-time-header {
      .k-time-now {
        min-width: auto;
        padding: 0;
        color: #008db9;
        text-transform: capitalize;

        &:hover::before {
          opacity: 0;
        }

        &:hover {
          color: #2787a5;
        }
      }
    }

    >.k-time-footer {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.bz-sessionList-header-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  >.column-1 {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;

    .bzc-date-sec {
      margin-left: 1rem;
      font-size: 0.875rem;
    }

    .k-datepicker {
      width: 9.5rem;
    }
  }

  >.column-2 {
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;

    .bz-classes-action-btn {
      margin-bottom: 0;
    }
  }
}

//group services css start inhouse

.bz-groupServices-inhouse {
  .bz-classes-search {
    >.form-control {
      height: 1.736rem !important;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-color: #dae1ed;
      padding-left: 1.65rem;

      &:focus {
        border-color: #dae1ed !important;
        box-shadow: none !important;
      }
    }

    >.fa-search {
      font-size: 0.65rem;
    }
  }

  .bz-group-services-card {
    margin-bottom: 0.5rem;
    min-height: 5rem;
    border: 0.0625rem solid #dee2e6;
    background-color: #fff;
    border-radius: 0.25rem;
    font-family: "Open Sans", sans-serif;
    border-left: 0.25rem solid #008db9;
    position: relative;

    >.card-body {
      position: relative;
      padding: 0.8rem;
    }

    .bz-groupServices-card-row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 1rem;
      padding-right: 2.5rem;

      >.column {
        line-height: 1;

        >label {
          font-weight: 500;
          padding-bottom: 0.2rem;
          font-size: 12px;
          color: #8f9bb3;
          display: inline-block;
          margin-bottom: 0;
          line-height: 16px;
          //text-transform: capitalize;
          letter-spacing: 0.3px;
          padding: 0 0 0.2rem;

          img {
            vertical-align: baseline;
            margin-right: 0.2rem;
            width: 0.7rem;
            height: 0.7rem;
          }
        }

        >span {
          font-size: 0.78rem;
          font-weight: 400;
          display: block;
          word-break: break-all;
          line-height: 1.2;
          padding-bottom: 0.5rem;
          color: #262727;
        }
      }
    }

    .bz-elipsis-icon-btn {
      position: absolute;
      top: 0rem;
      right: 0;

      >.k-dropdown-button {
        width: 0;
        height: 0;
        border-top: 3rem solid #008db9;
        border-left: 3rem solid transparent;

        >.k-button-icon,
        >.k-icon-button {
          min-width: auto;
          margin: 0;
          justify-content: flex-end;
          border: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 0.875rem;
          color: #fff;
          padding-right: 0.6rem;
          background-color: transparent;

          &:hover::before {
            opacity: 0;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.svg-inline--fa.k-button-icon {
  min-width: initial;
  min-height: initial;
}

.bz-group-services-list {
  .bzc-sessions-list-card {
    cursor: default;
  }

  .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 2rem;

    .bz-groupService-statuses-col {
      display: none;
    }
  }

  .k-listview {
    max-height: calc(100vh - 15.8rem);

    .k-listview-content {
      padding-right: 0.5rem;
    }
  }

  .k-listview.no-scroll-padding {
    .k-listview-content {
      padding-right: 0;
    }
  }

  .bz-elipsis-icon {
    >.k-button-icon {
      width: 1.5rem;
      height: auto;
      min-width: auto;
      justify-content: center;
      margin: 0;

      &:hover::before,
      &:active::before {
        opacity: 0;
      }

      &:focus::after {
        box-shadow: none;
      }
    }

    .fa-ellipsis-v {
      font-size: 1.1rem;
      color: #000;

      &:hover {
        color: $mutedTextColor;
      }
    }
  }

  .bz-groupService-statuses {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 31.6px;
  }

  .bz-statuses.Notes {
    >.fa-sticky-note {
      font-size: 1rem;
      cursor: pointer;
      margin-right: 0;
    }
  }
}

.bz-group-services-list.bz-past-GroupServices {
  .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 10rem 6rem 12rem;

    .bz-groupService-statuses-col {
      display: flex;
      justify-content: flex-end;
    }

    .bzc-col-1.group-name {
      h5 {
        max-width: 95%;

        >div {
          width: 8.5rem;

          >.tax-name-clip {
            max-width: 100%;
          }
        }
      }
    }

    .bz-past-statuses {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

//group services css end inhouse

.bz-class-session-btns .bz-btn-type2 .k-button {
  min-width: 1rem;
}

//calender styles
.bz-dateFilter-container {
  position: relative;

  >.bz-date-filter-calendar {
    position: absolute;
    top: 2rem;
    background-color: #fff;
    border-color: #ddd;
    box-shadow: 0.125rem 0px 0.9375rem #e2e2e2;
    background-color: #fff;
    color: #0a0a0a;

    >.bz-filter-container {
      >ul {
        padding: 0;

        >li {
          padding: 0.3rem 0.6rem;
          text-align: left;
          cursor: pointer;
          font-size: 0.75rem;
        }
      }
    }

    .bz-calender-sec>.k-calendar>.k-button {
      min-width: initial;
      padding: 0.4rem 0.5rem;
    }

    .bz-calender-sec>.k-calendar>.k-prev-view {
      right: unset;
    }

    .bz-calender-sec>.k-calendar {
      .k-calendar-header {
        .k-title {
          font-size: 0.8rem;
          font-weight: 600 !important;
        }
      }
    }
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0a0a0a;
    line-height: 1.5;
    vertical-align: middle;
    height: 2.42857143em;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td:not(.k-state-disabled)>.k-link {
    background-color: initial;
    color: #333;
    height: 2.42857143em;
    width: 2.42857143em;
    border-radius: 4px;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td:hover .k-link {
    border-color: #aeaeae;
    color: #333;
    background-color: #ebebeb;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td.k-state-selected .k-link,
  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td.k-state-selected:hover .k-link {
    border-color: #008db9;
    background-color: #008db9;
    color: #fff;
    box-shadow: inset 0 0 0 0.0625rem #008db9;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td.k-today>.k-link::before {
    display: none;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td.k-today>.k-link {
    background-color: initial;
    color: #008db9;
    border-color: #008db9;
    box-shadow: inset 0 0 0 0.0625rem #008db9;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td>.k-link {
    font-size: 0.75rem;
  }

  .k-calendar-view.k-calendar-yearview>.k-content .k-calendar-td>.k-link,
  .k-calendar-view.k-calendar-decadeview>.k-content .k-calendar-td>.k-link,
  .k-calendar-view.k-calendar-centuryview>.k-content .k-calendar-td>.k-link {
    width: 56px !important;
    height: 56px !important;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td {
    height: auto;
    border: 0;
  }

  .bz-date-filter-close {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    color: orangered;
    top: 1rem;
    right: 0.25rem;

    .fa-window-close {
      font-size: 1rem;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #e9665c;
      }
    }
  }

}

//Packages css start

.bz-packages-tags {
  margin-top: 0.5rem;

  .k-chip {
    display: inline-flex;
    padding: 0.25em 0.5em !important;
    font-size: 0.78rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0rem !important;
    border: 0;
    background-color: #d6f5ff !important;
    color: #008db9;
    width: auto !important;
    height: auto !important;
    border-left: 4px solid #008db9;
    text-align: left;
    text-transform: capitalize;
    margin: 0.25rem;

    >.k-chip-content {
      text-align: left;
      font-size: 80%;
      font-weight: 400;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  .k-chip-focused {
    box-shadow: none !important;
  }
}

.bz-badge {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008db9;
}

.bz-groupServices-tags {
  .k-chip {
    cursor: pointer;
  }
}

.bz-classList-container.bz-package-list-container .checkout-panelbar-wrapper .bz-packages-tags {
  margin-top: 0;
}

.bz-salesPerson-select {
  >.k-textbox-container.k-state-empty {
    .k-label {
      display: inline-block;
      margin-bottom: 0 !important;
      font-size: 1rem !important;
      top: calc(calc(1.4 * 1rem) + 1px + 0.375rem);
    }
  }

  >.k-textbox-container .k-label,
  >.k-textbox-container.k-state-focused .k-label {
    display: none;
  }
}

.bz-packages-subscription {
  .bz-classes-search {
    .k-autocomplete {
      min-width: 15rem;
      height: 1.736rem;

      >.k-searchbar {
        >.k-input {
          font-size: 14px !important;
          padding-left: 2rem;
          height: auto;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          border-color: #dae1ed;
        }
      }

      .k-i-close {
        height: auto;
      }
    }

    .k-state-focused {
      box-shadow: none;
      border-color: #dae1ed;
    }
  }

  .k-tabstrip>.k-tabstrip-items {
    flex-wrap: wrap;
  }
}

//cancel group service
.bz-cancellation-page {
  h5 {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .bzc-sessions-list-card {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .bzc-sessions-list-card .bzc-sessions-list-row .svg-inline--fa {
    margin-right: 0.2rem !important;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bz-row.checkbox.all {
    display: grid;
    grid-template-columns: 4rem 11rem;
  }

  .bz-row.checkbox {
    display: grid;
    grid-template-columns: 11rem;
  }

  .bz-cancellation-type {
    display: grid;
    grid-template-columns: 13rem 14.5rem;
    grid-column-gap: 1rem;

    .bzc-provider-name {
      .k-dropdown {
        width: 13rem;
      }
    }
  }

  .loading {
    .bz-cancellation-card-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 6rem;
    }

    .bz-cancellation-card-row.charge-dropdown {
      grid-template-columns: repeat(5, 1fr) 6rem;
    }

    .bz-cancellation-card-row.payment-profile {
      grid-template-columns: 1fr 10rem 10rem repeat(3, 1fr) 6rem;
    }
  }

  .bz-cancellation-card-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 0;

    .k-dropdown,
    .k-numerictextbox {
      width: 13rem;
      margin-top: 0.2rem;
    }

    .add-payment-profile {
      display: flex;
      align-items: flex-end;
    }
  }

  .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0.5rem;
  }

  .bz-cancellation-card-row.payment-profile {
    grid-template-columns: 1fr 10rem 13.7rem repeat(3, 1fr) !important;
    grid-column-gap: 0.5rem;
  }
}

.column-7.add-payment-profile {
  .k-dropdown {
    width: 15rem;
  }

  .add-new-btn {
    .k-button {
      margin-right: 0 !important;
    }
  }
}

//cancel package
.bz-cancel-package {
  >.card-header {
    .bz-card-heading {
      color: #262b40;
      font-size: 0.875rem !important;
      font-weight: 600;
      word-break: break-all;
    }
  }

  >.card-body {
    .card-title {
      small {
        font-weight: 400;
        line-height: 1;
        color: #777;
        font-size: 75%;
      }
    }

    .k-switch {
      .k-switch-container {
        display: inline-flex;
        border-color: #ccc;
      }

      .k-switch-handle {
        border-width: 1px;
        border-style: solid;
        border-color: #ccc;
        background-color: #fff;
      }
    }

    .k-switch.k-switch-off {
      .k-switch-container {
        color: #333;
        background-color: #fff;
      }

      .k-switch-handle {
        color: #333;
      }
    }

    .k-switch-on .k-switch-handle {
      color: #fff;
    }
  }
}

.form-group {
  position: relative;
}

.bz-refund-card {

  .bz-badge-circle {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    font-size: 140%;
    background-color: #ccf5f8 !important;
    color: #00cfdd !important;
  }

  .bz-bd-count-card-title {
    color: #262b40;
    font-size: 1rem;
    font-weight: 600;
  }
}

.bz-only-refund-card {
  bottom: 3rem;
  left: 0;
}

.bz-tax-refund-card {
  position: absolute;
  bottom: 0.25rem;
  left: 0.15rem;

  .bz-badge-circle {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    font-size: 140%;
    background-color: #ccf5f8 !important;
    color: #00cfdd !important;
  }

  .bz-bd-count-card-title {
    color: #262b40;
    font-size: 1rem;
    font-weight: 600;
  }
}

//audit history
.bz-package-audit-history {
  padding: 0.5rem !important;
  background-color: #fff;
  margin-bottom: 0 !important;

  .bz-package-audit-history-list {
    font-size: 0.75rem;
    padding: 0.7rem 0.5rem;
    line-height: 1rem;

    .bz-activity-time-date {
      color: #969696;

      >.fa-calendar-alt {
        color: #fdac41;
        font-size: 0.9rem;
        margin-right: 0.2rem;
      }
    }

    .bz-activity-dis-div {
      color: #000;
      padding-left: 0.8rem;
      padding-right: 0.5rem;
      font-size: 0.8rem;
      line-height: 1.3rem;
    }

    .bz-requestedBy {
      color: #423ec8;
      font-size: 0.872rem;
      margin-bottom: 0;
    }
  }
}

//package details
.bz-package-details {
  .bz-package-btns {
    display: flex;
    align-items: center;
    height: 100%;

    >.bz-btn-type2 {
      .bz-icon-btn {
        color: #717171;
        text-decoration: none;
        border: 0;
        outline: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        cursor: pointer;
        background: transparent;
        padding: 0;
        margin-right: 0.5rem;
        margin-left: 0;

        >.fa-plus {
          color: #4b515d;
          margin-right: 0.25rem;
          font-size: 12px;
        }
      }
    }
  }

  .bz-article-headline {
    font-size: 1rem;
  }

  .bz-package-details-container-row {
    display: flex;
    flex-wrap: wrap;
  }
}

.bz-packages-transaction-grid.bzc-grid {
  colgroup {
    col {
      width: 210px !important;
    }
  }

  .k-grid-header {
    border: 0;
    border-radius: 0;
    background-color: #dee2e6;

    .k-header {
      background-color: #dee2e6 !important;
      border: 0 !important;
      color: #1f1d1d;
      padding: 0.644rem 0.5rem !important;
      font-weight: 700;

      >.k-link {
        padding: 0;
        margin: 0;
        font-size: 0.8125rem;
      }
    }
  }

  .k-grid-content {
    border: 0 !important;
    overflow-y: auto;

    td {
      padding: 0.7rem 0.5rem;
      line-height: 1rem;
      font-size: 0.65rem;
      border: 0;
    }
  }
}

//toggle button
.bz-toggle-btn {
  display: flex;
  align-items: center;

  .k-switch {
    width: auto;
    line-height: 1;

    .k-switch-container {
      width: 35px;
      display: inline-flex;

      .k-switch-handle {
        width: 12px;
        height: 12px;
        vertical-align: middle;
      }
    }
  }

  .k-switch-on .k-switch-handle {
    left: calc(100% - 12px) !important;
  }

  .switch-label {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

.bz-packageSelect-card {
  margin-bottom: 0.25rem;

  >h6 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
  }

  >.pack-details {
    p {
      line-height: 1.2;
    }
  }
}

.selected-package {
  color: #008db9;

  div.pack-details>p {
    color: #008db9;
  }
}


.bz-purchase-checkout-list.bz-multiplepackage-select {
  .bz-row {
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    >.select-column {
      width: 16rem;
    }
  }

  .k-multiselect.bz-multiSelect {
    .k-multiselect-wrap {
      max-height: 35px;

      li.k-button {
        margin: 0.25rem 0 0 0.25rem;
        min-width: auto;
        background-color: #008db9;
        color: #fff;
      }
    }
  }

  .bz-toggle-btn {
    min-height: 30px;
  }
}

.bz-selectClient-dialog {
  .k-dialog-content {
    .k-radio-label {
      font-size: 0.875rem !important;
    }
  }
}

.bz-packages-btn-container {
  .k-dropdown-button.bz-btn-type2>.k-button {
    margin: 0;
    margin-bottom: 0.25rem;
  }
}

//pipe drive css starts
.bz-pipedrive-container {
  .k-primary {
    .btnNotloading {
      display: none;
    }
  }
}

.bz-purchaseClassSessionSuccess {
  .bz-successmessage-row {
    >h6 {
      display: flex;
      margin-bottom: 0.75rem;

      .bz-colon {
        width: 1.5rem;
      }
    }
  }
}

//paynow page css
.bz-paynow-page {
  .card {
    .bz-cancellation-card-row {
      justify-content: space-between;
      align-items: flex-start;
      grid-template-columns: 240px 82px 169px 131px 315px;
      gap: 0;

      >.column-1 {
        display: flex;
        align-items: center;
      }

      >[class|="column"]:not(.column-4) {
        padding-right: 1.5rem;
      }

      >.column-3 {
        >.paynow-redeem-switch {
          >.complimentary {
            padding-right: 1.5rem;
          }
        }
      }

      >.column-4 {
        .add-new-btn .k-button {
          margin-right: 0 !important;
        }
      }

      .k-dropdown {
        width: 14.5rem;
      }

      .bzc-time-sec {
        display: flex;
      }
    }

  }
}

.bz-paynow-page.inc-taxes {
  .card {
    .bz-cancellation-card-row {
      grid-template-columns: 200px 82px 82px 82px 169px 131px 330px
    }
  }
}

.bz-paynow-page.redeem-by-pakage {
  .card {
    .bz-cancellation-card-row {
      grid-template-columns: 240px 82px 169px 131px 208px;
    }
  }
}

.bz-paynow-page.redeem-by-complementary {
  .card {
    .bz-cancellation-card-row {
      grid-template-columns: 240px 82px 169px 131px;
    }
  }
}

.bz-paynow-page.no-redeem-by-complementary {
  .card {
    .bz-cancellation-card-row {
      grid-template-columns: 240px 82px 169px 310px;
    }
  }
}

.k-dropdown-width-100 {
  width: 100%;
}

//new css for cancellation page
.bz-cancellation-page-new {
  .k-listview {
    .bz-cancellation-card-row {
      grid-template-columns: 240px 82px 112px 210px;
      justify-content: space-between;
      align-items: flex-start;
    }

    .bz-cancellation-card-row.bz-charge-amount {
      grid-template-columns: repeat(3, 1fr);
    }

    .bz-cancellation-card-row.charge-dropdown {
      grid-template-columns: 240px 82px 112px 235px 162px;
      justify-content: space-between;
      align-items: flex-start;

      >[class|="column"]:not(.column-6) {
        padding-right: 1rem;
      }

      .column-6,
      .column-5 {
        padding-right: 0;

        .k-textbox {
          margin-top: 0.2rem;
        }
      }
    }

    .bz-cancellation-card-row.charge-dropdown.refund-amount {
      grid-template-columns: 240px 82px 112px 160px 130px;

      >.column-4 {
        grid-area: 2/1;
      }

      >.column-6 {
        grid-area: 2/2/2/4;
      }
    }
  }
}

//new css for new designs
.bz-classList-container.k-card>.k-card-body {
  .k-listview {
    .bz-groupServices-list-new {
      box-shadow: 0px 3px 8px #00000029 !important;
      border: 1px solid #9f9f9f !important;
      border-radius: 7px !important;
      opacity: 1;
      display: grid;
      grid-template-columns: 2fr 2fr 3fr 1fr auto auto auto;
      align-items: center;
      justify-content: center;
      max-height: 120px;
      background-color: #fff !important;
      padding: .8rem .5rem !important;

      .section-1 {
        max-width: 250px;
        min-width: 250px;

        .section-1-items {
          display: flex;
          flex-direction: column;


        }
      }
    }
  }
}

.bz-event-type {

  min-width: 100px;
  background-color: #f1f5fc !important;
  color: #008db9;
  font-weight: 600;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 5px 10px 5px 5px;
}

.bz-service-name {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #262727 !important;
  opacity: 1;
  margin-bottom: 0;
  display: flex;

  >span+div {
    display: inline-block;

    >span {
      margin-left: 0.15rem !important;
    }
  }
}

.bz-total-sessions {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #008db9;
  opacity: 1;
}

.bz-total-sessions.link-hover {
  &:hover {
    text-decoration: underline;
  }
}

.bz-main-provider {
  display: flex;
  align-items: center;

  >.bz-provider-img {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  >.bz-provider-name {
    padding-left: 10px;

    >h6 {
      font-size: 14px !important;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8f9bb3 !important;
      display: block;
      margin-bottom: 0;
    }

    .bz-provider {
      letter-spacing: 0px;
      color: #272727;
      font-size: 14px;
      font-weight: 600;
      word-break: break-all;
    }
  }

  .bz-calender-icon {
    img {
      width: 25px;
    }
  }
}

.bz-time-section {
  display: flex;
  align-items: center;

  .bz-availability-dates {
    text-transform: capitalize;
    padding-left: 21.25px;

    >h6 {
      color: #393939;
      font-weight: normal;
      letter-spacing: 0px;
      font-size: 0.875rem;
    }
  }

  .bz-availability-time {
    color: #262727 !important;
    font-weight: 500;
    letter-spacing: 0px;
    font-size: 0.78rem;
    opacity: 0.6;
  }

  .bz-calender-icon {
    img {
      width: 28px;
      height: 28px;
    }
  }

  h6 {
    color: #393939;
    font-weight: normal;
    letter-spacing: 0px;
    font-size: 0.875rem;
    margin-bottom: 0 !important;
    padding-left: 10px;
  }
}

.green-txt {
  font-size: 0.78rem;
  font-weight: 500;
  border: 0 !important;
  padding: 0;
}

.bz-price-section {
  >h6 {
    letter-spacing: 0px;
    color: #464646;
    opacity: 1;
    font-size: 39px;
    font-weight: bold;
  }

  .bz-status {
    display: block;
    min-width: 80px;
    max-width: 80px;
    height: 20px;
    border: 1px solid #28a745;
    border-radius: 3px;
    letter-spacing: 0px;
    color: #fff;
    font-size: 12px;
    background: #28a745;
    text-align: center !important;
    font-weight: 600;
  }

  .bz-draft {
    display: block;
    min-width: 80px;
    max-width: 80px;
    height: 20px;
    border: 1px solid #FFDAB9;
    background: #FFD580;
    border-radius: 3px;
    letter-spacing: 0px;
    color: #636363;
    font-size: 12px;
    text-align: center !important;
    font-weight: 600;
  }

  .bz-status-cancel {
    display: block;
    min-width: 80px;
    max-width: 80px;
    height: 20px;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    letter-spacing: 0px;
    color: #6C757D;
    font-size: 12px;
    text-align: center !important;
    background-color: #D3D3D3;
  }
}

.bz-classes-action {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .bz-classes-search {
    margin: 0 0.5rem;
    position: relative;

    >.form-control {
      min-width: 15rem;
      font-size: 14px !important;
      height: 34px;
      padding-left: 2rem;

      &:focus {
        box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
        -webkit-box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
        -moz-box-shadow: -3px 1px 10px -4px rgba(0, 0, 0, 0.78);
        border-color: #b5b5b5;
      }
    }

    .fa-search {
      position: absolute;
      top: 0.6rem;
      left: 0.6rem;
      color: $mutedTextColor;
    }
  }
}

//new css for new designs
.bz-classList-container.k-card>.k-card-body {
  .k-listview {
    .bz-groupServices-sessions-list-new {
      box-shadow: 0px 3px 8px #00000029 !important;
      border: 1px solid #9f9f9f !important;
      border-radius: 7px !important;
      opacity: 1;
      display: grid;
      grid-template-columns: 2fr 2fr 3fr 1fr 6rem 6rem;
      align-items: center;
      justify-content: center;
      min-height: 160px;
      background-color: #fff !important;
    }
  }
}

.bz-classList-container.k-card>.k-card-body {
  .k-listview {
    .bz-packages-list-new {
      box-shadow: 0px 3px 8px #00000029 !important;
      border: 1px solid #9f9f9f !important;
      border-radius: 7px !important;
      opacity: 1;
      display: grid;
      grid-template-columns: 0.6fr 0.6fr 0.6fr 1fr 0.3fr 0.15fr;

      gap: 10px 0;
      align-items: center;
      justify-content: center;
      min-height: 84px;
      background-color: #fff !important;
      padding: 1.2rem 0.5rem !important;

      .section-1 {
        .section-1-items {
          display: flex;
          flex-direction: column;

          .bz-event-type {
            position: absolute;
            top: 0;
            min-width: 100px;
            text-align: center;
            background-color: #008db9;
            color: #fff;
            border-radius: 0 0 5px 5px;
            font-weight: 500;
            font-size: 0.78rem;
          }

          .bz-event-type.active {
            width: auto;
            background-color: #008db9 !important;
          }
        }
      }

      .section-7 {
        display: flex;
        gap: 0 10px;
        justify-content: flex-end;
      }

      .section-9 {
        grid-area: 2/1/2/9;
      }

      .section-1 {
        h6.bz-service-name {
          display: flex;
          align-items: start;
        }
      }

      .bz-provider-name {
        h1 {
          font-size: 1.25rem !important;
          margin-bottom: 0 !important;
        }

        h1.price {
          font-size: 1rem !important;
        }
      }
    }

    .bz-groupServices-list-new {
      .section-1 {
        .section-1-items {
          display: flex;
          flex-direction: column;

          .bz-event-type {
            position: absolute;
            top: 0;
            min-width: 100px;
            text-align: center;
            background-color: #008db9 !important;
            color: #fff;
            border-radius: 0 0 5px 5px;
            font-weight: 500;
            font-size: 0.78rem;
            width: auto;
          }

          .bz-event-type.active {
            width: auto;
            background-color: #008db9 !important;
          }
        }
      }
    }

  }
}

.bz-packages-tags-new {
  margin-top: 0rem;

  .k-chip-list {
    margin-top: 6px !important;
  }

  .k-chip {
    display: inline-flex;
    padding: 0.15em 0.25em !important;
    font-size: 0.78rem !important;
    font-weight: 400;
    line-height: 1.5;
    border-color: #393939;
    color: #393939;
    background: #fff !important;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem !important;
    border: 1px solid;
    border-radius: 5px;
    width: auto !important;
    height: auto !important;
    text-align: left;
    text-transform: capitalize;
    margin: 0.25rem 0.25rem 0.25rem 0;

    >.k-chip-content {
      text-align: left;
      font-size: 0.875rem;
      font-weight: 400;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  .k-chip-focused {
    box-shadow: none !important;
  }

  .bz-cursor-pointer {
    padding: 10px !important;
  }

  .btn {
    color: #393939 !important;
    text-decoration: underline;
  }
}

.bzc-edit-session-options-row-new {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  justify-content: center;
  gap: 1rem 1rem;

  >.column-2 {
    .k-checkbox-label {
      font-size: 0.875rem !important;
      font-weight: 500 !important;
      color: black;
    }

    >.k-dropdown {
      width: 15rem;
    }

    >.k-numerictextbox {
      width: 15rem;
    }

    >.k-timepicker {
      width: 15rem;
    }

    >.k-datepicker {
      width: 15rem;
    }

    .bzc-input-group {
      .k-dropdown {
        width: 8.5em;

        >.k-dropdown-wrap {
          border-radius: 0 0.25em 0.25em 0;
        }
      }

      .k-numerictextbox {
        width: 7.6em;
      }

      .k-numerictextbox .k-numeric-wrap {
        border-radius: 0.25em 0 0 0.25em;
      }
    }
  }
}

h4.bzc-edit-session-options-row-header,
h6.bzc-edit-session-options-row-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

h4.bzc-edit-session-options-row-header:after,
h6.bzc-edit-session-options-row-header:after {
  background: #dee0e2;
  height: 1px;
  flex: 1;
  content: "";
}

.bzc-edit-session-options-row-buttons {
  display: flex;
  justify-content: flex-end;
}

.update-session-page-new {
  .bzc-row:nth-child(even) {
    color: #7b7b7b;
    font-size: 0.875rem;
    background-color: $whiteColor;
  }

  .bzc-row:nth-child(odd) {
    color: #7b7b7b;
    font-size: 0.875rem;
  }

  .bzc-row {
    width: 80%;

    label {
      font-weight: 500;
    }
  }

  .selected-color {

    .bz-selected-text {
      font-weight: 600;
    }
  }

  .k-checkbox-label {
    font-size: 0.875rem !important;
    padding-left: 0.2em;
    font-weight: 500 !important;
    color: black;
  }
}

.bzc-update-session-options-row-buttons {
  display: flex;
  justify-content: space-between;
}

.k-card-body .edit-session-body {
  height: 75vh;
  overflow: auto;
}

.edit-class-border-box {
  border: 1px solid lightgray;
  padding: 1.2rem;
  border-radius: 2px;
}

.add-package-form,
.edit-package-form {
  overflow: auto;
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  margin-bottom: 1rem;

  .section-Name {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .section-Description {
    grid-column: 3 / span 4;
    grid-row: 1 / span 4;
  }

  .section-CanClientBookOnline {
    grid-row: 2;
    grid-column: 1;
    align-self: center;
  }

  .section-Type {
    grid-row: 2;
    grid-column: 2;
  }

  .section-SessionType {
    grid-row: 3;
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  .section-hr-line {
    grid-row: 4;
    grid-column: 1 / span 5;
    row-gap: 1rem;
  }

  .section-group-services {
    grid-column: 1 / span 2;
    grid-row: 5;
  }

  .section-Price {
    grid-row: 5;
    grid-column: 3;
  }

  .section-Status {
    grid-row: 5;
    grid-column: 4;
  }

  .section-cancellation {
    grid-column: 1 / span 2;
    grid-row: 6;
  }

  .section-video-url {
    grid-column: 3 / span 4;
    grid-row: 6;
  }

  .section-k-form-buttons {
    grid-column: 4;
    grid-row: 7;
    justify-self: flex-end;
  }

  .upload-file-section {
    grid-column: 1 / span 2;
    grid-row: 7;
  }

  .section-k-form-add-buttons {
    grid-column: 4;
    grid-row: 8;
    justify-self: flex-start;
  }
}

.add-package-form {
  .section-CanClientBookOnline {
    margin-top: 2.61rem;
  }

  .section-Status {
    .k-radio-list {
      padding-top: 7px;
    }
  }
}

// header css
body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
}

.public-layout {
  .k-appbar {
    background: #fdfefe;
    color: black;
    padding: 0.25rem 2rem;
    min-height: 4rem;
    max-height: 4rem;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    z-index: 1030;

    .k-menu {
      padding: 0rem;
      background: #008db9 !important;

      .k-link.k-menu-link {
        color: white !important;
      }

      .active {
        border-bottom: 2px solid #fff;
      }

      .inactive {
        border-bottom: none;
      }
    }

    .k-menu:not(.k-context-menu)>.k-item:focus,
    .k-menu:not(.k-context-menu)>.k-item.k-state-focused {
      box-shadow: none;
    }
  }

  .bz-navBar {
    .k-menu-link {
      padding: 0.5rem 1rem;
    }

    .bz-verticalLine {
      padding: 0 1rem;
    }
  }
}

.actions {
  cursor: pointer;
  pointer-events: all;
}

.logo-container {

  max-width: 9rem;

  >img {
    width: auto;
    height: auto;
    max-height: 3.5rem;
    max-width: 100%;
  }
}

.provider-img>img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 3px solid #60a6c6;
}

.bz-tenet-name {
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 0.5rem 1.2rem;
  font-weight: 600;
  margin-left: 0;
}

.bzo-contact-navbar {

  .fa-phone-volume {
    transform: rotate(-45deg);
    font-size: 1.3rem;
  }

  >span {
    margin-left: 0.75rem;
  }
}

.plus-order {
  .k-button.k-outline {
    color: $whiteColor;
  }

  .k-button-outline:hover,
  .k-button.k-outline:hover,
  .k-button-outline.k-state-hover,
  .k-state-hover.k-button.k-outline {
    border-color: $whiteColor;
    color: $whiteColor;
    background-color: #008db9;
  }
}


.logo-menu {
  .k-drawer-item.k-state-selected {
    color: $whiteColor;
    background-color: inherit;
  }

  .k-drawer-item {
    justify-content: center;
  }

  .k-drawer-item {
    span.provider-text {
      margin: 0px auto;
    }
  }
}

.bz-side-menu {
  .k-drawer-items {
    >.logo-menu {
      &:first-child {
        .k-drawer-item {
          padding-bottom: 0;
          margin-top: 1rem;
        }
      }

      &:last-of-type {
        margin-bottom: 2rem;
      }
    }

    >.bzo-menu-items.bzo-logout {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      border-top: 0.1rem solid #aacdd9;
    }

    >.bzo-menu-items {
      &:first-child {
        margin-top: 1.5rem;
      }
    }
  }
}

@media (max-width: 479px) {}



.access-control-layout {
  .k-appbar {
    background: #008db9;
    color: white;
    padding: 0.25rem 3rem;
    min-height: 4rem;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .bz-navBar {
    .k-menu-link {
      padding: 0.5rem 1rem;
    }

    .bz-verticalLine {
      padding: 0 1rem;
    }
  }

  .ba-tag-name {
    font-size: 0.5rem;
    display: block;
    text-align: right;
  }
}

.k-animation-container-relative {
  display: block;
}

.package-redeem-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .reedem-summary {
    border-right: 1px solid lightgray;

    h6 {
      padding-right: 1rem;
    }
  }

  .package-item {
    .package-details {
      padding: 0.5rem 1rem;
      margin: 5px 0;
      display: flex;
      flex-direction: column;

      .package-expiry-schedules {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 0.875rem;
      }

      .package-name {
        h5 {
          font-size: 0.875rem;
          margin: 0;
          font-weight: 500;
        }
      }
    }
  }
}

.bz-checkout {
  &-inhouse-class {
    .order-summary-form {
      position: sticky;
      top: 0;
      max-height: calc(100vh - 6rem);
      overflow: auto;
    }
  }

  &-inhouse-package {
    height: 80vh;
    overflow: auto;
  }
}

.add-class-form {
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;

  .section-Name {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .section-Description {
    grid-row: 1 / 3;
    grid-column: 3 / 5;
  }

  .section-specificDays {
    grid-row: 6;
    grid-column: 1 / 3;
  }

  .section-hr-line {
    grid-row: 4;
    grid-column: 1 / span 4;
    row-gap: 1rem;
  }

  .section-EventType {
    align-self: center;
  }

  .k-radio-item {
    align-items: flex-start;
  }
}

.addClassForm-grid2 {
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;

  .section-uploadFile {
    grid-row: 3;
    grid-column: 1 / 3;
  }
}

.addSessionForm-grid2 {
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
}

.add-Session-Form {
  display: grid;
  gap: 0.5rem 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;

  .section-specificDays {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  .section-VirtualMeeting {
    align-self: flex-end;
  }
}

//new Mybookings
.success-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);

  .success-info {
    width: 48%;
    max-width: 38rem;
    overflow: auto;
    background-color: $whiteColor;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &.display-in-calendar {
      width: 60%;
    }

    .card-content {
      display: flex;
      font-size: 1rem;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 3.5rem;

      h4 {
        word-break: break-word;
      }

      .bzo-appoinmentBooked-grid {
        font-size: 1rem;
        align-self: center;
        max-height: 175px !important;
        border: none;

        .k-grid-header {
          border-color: #7b7b7b;
          color: #fff;
          background-color: #7b7b7b;

          th {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .k-grid-container>.k-grid-content {
          tr:hover {
            background-color: #fff;
          }

          .k-grid-table {
            width: 100%;
            border: none;

            td {
              text-align: center;
              font-size: 12px;

              &:first-child {
                border-left-width: 1px;
              }
            }

            tr {
              &:last-of-type {
                >td {
                  border-bottom-width: 1px;
                }
              }
            }
          }
        }

      }
    }

    .location-icon-blk {
      display: flex;
      width: 1rem;
      align-items: center;
      justify-content: center;

      >div {
        display: inline-flex;

        >span {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        svg {
          font-size: 1.1rem;
        }
      }
    }

    .location-phone {
      >.location-icon-blk {
        margin-top: 0.2rem;
      }
    }
  }
}

.Booking-Main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  height: calc(100vh - 114px);

  .first-panel {
    width: 25%;
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  .second-panel {
    width: 75%;
  }

  .one-panel {
    width: 25%;
  }

  .two-panel {
    width: 55%;
  }

  .three-panel {
    width: 20%;
  }

  .second-panel.hide-available-times {
    width: 24.5rem;
  }

  .second-panel.client-page {
    width: 35%;
  }

  .first-panel,
  .second-panel,
  .one-panel,
  .two-panel,
  .three-panel {
    background-color: $whiteColor;
    border-radius: 2px;
  }

  .second-panel {
    margin-left: 12px;
  }

  .three-panel {
    max-height: 83vh;
    min-height: 83vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .selected-slot-header {
      display: flex;
      justify-content: space-between;
      padding-right: 0.75rem;

      span[role="button"] {
        cursor: pointer;
      }
    }

    .selected-slot-footer {
      justify-self: flex-end;
      margin-top: auto;
      display: flex;
      justify-content: flex-end;

      >.k-button {
        min-width: 8rem;
        padding: 0.6rem 1rem !important;
      }
    }

    .bz-selected-appoinment-container {
      height: calc(100vh - 10rem);
      overflow: auto;
      padding-right: 0.35rem;
    }
  }

  .first-panel,
  .one-panel {
    max-height: 83vh;
    min-height: 83vh;
    display: flex;
    padding: 0px;
    flex-direction: column;

    .firstPanelContent {
      padding: 1.25rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .provider-items {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        font-size: 1rem;
        font-weight: 600;

        h6 {
          margin-bottom: 0;
          line-height: 1.5;
        }

        .location-blk-text {
          >.location-icon-blk {
            span.align-baseline {
              margin-left: 0.1rem !important;
              margin-right: 0rem !important;

              svg {
                color: #84898c !important;
              }
            }
          }
        }
      }
    }

    .upcoming-appts-expand-card {
      .card-header {
        display: flex;
        font-weight: 600;
        font-size: 1rem;
        color: $primaryColor;
        justify-content: space-between;

        span>.svg-inline--fa {
          font-size: 1.5rem;
          color: $primaryColor;
          fill: $primaryColor;
        }
      }

      .k-listview {
        height: calc(100vh - 15.5rem);

        .k-listview-content {
          padding-right: 0.3rem;
        }
      }
    }

    .upcoming-appts-collapse-card {
      padding: 0.75rem;
      justify-self: flex-end;
      margin-top: auto;
      display: flex;
      background-color: #7b7b7b;
      color: $whiteColor;
      font-weight: 600;
      font-size: 1rem;
      justify-content: space-between;

      .svg-inline--fa {
        font-size: 1.5rem;
        color: $whiteColor;
        fill: $whiteColor;
      }
    }
  }

  .appt-submit-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }

  .recurrence-selection {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: 0.35rem;
    margin-top: 1rem;
    max-width: 24rem;

    .bz-recurence-dropdown {
      width: 100%;
      margin-bottom: 1rem;

      .k-dropdown {
        max-height: 35px;
        cursor: pointer;

        &:focus-within,
        &:focus {
          box-shadow: none;
          border-color: #e4e7eb;
        }

        .k-input {
          line-height: 1;
        }
      }
    }

    .form-group {
      width: calc(100% - 108px);

      .k-numerictextbox {
        max-height: 35px;
        width: 100%;
        cursor: pointer;

        &:focus-within,
        &:focus {
          box-shadow: none;
          border-color: #e4e7eb;
        }

        .k-input {
          height: auto;
          padding: 0.35rem 0.75rem;

          &:focus-within {
            box-shadow: none;
          }
        }
      }
    }

    .bz-recurence-btn {
      align-self: center;
      margin-bottom: 0.68rem;

      .k-button {
        min-height: 35px;
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }

  .recurrence-selection.bz-custom-option {
    .form-group {
      width: calc(100% - 150px);
    }
  }

  .two-panel {
    .calendar-main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .calendar-item {
        width: 65%;
      }

      .availableTimes-Item {
        width: 200px;
        align-self: center;
        font-weight: 600;

        .render-time-duration {
          max-height: 410px;
          overflow: auto;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          column-gap: 0.75rem;
          padding-right: 0.35rem;
        }
      }
    }
  }

  .second-panel {
    .calendar-main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .calendar-item {
        width: 100%;
      }

      .availableTimes-Item {
        width: 100%;
        align-self: flex-start;
        font-weight: 600;

        .render-time-duration {
          max-height: 410px;
          overflow: auto;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 0.75rem;
          padding-right: 0.35rem;

          .selected-slot-single {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 0;

            .time-button {
              width: 100%;
              background-color: rgba(0, 0, 0, 0.6) !important;
              color: $whiteColor;
              margin-right: 0.4rem;
            }

            .confirm-button {
              width: 100%;
              background-color: $primaryColor !important;
              color: $whiteColor;
              margin-left: 0.4rem;
            }
          }
        }

        .render-time-duration.prospect-user {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .client-creation-page {
      max-width: 400px;

      .k-form .k-textbox,
      .k-form .k-maskedtextbox {
        height: 36px;
      }

      .k-form .k-maskedtextbox {
        >.k-textbox {
          border: 0;
        }
      }

      .k-form-fieldset.form-row {
        width: 100%;

        .form-group {
          >.k-form-field {
            margin-top: 0;
          }
        }
      }

      .k-form-buttons {
        margin-top: 0.875rem;
      }
    }
  }

  .selected-Items-booking {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .selected-provider-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .selected-service-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .bzo-status-success {
      font-size: 0.75rem;
      padding: 0px 5px;
      margin: 4px;
    }

    .bzo-status-Complimentary {
      font-size: 0.75rem;
      padding: 0px 5px;
      margin: 4px;
    }

    .bzo-status-consultation {
      font-size: 0.75rem;
      padding: 0px 5px;
      margin: 4px;
    }

  }

  .second-panel,
  .two-panel {
    .calendar-item {
      .calendar-section-heading {
        padding-left: 0.75rem;
        padding-top: 1.25rem;
      }
    }
  }

  .second-panel.bz-availableTimesCard,
  .two-panel {
    width: 50%;
    gap: 0;

    >.calendar-main {
      >.calendar-item {
        max-width: 23rem;
      }

      >.availableTimes-Item {
        width: calc(100% - 24.5rem);
        margin-left: 2.5rem;
        align-self: self-start;
        margin-bottom: 1rem;

        .render-time-duration {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    >.calendar-main.bz-guest-calendar-main {
      >.availableTimes-Item {
        width: 100%;
        margin-left: 1.2rem;
        align-self: self-start;
        margin-top: 0;
      }
    }
  }

  .one-panel {
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  .two-panel {
    margin-left: 2.5rem;
  }

  .three-panel {
    margin-left: 2.5rem;
    width: 18rem;
  }

  .two-panel.bz-thirdPanelActive {
    >.recurrence-selection {
      margin-left: 0.35rem;
      max-width: 22.3rem;
    }
  }

  .css-26l3qy-menu {
    top: unset;
    bottom: 2rem;
  }
}

.Booking-Main.prospect-user-main {
  .second-panel.bz-availableTimesCard {
    width: 70%;

    >.calendar-main {
      align-items: flex-start;

      .render-time-duration.prospect-user {
        padding-right: 0.5rem;
      }

      .availableTimes-Item {
        width: 15rem;
        margin-top: 4.1rem !important;

        .render-time-duration.prospect-user {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .client-creation-page h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.toggle-buttons-class {
  background-color: lightgray;
  padding: 3px;
  border-radius: 5px;

  button {
    border: none;
    padding: 2px;
    width: 150px;
    border-radius: 6px;
    font-size: 0.78rem !important;
    font-weight: 600;
    padding: 6px;
  }

  button.active {
    background-color: $primaryColor;
    color: white;
  }

  button.inactive {
    background-color: transparent;
  }

  button.inactive:hover {
    background-color: $primaryColor;
    color: white;
  }
}

button.k-button.add-session-btn:hover {
  background-color: $primaryColor;
  color: $whiteColor;
}

.purchase-btn-appts {
  display: flex;
  justify-content: flex-end;
}

.plan-details .k-dropdown {
  padding: 0 !important;
  width: 100%;
}

.plan-details .k-datepicker {
  width: 100%;
  height: 35px;
  padding: 0;
}

.plan-details .k-dateinput-wrap {
  align-items: center;
}

.plan-details .card {
  gap: 10px 0;
  margin-bottom: 10px;

  .card-body {
    padding: 0.25rem 1.25rem !important;
  }
}

.plan-details {
  .checkout-panelbar-wrapper {
    .col-12 {
      display: flex;
      align-items: center;
    }
  }
}

.add-payment {
  display: flex;
  align-items: center;

  label {
    width: 100%;
    margin: 0;
  }
}

.payment-txt {
  color: #393939;
}

.customtooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  /* If you want dots under the hoverable text */
  .tooltiptext {
    visibility: hidden;
    background-color: #717171;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 3px 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.customtooltip:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 991px) {
  .bz-appoinments-overview-card {
    margin-top: 0 !important;
  }

  .mobile-wd-100 {
    width: 100% !important;
  }

  .select_clientdrop.mobile-wd-100 {
    width: 100% !important;
  }
}

.bz-myprofile-container {
  input {
    width: 100% !important;
  }

  .k-maskedtextbox {
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;
  }

  .k-dropdown {
    width: 100% !important;
    padding: 0 !important;
  }

  .bz-content-text-row .bz-content-body {
    width: auto;
  }
}

.Location-PhoneNumber {
  .k-maskedtextbox.PhoneNumber {
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;

    .k-textbox {
      width: 100%;
    }
  }
}

.update-recurrence {
  input {
    width: 100% !important;
  }

  .k-datepicker {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-dropdown {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-numerictextbox {
    width: 100% !important;
    padding: 0 !important;
  }
}

.one-on-one .card-body {
  min-height: 127px;
}

.group-serv-list {
  .section-5 {
    display: flex;
    align-items: center;

    .view-icon {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-top: 0.1rem;
    }
  }
}

@media (max-width: 600px) {
  .k-tabstrip {
    width: 100% !important;
  }

  .bz-checkout-service {
    .serviceDetailsPanel-grid {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }

    .serviceDetailsPanel-flex {
      gap: 5px;
    }
  }

  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .group-serv-list {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        padding-top: 35px !important;

        .bz-provider {
          padding-left: 10px !important;
        }

        .bz-availability-dates {
          padding-left: 10px !important;
        }
      }
    }
  }

  .bz-migration-filter-container {
    margin-bottom: 0.5rem !important;

    >.bz-leftSide-filter {
      margin-bottom: 0.5rem !important;

      .badge-filter {
        flex-wrap: wrap;
        align-items: flex-start !important;

        .k-badge-container {
          margin-bottom: 0.5rem;
        }
      }
    }

    >.bz-rightSide-filter {
      flex-wrap: wrap;
      align-items: start !important;
      justify-content: flex-start !important;

      >.search-filter {
        width: 100% !important;
        margin-bottom: 0.5rem;
        margin-right: 0 !important;
      }

      >.multiselect-filter {
        width: calc(100% - 2.75rem) !important;
      }
    }
  }

}

.k-window-title {
  font-size: 1rem !important;
  font-weight: 600;
}

.buz-selected-button {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #008db9 !important;
  letter-spacing: 1.73px !important;
  padding: 0.25rem 0.3rem 0.25rem 0.5rem !important;
}
.buz-selected-button:hover{
  border-color: #08c4ff  !important;
 }

 
.buz-select-button {
  background-color: #008db9 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  letter-spacing: 1.73px;
  padding: 0.25rem 1.8rem !important;
  border-radius: 0.4rem !important;
}

.buz-select-button:focus {
  outline: none !important;
}

.bz-waitlist-btn {

  .buz-select-button,
  .buz-selected-button {
    color: #C7997C !important;
    background-color: #FEF5F0 !important;
    border: 1px solid #C7997C !important;
    font-weight: 600 !important;
  }

  .buz-select-button {
    padding: 0.25rem 1.4rem !important;
  }

  .buz-selected-button {
    padding: 0.322rem 0.5rem !important;

    .svg-inline--fa {
      color: #C7997C !important;
      margin-right: 0;
    }
  }
}

.k-button {
  font-size: 0.78rem !important;
  padding: 0.4469rem 0.5rem !important;
  text-align: center !important;
}

li.k-button {
  padding: 2px 6px !important;
}

.planleftCol {
  width: 70%;
}

.planrightCol {
  width: 30%;
  text-align: right !important;
}

.book-appt-btns {
  padding: 10px 0;
  float: right;
  margin: 0;
  display: flex !important;
}

.disable-renewal-btns {
  display: flex;
  align-items: center;
}

.back-arrow-btn {
  min-width: auto !important;
  padding: 0.3rem 0.5rem;
}

.select-payment-profile-text {
  margin-bottom: 10px;
}

.agree-copy a {
  .buzops_link {
    cursor: pointer !important;
  }

  .disable_link {
    cursor: not-allowed;
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
}

.agree-copy a:hover {
  .buzops_link {
    color: #007bff !important;
  }

  .disable_link {
    cursor: not-allowed;
    color: currentColor;
    opacity: 0.5;
    text-decoration: none;
  }
}

.plan-name-field .k-dropdown {
  height: 35px !important;

  h6 {
    font-size: 0.78rem !important;
    font-weight: 600;
  }
}

.another-payment .Add-credit-card {
  padding-left: 0.75rem;
}

.another-payment .Add-bank-account {
  padding-left: 0.75rem;
}

.flex-txt {
  display: grid;
  grid-template-columns: 1.4fr auto 1fr;
  align-items: center;
}

.flex-txt .title-column {
  width: 15px;
  text-align: center;
}

.purchased-dots {
  float: right;
  padding-right: 15px;
}

.audit-flex {
  display: flex !important;
  align-items: flex-start;
  gap: 8px;
}

.audit-flex .fa-calendar-alt {
  color: #fdac41;
  font-size: 0.9rem;
  margin-top: 5px;
}

.coupon-info {
  align-items: flex-start !important;
}

.coupon-info .section-check-mark {
  margin-top: 0px;
  display: flex;
  margin-right: 0.35rem;
}

.coupon-info .section-coupon-info {
  text-align: left !important;
}

.coupon-info .section-coupon-info h6 {
  margin: 0 !important;
}

.serviceDetailsPanel-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.serviceDetailsPanel-grid {
  h3 {
    font-size: 1.3rem !important;
  }

  >.section-1 {
    >h5 {
      display: flex;
      align-items: center;

      .location-icon {
        font-size: 1.5rem;
      }
    }
  }
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.payement-panel-header {
  .k-panelbar-item-text {
    font-weight: 600 !important;
    text-transform: capitalize;
  }
}

.overview-grid {
  align-items: flex-start;

  .bz-payment-options {
    display: grid;
    align-items: center;
    grid-template-columns: 8rem 6rem 4.2rem 9rem auto;
    justify-content: flex-start;
    grid-gap: 0.5rem;

    >.subscription-name {
      display: inline-block;
      min-width: 9rem;
    }

    >.bz-card-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.payment-profile {
  .addNewBtn {
    margin-left: 0.5rem;
  }

  .add-new-btn {
    .k-button {
      min-width: 4.7rem !important;
      margin: 0;
    }
  }
}

@media (max-width: 1460px) {
  .overview-grid {
    .bz-payment-options {
      display: flex;
      flex-wrap: wrap;

      .subscription-name {
        width: 100% !important;
      }

      .bz-card-details {
        width: auto !important;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .overview-grid {
    .bz-payment-options {
      .subscription-name {
        width: 100% !important;
      }

      .bz-card-details {
        width: auto !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .overview-grid {
    .bz-payment-options {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-template-columns: unset;

      .subscription-name {
        width: 24%;
      }

      .bz-card-details {
        width: 18%;
      }
    }
  }
}

h6.service-name {
  .bz-cursor-pointer {
    display: none !important;
  }
}

.padd-0 .p-2 {
  padding: 0 !important;
}

.pt-1-35 {
  padding-top: 0.35rem !important;
}

.coupon-info .bz-coupon-desc {
  font-size: 0.75rem !important;
  margin-left: 23px;
}

.plan-summary-card-row {
  display: flex;
  justify-content: space-between;
}

.order-summary-form {
  .k-form-error {
    text-align: left !important;
  }
}

.manage-declines {
  box-shadow: 0px 3px 8px #00000029 !important;
  border: 1px solid #9f9f9f !important;
  border-radius: 7px !important;
  margin: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;

  &-sections {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
  }
}

.add-new-btn.height35 .k-button {
  height: 35px;
}

.bz-manage-declines .k-column-title,
.bz-manage-declines td,
.summardeclinedpayments .k-column-title,
.summardeclinedpayments td {
  font-size: 14px;
}

.bz-summary-block {
  color: $primaryColor;
  padding-top: 4px;
}

.value-render-dropdown {
  font-size: 0.75rem;
}

.service-titlename2 {
  display: inline-block;
  width: 200px !important;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  padding: 0px;
}

.password_eye_icon {
  position: absolute;
  top: 11px;
  right: 7px;
}

.addpromotionsaffilates {
  .password_eye_icon {
    top: 8px;
  }
}

.group-class-tabs {
  display: inline-flex;
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
}

.group-event-flex {
  flex-wrap: wrap;
  gap: 10px 0;
}

.group-event-flex a {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  color: #5a5c5f !important;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 7px !important;
  padding: 0.34rem 0.54rem;
  max-height: 34px;
  min-width: 34px;
}

.group-event-flex a:hover {
  background-color: #dee2e6;
}

.group-event-flex .k-dropdown-button.bz-btn-type2 .svg-inline--fa {
  color: #fff !important;
}

.group-event-flex .k-button {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  color: #5a5c5f !important;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 7px !important;
  padding: 0.34rem 0.54rem !important;
  text-transform: capitalize;
  min-height: 34px;
  align-items: center;
}

.bz-packages-btn-container>.bz-no-add-icon {
  margin: 0;
}

.add-group-btns .group-event-flex .k-button {
  min-width: auto !important;
  display: inline-block !important;
}

.group-event-flex .k-form-buttons {
  margin-top: 0 !important;
}

.group-event-flex .k-dropdown-button.bz-btn-type2>.k-button {
  background-color: #008db9 !important;
  color: #fff !important;
  margin-bottom: 0 !important;
  margin: 0 !important;
}

.filters-dropdown-flex {
  display: flex;
  justify-content: space-between;
}

.filters-dropdown {
  margin-bottom: 10px;
  padding: 0 0.75rem;
}

.filters-dropdown .type-select .k-dropdown-button.bz-btn-type2>.k-button {
  background-color: #008db9 !important;
  color: #fff !important;
  font-size: 0.78rem !important;
  font-weight: 500;
}

.filters-dropdown .k-form-buttons {
  margin-top: 0 !important;
}

.filters-dropdown .form-control,
.filters-dropdown .form-control:hover,
.filters-dropdown .form-control:focus {
  font-size: 0.78rem !important;
  border: 1px solid #ced4da !important;
}

.filters-dropdown .form-control option {
  font-size: 0.78rem;
  padding: 5rem 0.5rem !important;
}

.filters-dropdown .form-control option:hover {
  background-color: #e7e7e7 !important;
}

.add-group-btns .k-dropdown-button.bz-btn-type2>.k-button {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  color: #5a5c5f !important;
  padding: 0.35rem 0.54rem !important;
}

.add-group-btns .k-dropdown-button.bz-btn-type2 .svg-inline--fa {
  color: #5a5c5f !important;
}

.bzc-grid.edit-event-tbl .k-grid-header .k-header {
  border-color: #fff !important;
  background-color: #fff !important;
  border-bottom: 3px solid #e7e7e7 !important;
}

.bzc-grid.edit-event-tbl .k-grid-header {
  border-width: 0 !important;
  padding: 0;
}

.bzc-grid.edit-event-tbl .k-grid-content {
  border-width: 0 !important;
  padding: 0;
}

.session-table .k-input,
.session-table .k-dropdown,
.edit-events-blk .k-textbox {
  font-size: 0.78rem !important;
}

.sessionlist-blk .k-tabstrip-items .k-link {
  font-size: 0.78rem !important;
}

.sessionlist-blk .k-form-buttons {
  margin-top: 0 !important;
}

.sessionlist-blk .k-dropdown-button.bz-btn-type2>.k-button,
.bzo-class-window .sessionlist-blk .k-button {
  background-color: #008db9 !important;
  color: #fff;
  border-color: #008db9;
}

.sessionlist-blk .k-dropdown-button.bz-btn-type2 .svg-inline--fa {
  color: #fff !important;
}

.groupclass-session-list {
  border: 1px solid #9f9f9f;
  border-radius: 7px;
  box-shadow: 0 3px 8px #00000029;
  background-color: #fff !important;
  display: grid !important;
  grid-template-columns: 1fr 0.6fr 0.6fr 0.3fr 0.3fr;
  align-items: center !important;

  .bz-price-section>h6 {
    font-size: 1rem !important;
    margin-bottom: 0;
    font-weight: 500;
  }

  .bz-status {
    border: 0 !important;
    min-width: auto !important;
  }

  .enroll-btn {
    padding: 0.2rem 0.45rem !important;
  }
}

.groupclass-session-list.bz-group-card {
  grid-template-columns: 0.9fr 0.7fr 0.9fr 0.4fr auto;
}

.groupclass-session-list.bz-manage-attendees {
  grid-template-columns: 17rem 15rem 10rem 7rem auto;
  justify-content: space-between;
}

.groupclass-session-list .section-5 {
  display: flex;
  gap: 10px;
}

.bzc-grid.edit-event-tbl .k-grid-header .k-header {
  .k-link {
    .k-column-title {
      color: #262727 !important;
      font-size: 0.78rem !important;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.bzc-grid.edit-event-tbl .k-grid-content td {
  color: #262727 !important;
  font-size: 0.78rem !important;
  padding: 0.875rem !important;
}

.bzc-grid.edit-event-tbl tbody tr:nth-child(even) td {
  background-color: #f5f5f5 !important;
}

.bz-schedule-btn {
  margin: 5px 0 !important;
  min-width: 100% !important;
  font-size: 0.78rem !important;
}

.custom-grid-box {
  .bzc-sessions-list-card-new.bz-classes-checkout {
    .bzc-sessions-list-row-new {
      .section-2 {
        padding-left: 15px;
      }
    }

    .bz-main-provider {
      .bz-provider-img {
        img {
          width: 65px !important;
          height: 65px !important;
        }
      }

      h6 {
        font-size: 0.875rem !important;
      }

      .bz-attendees-icon img {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }
}

.k-selection-multiple {
  .k-chip-solid {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.78rem !important;

    .k-chip-content {
      margin: 0 !important;
      text-align: center !important;
    }
  }
}

.myprofiletags .k-selection-multiple .k-chip-solid {
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #008db9 !important;
  color: #fff !important;
  border-color: transparent;
}

.myprofiletags .k-icon.k-icon.k-i-close {
  font-size: 13px;
}

.k-chip-list {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.select-date-tab span {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  margin-right: 10px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  font-size: 0.78rem !important;
  font-weight: 500;
}

.select-date-tab span:hover,
.select-date-tab span:focus,
.select-date-tab span.active {
  background-color: #008db9;
  border-color: #008db9;
  color: #fff;
}

.select-date-tab .nav-tabs {
  border-bottom: 0 !important;
  gap: 10px;
}

.select-date-tab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: #fff !important;
  border: 1px solid #e7e7e7 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  display: inline-block !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.78rem !important;
}

.select-date-tab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #008db9 !important;
  border-color: #008db9 !important;
  color: #fff !important;
}

.complimentary-btns {
  display: contents;
}

.complimentary-btns .bz-schedule-btn-container {
  display: flex;
  gap: 2px;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;

  .k-button {
    min-width: auto !important;
    background-color: transparent !important;
    border: 0;
    color: #008db9;
    margin: 0 !important;
    padding: 0.25rem 0 !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bz-schedule-btn-containet {
  .bz-schedule-btn {
    min-width: auto !important;
    gap: 0;

    .svg-inline--fa {
      margin-right: 0.3rem;
    }
  }

  .bz-schedule-btn.purchase-btn {
    min-width: 5rem !important;
  }
}

.subscription-blk .bz-content-text-row .bz-content-body {
  width: auto !important;
}

.appointment-date {
  display: block !important;
}

.fs-78 {
  font-size: 0.78rem !important;
}

.addon-dropdown {
  position: relative;

  .k-multiselect-wrap {
    padding-right: 3.5rem;

    .k-clear-value {
      right: 2rem;
    }
  }
}

.arrow {
  position: absolute !important;
  right: 25px !important;
  top: 14px !important;
  display: block;
  float: right;
  margin-top: 0.25em;
  border-color: transparent;
  border-top-color: #000;
  border-style: solid;
  border-width: 0.4em 0.25em 0;
  width: 0;
  height: 0;
  position: relative;
  line-height: 0;
}

.bz-overview-page-row {
  flex-wrap: nowrap !important;
}

.bz-subsection-row.bz-overview-page-row>.right-section {
  flex-basis: 35% !important;
  -webkit-flex-basis: 35% !important;
}

.k-tabstrip-items-wrapper {

  text-align: center;
}

.k-tabstrip-items {
  display: block !important;
}

.k-tabstrip-items .k-item {
  border: 0;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
  color: #262727 !important;
  cursor: pointer !important;
  display: inline-block !important;
  padding: 0.35rem 0.8rem !important;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper {
  border-bottom-width: 0px !important;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-state-active {
  border-bottom: 5px solid #008db9;
  margin-bottom: -3px;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper {
  border-bottom: 0;

  >.k-tabstrip-items {
    border-bottom: 0;
  }
}

.k-tabstrip-top>.k-tabstrip-items-wrapper {
  .k-item {
    border-bottom: 0.0625rem solid;
    border-color: transparent;

    &:hover {
      border-bottom-color: #ddd;
    }
  }

  .k-item.k-state-active {
    border-bottom: 3px solid #008db9;
    margin-bottom: -3px;
  }
}

.k-tabstrip-items .k-link {
  padding: 0 !important;
  font-size: 14px;
  font-weight: 600;
}

.client-name-txt {
  font-size: 1rem !important;
  font-weight: 600;
}

.card-height-size {
  min-height: 160px;
  height: 160px;
}

.dflexBtn {
  display: flex;
  flex-wrap: wrap;
}

.group-event-top-blk input .bz-classes-search>.form-control {
  min-width: 155px !important;
  width: 155px !important;
}

.border-btm {
  border-bottom: 1px solid #e7e7e7;
}

.k-list-container input {
  margin-right: 5px;
}

.k-multiselect.bz-multiselect-arrow::after {
  content: "\25BC";
  position: absolute;
  top: 30%;
  right: 0.75rem;
  font-size: 10px;
}

.k-multiselect.bz-multiselect-arrow {
  .k-clear-value {
    right: 1.5rem;
  }

  .k-multiselect-wrap {
    padding-right: 3rem;
  }
}


.cards-items-grid {
  display: grid;
  grid-template-columns: 7.5rem 1.8rem 9rem;
  grid-column-gap: 1rem;

  >.bz-card-details {
    flex-direction: row;
    display: inline-flex;

    >span {
      min-width: 2rem;
    }
  }

  .btn-link {
    text-align: left;
  }
}

.cards-items-grid.remove-link {
  grid-template-columns: 7.5rem 1.8rem 9rem;
}

.cards-items-grid.remove-link.no-edit-link {
  grid-template-columns: 7.5rem 3.5rem 9rem;
}

.cards-items-grid.no-remove-link.edit-link,
.cards-items-grid.no-remove-link.edit-link.bz-change-prefered {
  grid-template-columns: 7.5rem 1.8rem 9rem;
}

.cards-items-grid.remove-link.edit-link {
  grid-template-columns: 7.5rem 3.5rem 3.5rem 9rem;
}

.cards-items-grid.remove-link.edit-link.bz-change-prefered {
  grid-template-columns: 7.5rem 1.8rem 3.5rem 9rem;
}

.cards-items-grid.no-remove-link.no-edit-link,
.cards-items-grid.no-remove-link.bz-change-prefered {
  grid-template-columns: 7.5rem 9rem;
}

.cards-items-grid.no-remove-link.edit-link.squre-payment {
  grid-template-columns: 7.5rem 9rem;
}

.cards-items-grid.remove-link.edit-link.squre-payment,
.cards-items-grid.remove-link.bz-change-prefered {
  grid-template-columns: 7.5rem 3.5rem 8.6rem;
}

.bz-plans-card.bz-arrow-right.bz-complementary .bz-arrow-icon {
  top: -1px !important;
}

.k-button[label='Unfreeze'] {
  margin-left: 0 !important;
}

.bz-unfreeze-page {
  .bz-payment-profile-options.checkbox-container {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.bz-payUnpaid-dues-dropdown {
  width: 100%;
  max-width: 16rem;
}

.bz-overview-page-row.bz-appoinments-info-sec {
  >.left-section {
    flex: 0 0 calc(100% - 21.5rem);
    margin-right: 1.5rem;
  }

  >.right-section.bz-inactive-error-card {
    margin-top: 1rem;
  }
}

.bz-classes-layout {
  .bz-classList-container.k-card.bz-groupServices-inhouse {
    >.k-card-body {
      .k-listview {
        height: 78vh;
      }
    }
  }
}

.bz-classSessionList-container>.k-card-body {
  padding: 0 !important;
}

.custom-grid-box {
  .bz-purchase-checkout-list {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .bzc-sessions-list-row-new {
        .section-3 {
          .bz-main-provider {
            h6 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.bz-package-purchased-success {
  .k-dialog {
    min-width: 26rem;
  }
}

.bz-checkout-inhouse-package {
  .bz-salesPerson-select {
    margin-top: 0 !important;
  }
}

.section-classItem {
  .k-chip-list {
    .k-chip.k-chip-solid {
      border: 0;
    }

    .k-chip-solid.k-state-selected {
      box-shadow: none;
      background-color: #008db9;
      color: #fff;
      border-color: #008db9;
    }

    .k-chip-solid:focus,
    .k-chip-solid.k-focus,
    .k-chip-solid.k-state-focus {
      box-shadow: none;
    }
  }
}

.bz-duration-label {
  .k-label {
    display: none;
  }
}

.attendees-link {

  h6 {
    padding-left: 0.375rem;
    color: #008db9;
  }
}

.bz-plan-puchase-page {
  .payment-card-input {
    padding-left: 1.5rem;
    max-width: 30rem;
    width: 30rem;

    .credit-card-input-form {
      .bz-custom-card-input {
        min-width: 28.5rem;

      }
    }

  }
}

.bz-duration-label {
  .k-label {
    display: none;
  }
}

.attendees-link {
  h6 {
    padding-left: 0.375rem;
    color: #008db9;
  }
}

.bz-plan-puchase-page {
  .payment-card-input {
    padding-left: 1.5rem;
    max-width: 30rem;
    width: 30rem;

    .credit-card-input-form {
      .bz-custom-card-input {
        min-width: 28.5rem;
      }
    }
  }
}

.bz-checkout-page {
  .payment-card-input {
    padding-left: 1.5rem;
  }
}

.payment-card-input {
  max-width: 23.2rem;

  #field-wrapper {
    overflow: unset;
  }

  .credit-card-input-form {
    .bz-custom-card-input {
      padding: 0.3rem 0.75rem;
      display: flex;

      label {
        margin: 0;
      }

      input {
        width: 100%;
      }

      >.card-number-sec {
        flex: 0 0 calc(100% - 11.3rem);
        margin: 0 0.75rem;
      }

      >.expiry-date-sec {
        flex: 0 0 3.6rem;
        max-width: 3.6rem;

        >label {
          width: 3.6rem;
        }
      }

      >.zipcode-sec {
        flex: 0 0 4.4rem;
        max-width: 4.4rem;
        margin-left: 0.75rem;

        >label {
          width: 4.4rem;
        }
      }
    }
  }
}

//kendo css
.k-multiselect.k-state-focused>.k-multiselect-wrap,
.k-state-focused.k-dropdowntree>.k-multiselect-wrap {
  box-shadow: none;
  border-color: #89848c;
}

.k-multiselect {
  >.k-multiselect-wrap {
    .k-button {
      text-align: left !important;
    }
  }
}

.k-menu-group .k-menu-item {
  font-size: 0.875rem;
}

.k-animation-container {
  z-index: 99999 !important;
}

.k-button {
  text-transform: capitalize;
}

.k-popup.k-child-animation-container {
  padding: 0;
}

.k-animation-container {
  .k-list-container.k-group {
    width: 100%;
  }
}

.k-popup {
  >.k-group.k-reset {
    margin-right: 0 !important;
  }

  >.k-list-optionlabel {
    padding: 0.35rem 0.3rem !important;
    font-size: 0.875rem;
    cursor: pointer
  }

  .k-state-selected.k-list-optionlabel {
    color: #008db9;
    background-color: #fff;
  }
}

.k-menu-group .k-menu-link {
  padding-right: 1rem;
}

.k-list-filter {
  padding: 8px 12px;
  position: relative;

  >input.k-textbox {
    width: 100% !important;
    padding-left: 2rem;
  }

  >.k-i-search {
    position: absolute;
    top: 50%;
    transform: translate(0.75rem, -50%);
  }
}

.k-button[label="Proceed to Buy"] {
  text-transform: initial;
}

.payment-profile .add-new-btn .k-button {
  min-width: 3.6rem !important;
  margin-right: 0.5rem !important;
  border-color: #008db9 !important;
  color: #ffffff !important;
  background-color: #008db9 !important;
  border-radius: 3px !important;
  font-size: 0.875rem !important;
  max-height: 33.1px;
}

.one-time-charge {
  .add-new-btn {
    >.k-button {
      margin-left: 0;
    }
  }
}

.bz-plans-card.bz-client-icon {
  .bz-service-card-inner {
    padding-bottom: 1rem;
  }

  .bz-member-type {
    position: absolute;
    bottom: 1rem;
    margin-bottom: 0 !important;
  }
}

.bz-overview-appoinmentsInfo {
  >.card-body {
    display: flex;

    >.bz-elipsisis-icon {
      flex: 0 0 1rem;
    }

    >.media {
      flex: 0 0 calc(100% - 1rem);

      .media-body {
        .d-flex {
          align-items: flex-start;
          justify-content: space-between;

          .bz-appoinments-card-status {
            display: inline-flex;
            align-items: center;
            min-height: 1.15rem;
            gap: 5px;

            >span.d-inline-flex {
              >div {
                display: inline-flex;
              }
            }

            .bz-badge-warning {
              .payment-required-warning {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.Booking-Main .second-panel.hide-available-times.guestCalender {
  width: auto;
}

.guestCalender {
  .css-26l3qy-menu {
    top: unset;
    bottom: 2rem;
  }

  .availableTimes-Item,
  .bz-newCalender-back-btn {
    max-width: 360px !important;
    margin-left: 1.2rem;
  }

  .bz-newCalender-back-btn {
    >.btn {
      cursor: pointer;
    }
  }

  .k-form-field {
    margin-bottom: 1rem;
    max-width: 360px;
    margin-left: 0.95rem;
  }

  .calendar-section-heading {
    padding-left: 0 !important;
    max-width: 360px;
    margin-left: 0.95rem;
  }

  .bz-calender-sec {
    >.k-calendar {
      .k-calendar-header .k-calendar-title {
        padding-left: 0.7rem !important;
      }

      >.k-calendar-view {

        table,
        .k-calendar-table {
          border-spacing: 0 8px !important;
        }

        .k-calendar-thead {
          .k-calendar-th {
            width: 55px;
          }
        }

        .k-calendar-td {
          width: 52px;
        }

        .k-today {
          .k-link {
            border: 1px solid #008db9;
          }
        }
      }

      >.k-calendar-monthview {
        .k-link {
          width: 46px;
          height: 46px;
        }
      }
    }
  }

  .bz-timezone-dropdown {
    max-width: 360px;
    margin-left: 1.1rem;

    .css-1s2u09g-control {
      cursor: pointer;
    }

    .css-1pahdxg-control {
      box-shadow: none;

      &:hover {
        border-color: hsl(0, 0%, 80%);
      }
    }

    .badge-primary {
      background-color: #008db9;
    }
  }
}

.Booking-Main {
  .second-panel.guestCalender {
    overflow: visible;

    .calendar-main {
      min-width: 380px;
      max-width: 380px;
      margin: auto;
      flex-direction: column;
    }
  }
}

.availableItemsCard {
  .k-form-field {
    margin-left: 1.1rem;
  }
}

@media screen and (max-width: 400px) {
  .Booking-Main .second-panel.guestCalender .calendar-main {
    min-width: auto;
  }

  .Booking-Main .second-panel.hide-available-times.guestCalender {
    padding: 0;
  }

  .guestCalender .bz-calender-sec>.k-calendar>.k-calendar-monthview .k-link {
    width: 38px;
    height: 38px;
  }

  .guestCalender .bz-timezone-dropdown {
    max-width: initial;
    margin-left: 1rem;
  }

  .guestCalender.bz-availableTimesCard .availableTimes-Item,
  .guestCalender.bz-availableTimesCard .bz-newCalender-back-btn,
  .guestCalender.bz-availableTimesCard .k-form-field {
    margin-left: 0 !important;
  }

  .guestCalender.bz-availableTimesCard .render-time-duration {
    padding-right: 0.5rem;
  }

  .backbuttonschdeule {
    display: block !important;
  }

  .scheduledapptsheader .scheduleappts.clientdashboardheaderqlinks {
    padding-top: 80px;
  }

  .calendartabsappts .k-tabstrip-items-wrapper {
    margin-left: 10px !important;
  }
}

.mdi:before {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-alpha-c-box-outline::before {
  content: "\F0BF1";
}

.k-form-hint.k-text-start.complimentary-hint {
  color: #008db9 !important;
}

.bz-content-body.payment-profile {
  display: grid;
  grid-template-columns: 16rem 5.5rem;

  .addNewBtn {
    align-self: flex-start;

    .add-new-btn .k-button {
      min-width: 3.6rem !important;
      margin-right: 0.5rem !important;
      border-color: #008db9 !important;
      color: #ffffff !important;
      background-color: #008db9 !important;
      border-radius: 3px !important;
      font-size: 12px !important;
      max-height: 2.188rem;
    }
  }
}

.one-time-charge {
  .bz-content-body.payment-profile {
    grid-template-columns: 17.5rem 5.5rem;
  }
}

.Booking-Main .second-panel.guestCalender.bz-availableTimesCard>.calendar-main.bz-guest-calendar-main>.availableTimes-Item .render-time-duration {
  grid-template-columns: repeat(2, 1fr);
}

.add-new-btn .k-button {
  min-width: 3.6rem !important;
  margin-right: 0.5rem !important;
  border-color: #008db9 !important;
  color: #ffffff !important;
  background-color: #008db9 !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  max-height: 35px;
}

.cash-img-icon {
  width: 1.1rem;
  margin-right: 0.25rem;
}

.bzc-date-sec {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
}

.chargeback-card {
  margin: 1.5rem;

  .main-grid {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #9f9f9f;
    border-radius: 7px;
    box-shadow: 0 3px 8px #00000029;

    .main-grid-item {
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr 1fr 2rem;

      .column-3,
      .column-4 {
        align-self: center;
      }

      .column-2 {
        display: flex;
        flex-direction: row;

        .addNewBtn {
          align-self: flex-end;

          .add-new-btn .k-button {
            min-width: 3.6rem !important;
            margin-right: 0.375rem !important;
            border-color: #008db9 !important;
            color: #ffffff !important;
            background-color: #008db9 !important;
            border-radius: 3px !important;
            font-size: 12px !important;
            max-height: 35px;
          }
        }

        .payment-profile-dw {
          min-width: 10rem;
        }
      }
    }

    .main-grid-item.bz-chargeback-first-row {
      >.column-3 {
        display: flex;
        justify-content: flex-end;
      }
    }

    .main-grid-item.bz-chargeback-second-row {
      grid-template-columns: 10rem 1fr;
      align-items: flex-end;
      margin-bottom: 0.2rem;

      >.bz-btn-blk {
        display: flex;
        align-items: self-end;
        justify-content: flex-end;

        >.column-3 {
          margin-top: 21px;
        }
      }
    }
  }

  .dropdown-serviceType {
    width: 200px;
  }

  .dflexBtn-chargeback {
    display: flex;
    justify-content: space-between;
  }
}

.bz-chargeback-dialog {
  .purchased-dots {
    padding-left: 0.5rem;
  }

  .column-button-section {
    padding: 0;
    padding-top: 1rem;
  }
}

.bz-vertical-line {
  display: inline-block;
  border-right: 1px solid #c6d1d4;
  width: 1px;
  height: 1rem;
  margin: 0 0.5rem;
}

.edit-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.enroll-client-col {
  .enroll-attendees-blk {
    display: flex;
    align-items: center;

    .btn-link {

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.bz-complementaryCheckout {
  .bz-complementary-provider-dropdown {
    .k-dropdown {
      min-height: 3rem;

      .k-input {
        height: auto;
      }
    }
  }
}

.bz-provider-lists {
  max-height: calc(100vh - 12rem);
  overflow: auto;
}

.edit-package-form-new {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  >.pkg-col-1 {
    .section-CanClientBookOnline {
      margin-top: 1rem;
    }

    .section-SessionType {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      >div {
        flex: 0 0 31%;
      }
    }

    .section-SessionType.UnLimited {
      justify-content: flex-start;

      >.k-form-field {
        margin-right: 1.5rem;
      }
    }
  }

  >.pkg-col-2 {
    .section-Description {
      .k-textarea>.k-input {
        min-height: 210px;
      }
    }
  }

  >.btn-col-1 {
    grid-area: 1/1/1/3;

    >.section-k-form-buttons,
    .section-k-form-add-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .k-input {
    height: 35px;
  }
}

.edit-package-form-new-row-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 1.5rem;

  .k-input {
    height: 35px;
  }

  .upload-file-section {
    margin-top: 1rem;
  }
}

.pkg-card-padding {
  padding-right: 3rem;
}

.add-pkg-form-new.UnLimited-blk {
  >.pkg-col-1 {
    .section-SessionType>.session-type-field {
      flex: 0 0 100%;
    }
  }
}

//quick links css
.QuickLinksGrid-new {
  display: flex;
  flex-wrap: wrap;
  max-width: 30rem;

  >.QuickLinksParentGridItem {
    clear: both;
    color: #212529;
    display: flex;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
    text-align: inherit;
    white-space: nowrap;
    width: 13.5rem;
    border-color: #eaedf2 !important;
    box-shadow: 0 2px 18px #00000005 !important;
    border-width: 0.0625rem !important;
    border-style: solid !important;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    &:hover,
    &:focus {
      background-color: #f8f9fa;
      color: #16181b;
    }

    .quick-link-item {
      display: inline-flex;
      width: 100%;
      color: #212529;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
      line-height: 1.6;
      font-size: 0.875rem;

      .item-icon {
        display: inline-flex;
        margin-right: 0.5rem;
        font-size: 1.5rem;

        >img {
          width: 1.5rem;
        }
      }
    }
  }

  >.QuickLinksParentGridItem.Book.Appointments {
    grid-area: 1/2/7/3;
    padding: 0;

    >.quicklink-dropdown-link {
      width: 100%;

      .k-button {
        margin: 0;
        padding: 0.75rem 1rem !important;
        background-color: transparent;
        border: 0;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        font-size: 0.875rem !important;

        >.btn-content {
          >.item-icon {
            display: inline-flex;
            margin-right: 0.5rem;
            font-size: 1.5rem;
          }
        }

        >span {
          display: flex;
          align-items: center;

          >svg {
            margin-left: 0.15rem;
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .QuickLinksParentGridItem.Merge.Client {
    // padding: 0;

    .k-button {
      background-color: transparent;
      border: 0;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      font-size: 0.875rem !important;

      >.btn-content {
        >.item-icon {
          display: inline-flex;
          margin-right: 0.5rem;
          font-size: 1.5rem;
        }
      }

      >span {
        display: flex;
        align-items: center;

        >svg {
          margin-left: 0.15rem;
          font-size: 1.25rem;
        }
      }
    }
  }
}

.k-popup {
  ul.k-list {
    >.k-item[aria-disabled="true"] {
      cursor: not-allowed;
    }
  }
}

//Subscription overview css
.overview-grid.bz-subscription-overview {
  .bz-content-body {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 1rem;
    max-width: 20rem !important;
  }

  .bz-payment-options {
    display: flex;
    background-color: #fff;
    padding: 0.35rem 0.6rem;
    border: 1px solid #efeded;
    box-shadow: 0 2px 18px #00000005;
    max-width: 15rem !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    >.subscription-name,
    >.btn-link {
      grid-area: 1/1/1/3;
    }

    >.subscription-name {
      display: flex;
      align-items: center;
      padding-right: 1.25rem;

      .mble-subscription-badges {
        position: absolute;
        right: 0.6rem;
        top: 0.35rem;
        margin-left: 0;
      }
    }

    h6 {
      font-size: 14px;
      margin-bottom: 0;

      >.name {
        color: #008db9;
        cursor: pointer;
        max-width: 8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .overview-col-2 {
      display: flex;
      align-items: center;

      .bz-card-details {
        display: flex;
        font-size: 12px;

        img {
          margin-right: 0.25rem;
        }
      }

      .badge {
        font-size: 12px;
        font-weight: 500;
        padding: 0;
      }
    }

    .overview-col-2.bz-nomask-no {
      >.badge {
        margin-left: 0 !important;
      }
    }

    .badge-info {
      padding: 0.25em 0.4em;
      background-color: #b0b1b1;
      margin: 0;
      cursor: pointer;
    }
  }
}

.k-switch {
  width: auto;
  line-height: 1;

  .k-switch-container {
    width: 35px;
    display: inline-flex;

    .k-switch-handle {
      width: 12px;
      height: 12px;
      vertical-align: middle;
    }
  }
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 12px) !important;
}

.switch-label {
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-weight: 500;
}

label.reedem-class {
  font-weight: 500;
  padding-bottom: 0.2rem;
  font-size: 12px;
  color: #8f9bb3;
  display: inline-block;
  margin-bottom: 0;
  line-height: 16px;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  padding: 0 0 0.2rem;
}

.ft-12 {
  font-size: 12px;
}

.font-90 {
  font-size: 90%;
  margin-left: 27px;
}

.card-header.order-summary-header {
  padding: 0.25rem 0;
  background-color: #fff;
}

.bz-classes-tag-card {
  .card-body {
    padding-left: 0;

    .bz-packages-tags-new {
      padding: 0;

      .k-chip {
        .k-chip-label {
          max-width: 10rem;
        }
      }
    }
  }
}

//group events staff
.bz-group-events-staff {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.bz-group-events-staff.hide {
  margin-bottom: 0;

  .bz-assignClass-pkg-btn {
    display: none;
  }
}

.bz-purchase-checkout-list.bz-multiplepackage-select .k-multiselect.bz-multiSelect .k-multiselect-wrap {
  .k-searchbar {
    line-height: 1.3;

    >.k-input {
      height: auto;
    }
  }
}

.withdraw-plan {
  .addNewBtn {
    .k-button.k-flat {
      margin-left: 0;
    }
  }
}

.bz-subscribe-addon {
  .bz-colon {
    display: none;
  }
}

.bz-payment-transanction-grid-blk {
  max-width: 67.3rem;

  .k-grid-content td {
    word-wrap: break-word;
    line-height: 1.2;
  }
}

.bz-payment-transanction-grid-blk.transactionspayments {
  max-width: 80em;
}

.payment-required-warning {
  >.svg-inline--fa {
    color: #dc3545 !important;
  }
}

//applied coupon
.bz-apply-coupon-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-color: #42cb5e;
  border-style: dashed;
  padding: 0.75rem;
  padding-right: 2.5rem;

  h6 {
    margin-bottom: 0;
    font-size: 0.875rem;

    >img {
      margin-right: 0.25rem;
    }
  }

  .close {
    top: 50%;
    padding: 0.25rem 0.5rem;
    transform: translate(0%, -50%);

    &:focus {
      outline: none;
    }
  }
}

.bz-apply-coupon-new.coupon-oneonone {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h6 {
    margin-bottom: 0.25rem;
    display: flex;
    text-align: left;

    img {
      margin-top: 0.05rem;
    }
  }

  .bz-coupon-desc {
    font-size: 0.75rem;
    margin-left: 1.1875rem;
    text-align: left;
  }

  .close {
    top: 1.3rem;
  }
}

.payment-card-input-new {
  >.payment-card-input-blk {
    width: auto;
    display: inline-block;
    min-width: 375px;

    >.k-form-field {
      display: inline-block;

      >.credit-card-input-form {
        .bz-custom-card-input {
          display: flex;
          padding: 0.25rem 0.75rem;
          justify-content: center;
          align-items: center;

          >div {
            display: table-cell;

            >label {
              margin: 0;
            }
          }

          >.icon-sec {
            display: inline-flex;
          }

          >.card-number-sec {
            padding: 0 0.75rem;

            >label {
              width: 10rem;
              display: inline-block;
            }
          }

          >.expiry-date-sec {
            >label {
              display: inline-block;

              >input[value=""] {
                width: 3.4rem;
                font-size: 14px !important;
              }

              >input {
                width: 2.75rem;
              }
            }
          }

          >.zipcode-sec {
            padding-left: 0.75rem;
            min-width: 5rem;

            >label {
              display: inline-block;

              >input[value=""] {
                width: 4.2rem;
                font-size: 14px !important;
              }

              >input {
                width: 4rem;
                font-size: 14px !important;
              }
            }
          }
        }

        .credit-card-input {
          width: inherit;
          padding: 0;
          font-size: 14px !important;
        }
      }
    }
  }
}

.checkout-page {
  .checkout-panelbar-wrapper {
    .payment-card-input-new {
      .bz-custom-card-input {
        .zipcode-sec {
          width: 5rem;
        }
      }
    }
  }
}

.k-popup.k-list-container.k-child-animation-container.k-group .k-list[role="menu"] li.k-item[role="menuItem"] {
  width: auto;
}

.bzc-void-grid {
  colgroup {
    col:nth-child(1) {
      width: 140px;
    }

    col:nth-child(2) {
      width: 140px;
    }

    col:nth-child(3) {
      width: 120px;
    }

    col:nth-child(4) {
      width: 170px;
    }

    col:nth-child(5) {
      width: 110px;
    }
  }

  .k-grid-footer td,
  .k-group-footer td,
  .k-grouping-row td {
    font-weight: 500;
    font-size: 0.85rem;
  }
}

//refund transanction grid
.bz-refund-grid {
  max-width: 600px;
}

.bz-pickschedule-blk {
  .customDropdown {
    cursor: pointer;

    .k-input {
      height: auto;
    }
  }
}

.k-list-container {
  .k-list {
    >.k-item.k-state-selected {
      >.bz-pickSchedule-drop-item {
        div.col-9 {
          >span {
            color: #008db9 !important;
          }
        }
      }
    }
  }
}

//Manage Subscription
.k-slider-horizontal .k-label {
  bottom: -1.9em;
}

//package page
.bz-edit-Package-form.bz-edit-pkg-new {
  .bz-groupServices-card-row {
    >.column.frequency {
      >span {
        max-width: 9rem;
      }
    }
  }

  .edit-package-form-new {
    >.pkg-col-1 {
      .action-items {
        flex-direction: column;

        >.section-Status {
          margin-top: 1rem;
        }
      }
    }

    >.pkg-col-2 {
      .k-textarea {
        >textarea {
          height: 8rem;
        }
      }
    }
  }

  .section-Type {
    .k-dropdown-button {
      >.k-button {
        margin: 0;
      }
    }
  }

  .k-numerictextbox,
  .k-dropdown,
  .k-multiselect {
    height: 2.25rem;

    .k-input {
      height: auto;
    }
  }

}

.short-code {
  .input-group {
    width: auto;

    >.form-control.k-textbox {
      flex: 0 0 8rem;
      width: 8rem;
    }

  }
}

.bz-add-pkg-dialog.bz-book-Appoinment-dialog.k-dialog-wrapper {
  .k-dialog {
    width: 31.25rem !important;

    .k-dialog-content {
      .k-numerictextbox {
        width: 100%;
      }
    }
  }
}

//edit events
.bz-edit-appoinments {
  .bzc-edit-session-options-row-buttons {
    justify-content: flex-start;
  }

  .bzc-edit-session-options-row,
  .bzc-edit-session-options-row-new {
    .input-group {
      .k-numerictextbox {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .input-group-append {
        >.k-dropdown {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

// delete appoinment dialog
.bz-delete-appoinment-dialog {
  .k-dialog {
    max-width: 36rem;

    .ready-to-book-appt-dialog {
      .column-1-section.cancel-label {
        grid-area: 1/1/1/3;
      }
    }
  }
}

//package summary cards
.package-redeem-card-blk {
  display: flex;

  >.reedem-summary {
    flex: 0 0 12.5rem;

    >div {
      min-height: 4.1rem;
      margin: 5px 0;
      display: flex;
      align-items: center;
    }

    h6 {
      margin-bottom: 0;
      border-right: 1px solid #d3d3d3;
      padding-right: 1rem;
      min-height: 2rem;
      display: flex;
      align-items: center;
    }
  }

  >.package-redeem-card {
    // flex: 0 0 calc(100% - 12.5rem);
    width: calc(100%);
  }
}

.text-upper {
  text-transform: uppercase;
}

.FamilyMemberEdit,
.CustomMaskedTextBox {
  .k-textbox {
    width: 100%;
    height: 100%;
  }
}

.bz-plan-puchase-page {
  .checkout-panelbar-wrapper {
    .k-panelbar {
      >.k-item {
        .k-animation-container {
          z-index: 100 !important;
        }
      }
    }
  }
}

.bz-member-info {
  display: flex;
  align-items: center;
  justify-content: fle;
}

.k-animation-container-shown {
  .k-slide-down-enter-active {
    ul.k-list {
      margin-right: 0 !important;
    }
  }
}

.bz-addon-overview {

  .payment-transaction-container,
  .bz-auditHistory-blk {
    h5 {
      display: none;
    }

    .bz-package-audit-history {
      padding-top: 0 !important;
    }
  }
}

.bz-payment-transanction-grid-blk {
  .bzc-grid .k-grid-content td {
    word-wrap: break-word;
  }
}

.bz-related-transaction-grid.bzc-grid .k-grid-content td {
  word-wrap: break-word;
}

//timezone dropdown changes
.bz-timezone-dropdown {
  position: relative;
  max-width: 356px;
  margin: auto;

  .css-b62m3t-container {
    width: 100%;
    font-size: 0.875rem;

    .css-26l3qy-menu {
      text-align: left;
      min-width: 17rem;
    }

    .css-1s2u09g-control {
      border: 0;

      &:hover,
      &:focus {
        border: 0;
      }

      >.css-17adf03-Sa {
        padding-left: 0;
      }

      .css-tlfecz-indicatorContainer {
        padding: 0;
        color: #000;
      }
    }

    .css-1pahdxg-control,
    .css-1s2u09g-control {
      box-shadow: none;
      border: 0;
      min-height: initial;

      .css-17adf03-Sa {
        padding: 0;

        .css-1usy4l0-Sa {
          padding: 0;
          margin: 0;
        }
      }

      .css-89lswr-Sa {

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
          padding: 0;
        }
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .badge {
    margin-left: 0.5rem !important;
    width: auto;
    background-color: #fff;
    color: #000;
    padding: 0;
    font-size: 0.875rem;
    z-index: 99;
    border-left: 2px solid #9e9e9e;
    border-radius: 0;
    padding-left: 0.5rem;
  }
}

//schedule page css changes
.Booking-Main.bz-provider-selected {
  margin: auto;
  height: auto;
  gap: 0;

  >.first-panel,
  >.one-panel {
    width: 23rem;
    border: 0;
    border-right: 1px solid #e2dede;
    border-radius: 0;

    >.upcoming-appts-collapse-card {
      background-color: transparent;
      color: #008db9;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.625rem;
      font-weight: normal;
      font-size: 0.875rem;
      padding: 0 1.25rem;

      >.btn-link {
        margin-left: 0.25rem;

        .svg-inline--fa {
          color: #008db9;
          font-size: inherit;
        }
      }
    }
  }
}

.bz-cont-btn {
  width: 100%;
  margin: 0;
}

//icon button
.bz-icon-btn {
  >.k-button {
    min-width: initial;
    background-color: transparent;
    border: 0;
    padding: 0 !important;
    margin: 0;
    font-size: 1rem !important;
  }
}

.k-dropdown-button.bz-icon-btn {
  >.k-button {
    background-color: transparent !important;
    color: #000 !important;
  }
}

.bz-custom-link {
  cursor: pointer;
  color: #008db9;
  text-decoration: underline;
}

//notes block
.bz-notes-blk {
  >h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bz-package-audit-history {
    padding: 0.5rem 0 !important;

    .bz-package-audit-history-list {
      padding-left: 0;
    }
  }

  .bz-norecords-available {
    margin-top: 1rem;
  }
}

.bz-notes-dialog {
  >.k-dialog {
    min-width: 33rem;

    .k-dialog-content {
      .k-textarea {
        width: 100%;
        margin-bottom: 1rem;
      }

      .dialog-btn-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

//agreement window
.k-window.bz-agreement-window {
  top: 0.5rem !important;
  height: calc(100% - 1rem) !important;
  padding-bottom: 1rem;

  .agreement-view {
    >table {
      background-color: white;
    }

    th,
    td {
      padding: 0;
    }

    table,
    td,
    th {
      border: 0;
      text-align: left;
      font-size: initial;
    }
  }
}

.bzo-notification {
  min-width: 20rem;
}

.prev-selected-slot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: normal;
  color: #000;
  margin-bottom: 0.5rem;

  >span:first-child {
    font-weight: 600;
  }

  >span:last-child {
    color: #84898c;
  }
}

.bz-md-phone {
  font-size: 1rem;
}

.bz-md-location {
  font-size: 1.1rem;
}

.bz-md-video {
  font-size: 1.1rem !important;
}

.location-list-blk {
  display: flex;
  align-items: center;
  width: 100%;

  >.icon {
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-right: 0.25rem;
  }

  .content {
    display: flex;
    flex-direction: column;

    .title {
      color: #000;
    }

    .desc {
      font-size: 12px;
      color: #6c757d;
    }
  }
}

.Booking-Main {
  .provider-items {
    .location-blk-text {
      align-items: flex-start !important;
      margin-left: -0.2rem;
    }

    .location-icon-blk {

      .bz-md-video {
        font-size: 1.292rem;
      }

      .bz-md-phone {
        font-size: 1.2rem;
      }

      .bz-md-location {
        font-size: 1.3rem;
      }
    }
  }
}

.location-text {
  .location-icon-blk {
    line-height: 1.25;
  }
}

.block-all-slots {
  pointer-events: none;
  opacity: 0.7;
}

.unblock-all-slots {
  pointer-events: all;
  opacity: 1;
}

.services-member-schedule {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  flex-wrap: nowrap;
}

.bz-schedule-link-btn2 {
  display: flex;
  justify-content: flex-end;

  >.k-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    text-transform: capitalize;
    color: #000;
    min-width: auto;
    margin: 0;
    grid-gap: 0;

    >.svg-inline--fa {
      color: #008db9;
      margin-right: 0.3rem;
    }

    &:hover {
      color: #008db999;

      >.svg-inline--fa {
        color: #008db999;
      }
    }
  }
}

.bz-schedule-link-btn2.add-group-btns {
  .k-button {
    color: #008db9;

    &:hover {
      color: #008db9a1;
    }
  }
}

.payments-grid {
  .payments-inr-grid {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }

  .payments-inr-grid2 {
    display: grid;
    grid-template-columns: 1.1fr 0.25fr 2fr;
  }
}

.pe-none {
  pointer-events: none;
}

// Self CheckIn Portal Start
.selfcheckin {
  width: 180px;
}

.selfcheckin-box {
  background-color: #e2e2e2;
  width: 180px;
  height: 180px;
  border-radius: 0.72rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selfcheckin-box .fa-lock-open,
.selfcheckin-box .fa-wifi {
  font-size: 42px;
  line-height: 46px;
  color: #707070;
}

.selfcheckin p {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #707070;
  padding-top: 10px;
}

.k-popup.k-child-animation-container .k-list .k-item[role='option'] {
  padding: 0.35rem 0.3rem !important;
}

.selfcheckin:hover .selfcheckin-box {
  background-color: #008db9;
}

.selfcheckin:hover .selfcheckin-box .fa-lock-open,
.selfcheckin:hover .selfcheckin-box .fa-wifi {
  color: #fff;
}

.selfcheckin:hover p {
  color: #008db9;
}

.cursor-pointer {
  cursor: pointer;
}

.self-barcode label {
  font-size: 14px !important;
  display: inline-block;
  padding-left: 6px;
  margin-top: -3px;
}

.self-barcode .k-radio:checked:checked+label {
  font-weight: 700 !important;
}

.selfcheck_enter {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 10px 20px;
}

.selfcheck_enter:focus,
.selfcheck_enter:focus-visible {
  border: 1px solid #008db9;
}

.self-barcam .fa-camera {
  font-size: 28px;
  color: #707070;
  padding-right: 10px;
}

.self-barcam p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px;
}

.self-barcam:hover .fa-camera,
.self-barcam:hover p {
  color: #008db9;
}

.selfcampic {
  min-height: 400px;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 30px;
  display: none;
}

.selfcampic h4 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.selfcampic_in {
  min-height: 300px;
  width: 100%;
  background-color: #4e4e4e;
}

.selfcampic strong {
  font-size: 16px;
  color: #717171;
  font-weight: 400;
  padding-top: 5px;
  cursor: pointer;
}

.selfcheck_verify {
  padding: 10px 20px;
  margin: 0 auto;
  width: 40%;
  display: block;
}

.bz-content-text-row-new {
  display: grid;
  grid-template-columns: 1fr 0.25fr 2fr;
  align-items: center;
  padding-bottom: 0.75rem;
}

.enrol-cancel-span {
  color: #000;
  font-weight: 600;
}

.k-loader.laoder-full-screen {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
}

.generate-image-card {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

.checkinuser {
  width: auto;
  border: 1px solid #ddd;
  background: #f4f4f4;
  margin: 0 auto;
}

.checkinuser .user-pic {
  width: 108px;
  display: flex;
  align-items: center;

  >img {
    margin-left: 0.5rem;
    border-radius: 0.3rem;
  }
}

.checkinuser_name {
  color: #058fba;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 0.5rem;
}

.checkinuser .user-text {
  justify-content: left;
}

.render_block_txt {
  padding-left: 20px;
}

.render_block_txt h6 {
  font-weight: 600;
}

.render_block_txt .bz-service-type {
  color: #717171;
}

.render_resolve_in {
  text-align: center;
  border: 1px solid #9d9d9d;
  border-radius: 6px;
  background: #f4f4f4;
  min-width: 200px;
  height: 115px;
  padding-top: 20px;
}

.render_resolve_in strong {
  font-size: 14px;
  color: #3f414b;
  padding-bottom: 5px;
  display: block;
}

.render_resolve_in span {
  font-size: 14px;
  color: #35b994;
  padding-bottom: 5px;
  font-weight: 600;
  display: block;
}

.render_resolve_in small {
  font-size: 14px;
  color: #717171;
  padding-bottom: 5px;
}

.render_resolve_in p {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 0.5rem;
}

.card-checked {
  .card-body {
    background-color: #00000012;
    z-index: 9999999;
    border-radius: 0.4rem;
  }
}

.render_sub_parent {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card-disabled {
  .card-body {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.appoint_check {
  color: #008db9;
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 1.5rem;
}

.export-btn {
  border: 0;

  >.k-button {
    max-height: initial;
    margin: 0;
  }
}

.select_clientdrop {
  .k-input {
    font-size: 13px !important;
  }
}

.livestream_search {
  .k-input {
    font-size: 13px !important;
  }
}

.livestream_check {
  font-size: 18px !important;
  color: #3a3a3a !important;
  font-weight: 600 !important;
}

.livestream_info h5 {
  font-size: 14px;
  font-weight: 500;
  color: #3a3a3a;
  display: flex;
  align-items: center;
}

.livestream_info_walk {
  margin-right: 10px;
  font-size: 1rem;
  color: #fff;
  width: 50px;
  height: 50px;
  display: inline-flex;
  background-color: #008db9;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.livestream_info {
  .bz-member-icon {
    display: none;
  }
}

.livestream_profile {
  width: 70px;
  height: auto;
}

.livestream_michel h5 {
  font-size: 22px;
  color: #3a3a3a;
  text-transform: uppercase;
  font-weight: 800;
  padding-top: 10px;
  padding-left: 10px;
}

.livestream_michel h5 span {
  font-size: 16px;
  color: #717171;
  display: block;
  text-transform: none;
  font-weight: 400;
}

.livestream_head {
  border-radius: 0.4rem;
  border: 1px solid #dae1ed;
  height: 3rem;
  margin: 1rem 0;

  .card-body {
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    color: #181c32;
  }

  strong {
    text-transform: uppercase;
    font-size: 15px;
  }
}

.disable-card {
  cursor: not-allowed;
  pointer-events: none;
}

div#html5qr-code-full-region {
  img {
    display: none;
  }

  div#html5qr-code-full-region__scan_region {
    img {
      display: inline;
    }
  }
}

.bz-checkins-dialog {
  .k-dialog {
    max-width: 1350px;
    width: 90%;
  }
}

.bz-Self-CheckIn {
  .checkedin-success.selfcheckins-success {
    right: 0.8rem;
    top: -0.6rem;
  }

  .self-checkbox {
    min-height: 560px;
  }

  .checkinuser {
    background-color: transparent;
  }

  .bz-appoinments-info-selfechekin {
    .bz-upcoming-apponments-sm-card {
      >.card-body {
        padding: 1.5rem 1.25rem;

        .checkedIn-info {
          position: absolute;
          bottom: 0.4rem;
        }
      }
    }
  }
}

.bz-global-search {
  position: relative;

  .livestream_search {
    height: 100%;
    padding-left: 1.25rem;
  }

  >svg {
    position: absolute;
    left: 0.6rem;
    top: 0.5rem;
    font-size: 1.2rem;
    color: #696b6c;
  }
}

.Self-CheckIn {
  .appts-container {
    .bz-overview-appoinmentsInfo {
      min-height: 116px;
      border: 1px solid #d9d9d9 !important;
      box-shadow: 0 0 15px 0 #f9f9f9;
      border-radius: 0.4rem;
      cursor: pointer;
      max-width: 310px;
      z-index: 999;

      >.card-body {
        padding: 1rem;

        >.media {
          flex: auto;

          >.media-body {
            .d-flex {
              padding-right: 2rem;
            }
          }

          .render_block_txt {
            padding-left: 0.5rem;

            p {
              display: block;
            }
          }

          .payment-warning {
            position: absolute;
            top: 0.5rem;
            right: 1rem;
            font-size: 1.25rem;
          }
        }
      }
    }

    .bz-overview-appoinmentsInfo.card-disabled {
      cursor: not-allowed;
      background-color: #ebebeb !important;
    }
  }

  .checkedin-success {
    font-size: 1.25rem;
    color: #fff;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }

  .checkedIn-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #058fba;
    font-size: 0.75rem;
    min-width: 68px;

    .checkedin-time {
      font-size: 0.85rem;
      color: #058fba;
      line-height: 1;
      margin-right: 0.1rem;
    }
  }

  .today-appointments {
    >h6 {
      flex-direction: column;
    }
  }
}

.bz-checkin-status {
  .k-dialog-content {
    padding: 1rem !important;

    .k-listview {
      max-height: 225px;
    }
  }
}

.site-checkin-livestream {
  .livestream_head {
    .ba-activity-row {
      >.ba-activity-coloumn-1 {
        padding-left: 1.5rem !important;
      }
    }
  }

  .bzc-checkins-list {
    >.k-listview-content {
      >.ba-activity-card {
        .column {
          flex: 0 0 25%;
        }

        .livestream_list {
          >.row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
          }
        }

        .livestream_client {
          >.media {
            display: flex;
            align-items: center;

            .bz-user-img {
              border-radius: 50%;
            }
          }
        }

        .livestream_info {
          h6 {
            font-weight: 500;
            line-height: 1.314;
          }

          .bz-user-img {
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.checkbox-btn {
  background: #fff;
  border: 0;
  margin-left: 0;

  &:hover {
    border-color: transparent;
    background-color: transparent;
    color: #606060;
  }
}

.download-icon {
  background-color: #fafbfb;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  color: #5a5c5f;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.48rem;
  vertical-align: middle;
  text-decoration: none;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  cursor: pointer;
  background: transparent;
}

.checkbox-btn.k-state-hover {
  border-color: transparent;
  background-color: transparent;
  color: #606060;
}

.barcode-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  >.k-barcode {
    margin-left: -1rem;
  }
}

.bz-checkins-grid {
  .k-grid-header {
    thead {
      height: 2.5rem;
    }
  }

  .k-grid-container {
    .k-grid-table tr {
      height: 45px;
    }
  }

  .k-grid-content td {
    word-wrap: break-word;
  }
}

// checkin logs grid column width
.bz-grid-custom-fields {
  colgroup {
    >col:nth-child(1) {
      width: 200px;
    }

    >col:nth-child(2) {
      width: 150px;
    }

    >col:nth-child(3) {
      width: 220px;
    }

    >col:nth-child(4) {
      width: 125px;
    }

    >col:nth-child(5) {
      width: 110px;
    }

    >col:nth-child(6) {
      width: 180px;
    }

    >col:nth-child(7) {
      width: 180px;
    }

    >col:nth-child(8) {
      width: 110px;
    }

    >col:nth-child(9) {
      width: 180px;
    }

    >col:nth-child(10) {
      width: 200px;
    }

    >col:nth-child(11) {
      width: 200px;
    }

    >col:nth-child(12) {
      width: 200px;
    }
  }
}

// Responsive Start
@media only screen and (max-width: 1100px) {
  .select_clientdrop {
    width: 210px !important;
  }
}

.k-listview.k-widget.k-d-flex.addgroupclassnews {
  max-height: 448px;
  overflow-y: auto;
}

@media only screen and (max-width: 1024px) {
  .livestream_mobile {
    width: 100%;
    overflow-x: scroll;
  }

  .livestream_head,
  .livestream_mobile .k-listview {
    width: 100%;
    min-width: 1100px;
  }

  .k-listview.k-widget.k-d-flex.addgroupclassnews {
    max-height: 466px;
    overflow-y: auto;
  }
}

@media only screen and (max-width:885px) {
  .k-listview.k-widget.k-d-flex.addgroupclassnews {
    max-height: 316px;
    overflow-y: auto;
  }

}

@media only screen and (max-width: 768px) {

  .k-listview.k-widget.k-d-flex.addgroupclassnews {
    max-height: 258px;
    overflow-y: auto;
  }

  .site-checkin-livestream .filter-options {
    display: block !important;
    width: 100%;
  }

  .site-checkin-livestream .k-dropdown {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .select_clientdrop {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .export-btn {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .livestream_search {
    width: 100% !important;
  }
}

@media only screen and (max-width: 599px) {
  .site-checkin-livestream .ba-activity-card .ba-activity-row {
    flex-direction: inherit;
  }

  .livestream_mob {
    display: none;
  }

  .livestream_mob_txt {
    display: block !important;
  }

  .livestream_mob_txt h5 {
    text-align: center;
    font-size: 20px !important;
    margin-right: 0 !important;
  }

  .selfscan_mob {
    width: 100% !important;
  }

  .selfcheck_enter {
    height: 50px;
    font-size: 14px !important;
  }

  .Self-CheckIn h4 {
    font-size: 23px !important;
  }

  .home_checkin {
    display: block !important;
    margin-top: 70px;
  }

  .today-appointments p {
    font-size: 14px;
  }

  .checkinuser .user-pic {
    width: 100%;
    height: 100%;
  }

  .user-content .user-details {
    display: block !important;
  }

  .checkinuser_name {
    font-size: 18px;
  }

  .checkinuser h6 {
    font-size: 13px;
  }

  .checkinuser span {
    font-size: 11px;
    display: block;
  }

  .Self-CheckIn br {
    display: none;
  }

  .site-checkin-livestream {
    .live-stream-mobile {
      .filter-right {
        .bz-global-search {
          width: 100%;
          margin-bottom: 0.625rem;

          >.livestream_search {
            width: 100%;
            max-width: 100% !important;
          }
        }
      }
    }
  }
}

.checked-in-success {
  color: #058fba !important;
}

.k-popup.k-child-animation-container .k-list .k-item[role="option"] {
  padding: 0.35rem 0.3rem !important;
}

//self checkin styles
.self-checkbox {
  border: solid 1px #dee2e6;
  padding: 25px;
  min-height: 600px;
  text-align: center;
  height: 100%;
  border-radius: 0.4rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.self-checkbox-app {
  text-align: left;

}

.self-checkin-heading {
  font-size: 20px;
  font-weight: 700;
}

.self-btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.self-radio-checkgroup {
  background: #008db9;
  width: 100%;
  border-radius: 50px;
  padding: 5px 0;
  max-width: 300px;
  margin: auto;
}

.selfcheck-radio-btn {
  padding: 0.375rem 0.75rem;
  font-size: 13px !important;
  text-align: center;
}

.selectbarcode.self-barcode .active .selfcheck-radio-btn {
  color: #000000;
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  font-size: 13px !important;
}

.self-radio-checkgroup .selectbarcode label {
  font-size: 13px !important;
  color: #fff;
}

.self-checkin-input-box {
  padding-top: 25px;
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.selfcheck_enter.barcode {
  height: auto;
  border-radius: 50px;
}

.camericon {
  color: #9ba6aa;
  float: right;
}

.scan-camera,
.barcode-scan-content {
  text-align: center;
}

.barcode-scan-content img {
  padding: 35px 0;
}

.barcode-scan-content p {
  font-size: 16px;
  font-weight: 700;
  color: #92a5ac;
  width: 80%;
  margin: auto;
}

.self-checkbox-details {
  border: 1px solid #e3e0e0;
  border-width: 1px 0;
  padding: 10px 0;
}

.self-checkbox-details p {
  width: 50%;
  margin: auto;
  text-align: center;
}

.checkinuser .user-pic.seflcheck {
  width: auto;
}

.no-appointment-bz {
  background-color: #f4f0e7;
  color: #918889;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  text-align: center;
  border: 1px solid #e8dfce;
  font-weight: 700;
}

.checkinuser_name.selfcheckin-name {
  font-size: 14px;
  color: #000000;
  text-transform: capitalize;
}

.bz-appointments-selfcheckin {
  color: #2888e2;
  font-size: 18px;
  font-weight: 700;
  display: block;
  width: 100%;
}

.checkedin-success.selfcheckins-success {
  position: absolute;
  right: 15px;
}

.k-button.k-primary.resetbtn-checkin {
  color: #008db9 !important;
  background: none !important;
  border: none !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-decoration: underline;
  padding: 0.35rem 0.3rem !important;
}

.bz-myprofile-container {
  .k-datepicker {
    padding: 0;
  }
}

.disabled {
  opacity: 0.5;
  box-shadow: none;
  cursor: not-allowed;
}


.bz-directLink {
  border-color: #e2d3d3;
  display: inline-block;
  padding: 0.563rem 0.5rem;
  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 0.2rem;
}

.chargeback-global-window {
  top: 0 !important;
  border: 0;

  .k-window-titlebar {
    display: none;
  }

  z-index: 10002 !important;
}

.bz-checkin-logs-window {
  width: 100% !important;
}

.bz-logs-filter {
  .filter-level-2 {
    font-size: 0.875rem;

    input[type="text"],
    input {
      font-size: 0.875rem !important;
    }
  }
}

.btn-click-sendqr {
  background-color: #008db9;
  padding: 2px 5px;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
  border-radius: 50px;
  text-align: center;
}

.self-checkin-input-box .input-group-text,
.selfcheck_enter:focus {
  border-color: #ddd;
}

.self-checkin-input-box .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.bz-location-content>div {
  display: inline-flex;
}

.bzc-payout-toggle-form {
  input[type=checkbox] {
    display: none;
  }
}

.criteria {
  font-size: 20px;
}

.bzc-edit-session-body-new {
  input[type=checkbox] {
    display: none;
  }
}

.bz-location-content>div {
  display: inline-flex;
}

.bzc-payout-toggle-form {
  input[type=checkbox] {
    display: none;
  }
}

.criteria {
  font-size: 20px;
}

.bzc-edit-session-body-new {
  input[type=checkbox] {
    display: none;
  }

  .bzc-edit-session-options-row {
    .k-checkbox-label {
      padding-left: 0;
    }
  }
}

.bz-filled-btn {
  border-color: grey;
  background-color: grey;
  color: #fff !important;
  border: 1px solid #fff !important;
  letter-spacing: 1.73px;
  padding: 0.25rem 2.25rem !important;
  border-radius: 0.4rem !important;
}

@media screen and (max-width: 639px) {
  .bz-dateTime small {
    font-size: 10px
  }
}

.checkedin-light {
  background-color: #5db75d;
  color: #fff;
  padding: 2px 9px;
  font-size: 12px;
  border-radius: 50px;
}

.checkedin-light .checkicon {
  color: #fff
}

.bz-mybookings-dialog-box .k-dialog .k-dialog-content p small {
  display: block;
}

.bz-overview-appoinmentsInfo.card-disabled .card-body {
  background-color: #00000012;
}

.manage-leads-grid table th {
  font-size: 12px;
  font-weight: 600;
}

.manage-leads-grid table td {
  font-size: 12px;
}

.insert-charge-back-table table th,
.charge-back-table table th {
  font-size: 14px;
  font-weight: 600;
}

.insert-charge-back-table table td,
.charge-back-table table td {
  font-size: 14px;
}

.availble-payment-methods table th {
  background: #f5f5f5;
}

.availble-payment-methods table th,
.availble-payment-methods table td {
  padding: 5px;
  font-size: .875rem;
  text-align: left;
  color: #181c32;
  font-weight: 600;
}

.availble-payment-methods table td,
.availble-payment-methods table td .k-input {
  font-size: .8125rem;
  font-weight: normal;
}

.availble-payment-methods table tbody th:first-child {
  font-weight: 500;
}

.bz-available-payment-methods {
  >table {
    th {

      padding: 5px 14px;
    }

    td {
      padding: 5px 14px;
    }
  }
}

.insertbtn .add-new-btn .k-button {
  min-width: 171px !important;
  margin: 0 !important;
}

.edit-payment-method {
  color: #008db9;
  text-decoration: underline;
}

.checkins-success-message {
  font-size: 1.5rem !important;
}

.access-control-layout .k-appbar.self-checkin-nav {
  background: #fff;
  display: block;
}

.k-appbar.self-checkin-nav .k-appbar-section {
  display: block;
}

.self-checkin-nav .bz-tenet-name {
  color: #000
}

.selfcheckin-height {
  height: auto;
  display: block;
  position: relative;
  top: 45%;
  border-width: 0;
  min-height: auto;
  box-shadow: none;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.selfcheckin-active {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  background: #5db75d;
  font-size: 14px;
  color: #fff;
  width: 90%;
  display: block;
  padding: 5px;
  border-radius: 50px;
  margin: auto;
  text-align: center;
  max-width: 300px;
}

.checkinuser .user-pic.seflcheck,
.user-content .user-details {
  display: block !important;
}

.close-checkins,
.close-checkins:focus {
  background-color: #19addd;
  border: none !important;
  padding: 2px 6px;
  cursor: pointer;
  display: inline-block;
  color: #fff
}

.justify-content-right {
  justify-content: right !important;
}

.bz-addCard-dialog {
  z-index: 10003 !important;
}

.btnnowshow.bz-elipsisis-icon .k-button.k-button-icon {
  width: 100px !important;
  text-align: right !important;
}

.dialogbarcode .k-window {
  width: 80%;
  max-width: 500px;
}

.qr-buzops {
  padding: 0 15px
}

.input-group-text {
  padding: .275rem .75rem;
}

.bz-appoinments-info-selfechekin {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  --bs-gutter-x: 0;
  padding: 0.1rem;
  padding-right: 0.35rem;
}

.bz-appoinments-info-selfechekin .col-md-6 {
  width: 100%;
}

.bz-appoinments-info-selfechekin .card-disabled {
  background-color: rgba(0, 0, 0, .07058823529411765) !important;
}

.bz-checkins-sites {
  .k-multiselect-wrap {
    >ul {
      display: inline-block;
      width: 100%;

      >li.k-button {
        width: 98%;
        justify-content: space-between;

        >span {
          text-align: left;
        }
      }
    }

    >.k-clear-value {
      display: none;
    }

    >.k-searchbar {
      >.k-input {
        height: auto;
        padding: 0 0 0.25rem 0.25rem;
      }
    }
  }
}

.render_resolve_in.suspendbuzpos {
  background-color: #fff;
  border: .0625rem solid #eaedf2 !important;
  padding: 10px;
}

.render_resolve_in.suspendbuzpos .name {
  color: #008db9;
  font-size: 14px;
}

.render_resolve_in.suspendbuzpos .inactive {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  font-size: 12px;
  padding: 0 5px;
}

.render_resolve_in.suspendbuzpos .Plan {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 12px;
  padding: 0 5px;
}

.render_resolve_in.suspendbuzpos .buttonpaynow {
  border-color: #008db9 !important;
  color: #ffffff !important;
  background-color: #008db9 !important;
  border-radius: 3px !important;
  font-size: 12px;
}

@media screen and (max-width: 680px) {
  .self-signin.self-login-bz {
    display: block;
  }

  .self-signin.self-login-bz .bzo-checkIn-login-card {
    width: 100%;
    max-width: 400px;
  }

  .self-signin.self-login-bz .card.p-5 {
    padding: 1.5rem !important
  }

  .row.bz-appoinments-info-selfechekin {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .self-checkbox {
    min-height: inherit !important;
  }

  .checkinuser .user-pic>img {
    width: 100px !important;
    height: 100px !important;
  }

  .slefcheckinmb {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:500px) {
  .bz-payment-method-grid {
    .bz-available-payment-method {
      overflow: auto;

      >table {
        width: 700px;
      }
    }
  }
}


.bz-addon-cancellation .bz_fs_1_5 {
  font-size: 16px;
}

.bz-payment-method-grid {
  .bz-available-payment-methods {
    >table {
      >thead {
        th:nth-child(1) {
          width: 150px;
        }

        th:nth-child(2) {
          width: 120px;
        }

        th:nth-child(3) {
          width: 120px;
        }

        th:nth-child(4) {
          width: 120px;
        }

        th:nth-child(5) {
          width: 80px;
        }
      }
    }
  }
}

.linked-subscription-grid {
  >table {

    th,
    td {
      padding: 5px 14px;
      vertical-align: middle;
    }

    >thead {
      th {
        width: 33%;
      }
    }

    td {
      .k-dropdown {
        width: 16rem !important;
      }
    }
  }
}


.bz-card-new {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  border: 1px solid #efeded;
  box-shadow: 0 2px 18px #00000005;

  .bz-group-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 8rem;
  }

  .badge-info {
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.25rem;
  }
}

.bz-package-utilization {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  >.bz-card-new {
    flex: 0 0 9rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}



.bz-upcoming-appointments-list-view {
  .k-listview-content {
    max-height: 27rem;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);



    >.col-md-6.col-xxl-4 {
      width: 100%;
      padding: 0;

      >.bz-appoinments-info-card {
        margin-bottom: 0 !important;
        box-shadow: 0px 0px 3px 0 #e2e5e8 !important;
        border: 0 !important;

        .card-body {
          padding: 0.75rem;

          p {
            font-size: 0.875rem;
          }
        }

        img.bz-user-img {
          width: 2.5rem;
          align-self: self-start !important;
        }

        .bzo-service-status {
          font-size: 0.75rem;
        }

      }
    }

    .bz-norecords-available {
      grid-column: 1 / 3;
    }
  }
}

.k-grid th,
.k-grid td {
  word-wrap: break-word;
  text-overflow: initial;
}

.qrcodescanner {
  max-width: 600px;
  width: 100%;
  min-height: 400px;
  height: 100%;
}

.bz-groupServices-sessions-list-new .bz-time-section h6 {
  padding-left: 0;
}

.left-section h5 {
  font-size: 1rem;
  font-weight: 600;
}

.packagecoupons .bzc-grid.bz-checkins-grid {
  width: 100% !important;
}

.payment-required-appointments {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.appointment-info-tabs {
  .k-last {
    float: right;
    border-bottom: unset !important;
  }
}

.k-popup.k-list-container.k-reset.k-group.k-child-animation-container {
  >.k-list[role="menu"] {
    >.k-item[role="menuItem"] {
      padding: 0.4rem 0.6rem;
    }
  }
}

.bz-checkins-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bz-plan-cancellation-dialog {
  .k-dialog {
    max-width: 28rem;

    table {
      border: 0;
      margin-bottom: 1rem;
      width: 100%;

      tr {
        height: auto !important;

        td {
          padding: 0;
          padding-bottom: 0.5rem;
          border: 0;
          font-size: 0.875rem;
        }

        &:last-of-type {
          >td {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .k-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}

.coupon-list-card {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0rem 0.9rem;

  .coupon-card-item {
    height: 80px;
    border-color: #008db9 !important;
    border-radius: 3px !important;
    border: 1px solid #008db9 !important;
    font-size: 0.76rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 220px;
    padding: 0.5rem;
  }
}

.reports-date-range-dropdown {
  border-color: #008db9;
  color: #008db9;
}


.appointment-info-tabs,
.forms-list-tabs {
  .k-animation-container {
    min-height: 28rem;
  }
}

.bz-checkins-date-filter {
  >.k-grid-toolbar {
    overflow: inherit;
  }

}

.bz-zerodollar-icon {
  img {
    width: 20px;
  }
}

.bz-group-services-list.bz-past-GroupServices.statuses-alignment {
  .bzc-sessions-list-card {
    .bzc-sessions-list-row {
      >.member-name {
        padding-right: 1rem;

        h5 {
          >img {
            vertical-align: baseline;
          }
        }
      }

      >.group-name {
        padding-right: 1rem;

        .bz-location-content {
          svg {
            vertical-align: text-bottom;
          }
        }
      }
    }
  }
}

.bz-bussiness-address-warning {
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;

  .alert-link {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

  }
}

.quicklink-dropdown-button {
  width: 215px !important;
  border: 1px solid #eaedf2 !important;
}

.bz-checkins-settings-container {
  .bz-content-text-row.bz-error {
    align-items: flex-start;
  }
}

.bz-checkins-config {
  padding: 0 0.75rem;

  .bz-activity-log-section {
    .bz-package-audit-history {


      .bz-package-audit-history-list {}
    }
  }
}

.bz-checkins-config-dialog {
  .k-window-title {
    font-weight: 600;
    line-height: 1.8;
  }
}

.bz-openpath-settings-container {
  .bz-content-text-row+.bz-entry-items {
    hr {
      display: none !important;
    }
  }

  .k-dropdown,
  .k-textbox {
    width: 15rem;
  }

}

.bz-checkins-settings-container {
  .k-numerictextbox {
    width: 15rem;
  }
}

.btn-link.remove-entry-btn {
  padding: 0 !important;
  font-size: 0.875rem !important;
  color: #dc3545 !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border-radius: 3px !important;
  border: 0 !important;
  margin: 0;

  &:hover,
  &:focus {
    text-decoration: underline !important;
    background-color: transparent !important;
    border: 0 !important;
  }
}

//open path credintials
.bz-openPath-credintials {
  padding: 0.75rem;
  background: #ffffff !important;

  >.mobile-onsite-checkins {
    >.section-2 {
      text-align: center;
      flex: 0 0 10.5rem;
    }
  }

  .bz-access-credintials-status {
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    >img {
      width: 1rem;
      margin-right: 0.4rem;
    }
  }

  .bz-access-credintials-status.active,
  .bz-access-credintials-status.assigned {
    color: #1FD447;
  }

  .bz-access-credintials-status.noaccess,
  .bz-access-credintials-status.notassigned {
    color: #B6BCC2;
  }

  .bz-access-credintials-status.pending {
    color: #D3A22F;
  }

  .bz-access-credintials-status.suspended {
    color: #E7320E;
  }

  .bz-checkins-actions-container {
    .k-button.k-button-sm {
      min-width: 5rem;
      font-size: 12px !important;
      padding: 0.25rem 0.75rem !important;
      line-height: 1.15;
      gap: 0;
      min-height: initial;

      >svg {
        margin-right: 0.25rem;
      }
    }
  }

  .openpath-credintials {
    .k-multiselect .k-multiselect-wrap {
      padding-right: 1.75rem;
    }
  }
}

.bz-openPath-credintials.bz-onsiteAccess {
  >.mobile-onsite-checkins {
    >.section-2 {
      text-align: left;
      width: 15rem;
      flex: 0 0 15rem;
    }
  }
}

// grey out disabled fields
.bz-openPath-credintials {

  .k-text-disabled,
  .k-state-disabled {
    opacity: 1;
  }

  .k-dropdown-wrap.k-state-disabled,
  .k-multiselect.k-state-disabled>.k-multiselect-wrap,
  .k-textbox:disabled {
    background-color: #e9ecef !important;
    opacity: 1;
    color: #A9B0B5;
  }

  .k-multiselect.k-state-disabled>.k-multiselect-wrap {
    border-color: #e9ecef;
  }

  .k-multiselect.k-state-disabled+.k-text-disabled {
    color: #A9B0B5;
  }

  .k-textbox:disabled {
    border-color: #e9ecef;
  }
}

.bz-openPath-credintials.cursor-not-allowed {
  .k-primary {
    opacity: 0.5;
  }

  .bz-openPath-actions {
    >.section-3 {
      >span {
        opacity: 0.5;
      }
    }
  }

  .k-dropdown {
    .k-dropdown-wrap {
      background-color: #e9ecef !important;
      opacity: 1;
      color: #A9B0B5;
    }
  }

  .mobile-onsite-checkins {
    >.section-3 {
      .btn-link {
        color: #B6BCC2;
      }

      .form-control {
        background-color: #e9ecef !important;
        opacity: 1;
        color: #A9B0B5;
      }
    }
  }
}

.k-widget.k-maskedtextbox.masked-phone {
  input.k-textbox {
    width: 100%;
  }
}


.plan-cards-lead {
  display: flex;
  gap: 2rem;
  padding-top: 0.5rem;

  .plan-cards-lead-item {
    cursor: pointer;
    width: 350px;
    border: 1px solid #008DB9;
    height: 75px;
    display: grid;
    grid-template-columns: 1fr 1.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;

    .plan-check-lead {
      display: flex;
      align-items: flex-start;
      height: 100%;
      padding-top: 2px;
    }

    .plan-details {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .plan-details-fixed {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.bz-openPath-credintials.bz-checkins-barcode {
  >.mobile-onsite-checkins {
    >.section-3 {
      text-align: left;
      max-width: 17rem;
      flex: 0 0 17rem;
    }
  }
}

.bz-barcode-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -0.9rem;
  margin-top: 1.5rem;

  >.bz-send-qr-icon {
    margin-top: 0.75rem;
  }
}

.custom-grid-box {
  .bzc-sessions-list-card-new.bz-classes-checkout {
    .bzc-sessions-list-row-new {
      .bzo-status-success {
        padding: 2px 10px;
        font-size: 12px;
      }
    }
  }
}

.checkout-panelbar-wrapper {
  .package-redeem-card-blk>.package-redeem-card {
    .package-item {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

.bz-checkout-switch-btns {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  .redeem-switch {
    padding-right: 1rem;
  }

  .bzc-session-price {
    display: flex;
    align-items: center;

    >small {
      font-size: 13px;
      color: #393939;
      margin-right: 0.5rem;
    }
  }
}

.bz-kiosk-link {
  display: flex;
  align-items: center;

  >span {
    flex: 0 0 5rem;
  }

  >div {
    word-break: break-all;
  }
}

.manage-leads-grid {
  .k-grid-content td {
    word-wrap: break-word;
  }

  .k-master-row .k-grid-content-sticky {
    border-color: #dee2e6;
  }

  .k-master-row.k-alt {
    &:hover {
      background-color: #fff;
    }

    .k-grid-content-sticky {
      background-color: #fff;
    }
  }

}

.send-link-btn {
  min-width: initial;
  font-size: 12px !important;
  padding: 0.25rem 0.5rem !important;
  line-height: 1.15;
  grid-gap: 0;
  gap: 0;

  >svg {
    margin-right: 0.25rem;
  }
}

h4.side-heading {
  color: #000 !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
}

.manage-tax-container {
  display: flex;
  flex-wrap: wrap;

  .tax-card-item {
    cursor: default;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 18px rgba(47, 43, 61, .1), 0 0 transparent, 0 0 transparent;

    .tax-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.tax-card-item.tax-disabled {
  background-color: #e9e9e9;

  .k-switch-off .k-switch-container {
    background-color: #e9e9e9;
  }
}

.affiliate-program-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .affiliate-program-card-item {
    box-shadow: 0 1px 3px 1px #008DB9;
    padding: 0.25rem;
    box-shadow: 0 1px 3px 1px #008DB9;
    width: 280px;
    border: 1px solid #008DB9;
    height: 80px;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    .affiliate-program-text {
      font-size: 1.25rem;
      font-weight: 600;
      color: #008DB9
    }

    cursor: pointer;
  }
}

.rewards-list-view-container {
  border: 1px solid #eaedf2;
  border-radius: 7px;
  padding: 16px;
  width: 100%;

  .bz-provider-name {
    padding-left: 0;
  }
}

.rewards-list-view {

  border: 0px solid #eaedf2;


  height: 100px;

  background-color: #fff !important;
  grid-template-columns: 1fr 1fr 0.3fr;
  align-items: center !important;
  gap: 10px;

  .section-3 {
    display: flex;
    gap: 10px;
  }

  .section-1 {
    .section-1-items {
      position: relative;

      .bz-event-type {
        position: absolute;
        top: -28px;
        min-width: 140px;
        text-align: center;
        background-color: #008db9;
        color: #fff;
        border-radius: 0 0 5px 5px;
        font-weight: 500;
        font-size: 0.78rem;
      }
    }
  }

  .bz-main-provider {
    padding-top: 0.5rem;
  }
}

.bz-rewards-list-view {
  .k-listview-content {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 0rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);



    >.col-md-6.col-xxl-4 {
      width: 100%;
      padding: 0;

    }
  }
}

.affiliate-program-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .affiliate-program-card-item {
    padding: 0.25rem;
    box-shadow: 0 1px 3px 1px #008DB9;
    width: 280px;
    border: 1px solid #008DB9;
    height: 80px;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    .affiliate-program-text {
      font-size: 1.25rem;
      font-weight: 600;
      color: #008DB9
    }

    cursor: pointer;
  }
}



.bz-offers-list-view,
.bz-subscriptions-list-view {
  .k-listview-content {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 0rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);



    >.col-md-6.col-xxl-4 {
      width: 100%;
      padding: 0;

    }
  }
}

.credentialopenpath .k-textbox-container {
  display: block;
  width: 100%;
  max-width: 250px;
}

.credentialopenpath .k-textbox-container .k-widget.k-dropdown {
  width: 100%;
}

.rewards-form {
  input.reward-form-input {
    width: 50% !important;
  }

  .k-datepicker {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-dropdown {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-numerictextbox {
    width: 50% !important;
    padding: 0 !important;
  }

  .k-numerictextbox.CommissionAmount {
    width: 18% !important;
    padding: 0 !important;
  }

  .k-numerictextbox.ChargesCount {
    width: 18% !important;
    padding: 0 !important;
  }
}

.rewards-modal-form {
  input.reward-form-input {
    width: 100% !important;
  }

  .k-datepicker {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-dropdown {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-numerictextbox {
    width: 100% !important;
    padding: 0 !important;
  }

  .k-numerictextbox.CommissionAmount {
    width: 65% !important;
    padding: 0 !important;
    margin-bottom: 0.25rem !important;
  }

  .k-numerictextbox.ChargesCount {
    width: 65% !important;
    padding: 0 !important;
  }
}

.reward-edit,
.create-new-payout-btn {
  padding: 0.35rem 0.5rem !important;
  border: 1px solid #008db9;
  border-color: #008db9;
  color: #008db9;
  background-color: #ffffff;
}

.k-listview-item.selected-reward,
.k-listview-item.selected-offer {
  background: lightblue;
}

.border-1 {
  border: groove;
}

.box {
  float: left;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .2);
}

.red {
  background-color: rgba(243, 23, 0, 0.32);
}

.light-blue {
  background-color: #ADD8E6;
}

//topaz signature pad
.bz-connect-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  margin: auto;
  padding: 0.5rem;
  margin-top: 0.5rem;

  >p {
    color: #9d414a;
  }
}

.grid-editors {
  width: 100%;
}

.mobilecalendar {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobilecalendar {
    display: block;
    margin-top: 30px;
  }

  .prospect-user.no-ads {


    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;


  }

  .prospect-user.no-ads .selected-slot-single {
    width: 90%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    background: #fff;
    padding: 10px 0;
  }

  .prospect-user.no-ads .rowslots {
    display: inline-block;
    float: left;
    width: 30%;
  }

  .prospect-user.no-ads .rowslots .card,
  .prospect-user.no-ads .selected-slot-single .card-body {
    padding: 12px !important;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item {
    margin-bottom: 4rem !important;
  }

  .Booking-Main>.first-panel>.firstPanelContent>.provider-items {
    width: 100% !important;
    padding: 0 !important;
  }

  .profilepic {
    width: 30%;
  }

  .profileinfo {
    width: 70%;
  }

  .profileinfo .bzo-status-consultation,
  .profileinfo .bz-duration,
  .locationcalendar,
  .timezone-mobile-text {
    font-size: 0.75rem;
  }

  .profileinfo .bz-duration {
    margin-left: 5px;
  }

  .locationcalendar {
    border-top: 1px solid #e2dede;
    padding-top: 5px
  }

  .calendardesktop {
    display: none !important;
  }
}

.phone-tooltip {
  max-width: 11rem;
}

.show-tax-content-row {
  align-items: baseline;
}

.bz-taxpanel-arrow-icon {
  cursor: pointer;
  font-size: 1rem;
}

//tooltip table
.taxes-tooltip-table {
  border: 1px solid #6c757d;
  color: #dee2e6 !important;

  td,
  th {
    border: 1px solid #6c757d;
  }

}

.k-tooltip.bz-tooltip-table {
  padding: 0;
  border-radius: 0;
}

.bz-tooltip-table {
  >.k-tooltip-content {
    >.taxes-list {
      min-width: 11rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      >.list-group-item {
        background-color: #1a233a;
        padding: 0.25rem 0.5rem;
        border: 0;
        align-items: flex-start !important;

        >span {
          &:nth-child(1) {
            flex: 0 0 calc(100% - 3rem);
            max-width: 10rem;
            padding-right: 1rem;
            word-wrap: break-word;
            white-space: break-spaces;
          }
        }
      }
    }
    > .taxes-list.rolled-over-text{
      min-width: auto;
      >.list-group-item {
        > span{
          flex-basis: auto;
          padding-right: 0;
          max-width: 9.5rem;
        }
      }
    }
  }
}

.bz-18 {
  font-size: 18px;
}

.icon {
  &-error {
    color: red
  }

  &-completed {
    color: green
  }

  &-failed {
    color: red
  }
}

.suspend-container {
  .bzc-failedTransaction-container {
    .bzc-failedTransactionGrid {
      max-width: 51rem;
    }

    hr {
      display: none;
    }
  }
}

.bz-notes-dialog.onsiteaccessmodal>.k-dialog {
  width: 90%;
  max-width: 800px;
  min-width: initial;
}

.bz-notes-dialog.onsiteaccessmodal .k-window-titlebar {
  white-space: normal;
}

@media (max-width: 620px) {
  .bz-notes-dialog.onsiteaccessmodal .k-window-title.k-dialog-title {
    max-width: initial;
  }
}

@media (max-width: 768px) {
  .openpathflex {
    display: flex;
  }

  .openpathflex .credentialopenpath {
    order: 1
  }

}

.offers-list-view {
  border: 0px solid #9f9f9f;
  border-radius: 7px;
  min-height: 90px;
  background-color: #fff !important;
  display: flex !important;
  grid-template-columns: 1fr 1fr 1fr 0.3fr;
  align-items: center !important;
  justify-content: space-between;

  .bz-main-provider {
    padding-top: 0.5rem;
  }
}

.top-promotions-grid,
.top-affiliates-grid {
  border: 1px solid #eaedf2 !important;
  border-radius: 5px;
  width: 100%;
}

.top-promotions-grid .bz-card,
.top-affiliates-grid .bz-card {
  border: 0px solid #eaedf2 !important
}

.promotions-list-view {
  border-width: 0;
  border-bottom: 1px dashed #eaedf2;
  border-radius: 0px;
  min-height: 100px;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 0px !important;
  gap: 10px;

  .section-1 {
    h6 {
      color: #008db9;
      font-weight: 600;
      font-size: 13px;
      word-break: break-all;
    }

    width: 100%;
    display: flex;
    justify-content: space-between;

    .payout-settings {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }

    border-bottom: 1px solid lightgray;
  }

  .section-2 {
    padding-top: 5px;
    width: 100%;
    display: flex;
    gap: 2rem;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;

    .count-items {
      display: flex;
      width: 100%;
      gap: 1.2rem;
      justify-content: space-between;

      .child-count-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading-item {
          color: #607d8b;
          font-weight: 600;
          font-size: 13px;
          text-align: center;
        }

        .value-item {
          font-weight: 600;
          font-size: 14px;
        }

        .value-item-sm {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .rewards-section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: auto;
    }
  }
}

//taxes page css
.bz-taxes-card {
  >.bz-card {
    max-width: 24.125rem;
    height: 5.8rem;

    .bz-list-profile-lead {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 700;

      .k-switch {
        margin-left: 0.5rem;
      }
    }

    .bz-list-profile-caption {
      font-size: 0.75rem;
      color: #666678 !important;
      line-height: 1rem;
    }
  }
}

.bz-list-profile-lead {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;

  .k-switch {
    margin-left: 0.5rem;
  }
}

.bz-list-profile-caption {
  font-size: 13px;
  color: #666678 !important;
  line-height: 1rem;
}

.tax-card-item {
  min-height: 5rem;

  >.card-body {
    >.d-flex:first-child {
      width: calc(100% - 7.2rem);
    }
  }
}

.tax-card-item.category {
  min-height: 5rem;

  >.card-body {
    >.d-flex:first-child {
      width: calc(100% - 3.2rem);
    }
  }
}

.tax-card-item.category {
  .tax-name-clip {
    max-width: 90%;

  }
}

.status-links {
  cursor: pointer;

  :hover {
    color: #008DB9;
  }
}


span.highlight {
  display: inline-block;
  color: #FFC107;
}

.promotion-overview {
  h6 {
    color: #008db9;
    font-weight: 600;
    align-self: center;
    font-size: 14px;
  }

  .count-items {
    display: flex;
    width: 100%;
    gap: 1.2rem;
    justify-content: space-between;

    .child-count-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading-item {
        color: #272727;
        font-weight: 500;
      }

      .value-item {
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }



  .summary-card {
    background: #fff;
    padding: 16px;
    min-height: 140px;
    border: 1px solid #eaedf2;
    margin-bottom: 30px;
    border-radius: 0.5rem;

    .summary-card-heading {
      font-weight: 600;
      font-size: 1rem;
      padding: 0.25rem;
      background: #f1f5fc !important;
      font-size: 14px;
      color: #008db9
    }

    .summary-card-body {
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 1rem;

      .summary-items,
      .total {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
      }
    }
  }
}

.promotion-promoters {
  border: 0px solid #eaedf2;


  .border-bottom-buzops {
    border-bottom: 1px solid #e9ebec;
  }
}

.promotion-promoters-list-view {
  border-width: 0;
  border-bottom: 1px solid #eaedf2;
  border-radius: 0px;
  min-height: 100px;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;



  .section-1 {
    h6 {
      color: #008db9;
      font-weight: 600;
    }

    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
  }

  .section-2 {
    width: 100%;
    padding: 0.75rem;

    .count-items {
      width: 100%;
      display: flex;
      gap: 1.2rem;
      justify-content: space-between;

      .child-count-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading-item {
          color: #272727;
          font-weight: 500;
        }

        .value-item {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }
  }
}

.promotion-links {
  h6 {
    color: #008db9;
    font-weight: 600;
  }
}

.affiliate-layout {
  .App-body {
    padding: 7px 12px;
  }
}

.affiliates-selected-list-view {
  width: 320px;
  border: 1px solid #9f9f9f;
  border-radius: 7px;
  height: 80px;
  box-shadow: 0 3px 8px #00000029;
  background-color: #fff !important;
  display: grid !important;
  grid-template-columns: 1fr 0.3fr;
  align-items: center !important;
  justify-content: space-between;

  .bz-main-provider {
    padding-top: 0.5rem;
  }
}

.subscriptions-selected-list-view {
  border: 0px solid #eaedf2;
  border-radius: 7px;
  min-height: 80px;
  background-color: #fff !important;
  // display: flex !important;
  // grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .bz-main-provider {
    padding-top: 0.5rem;
  }
}

.session-price-inc-tax {
  display: flex;
  justify-content: flex-start;
  gap: 1.2rem;
}

.upcoming-appoinment-popup {
  li {
    display: flex !important;
    padding-left: 0;
  }
}

.tax-name-clip {
  display: inline-block;
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px dotted #d9d9d9;
  cursor: pointer;
}

.tax-card-item.tax-disabled {
  .tax-name-clip {
    border-bottom: 1px dotted #9a9a9a;
  }
}

.taxName-tooltip {
  max-width: 10rem;

  .k-tooltip-content {
    white-space: break-spaces;
    word-wrap: break-word;
  }
}

//migration page css
.bz-import-file-container {
  max-width: 35rem;

  .k-upload {
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #a3a3a3;
    border-radius: 5px;

    .k-dropzone {
      flex-direction: column-reverse;
      margin-bottom: 1rem;

      >.k-dropzone-hint {
        margin-bottom: 1.25rem;
      }

      >.k-upload-button {
        border-color: #008db9 !important;
        color: #fff !important;
        background-color: #008db9 !important;
        border-radius: 3px !important;
        padding: 0.4469rem 1rem !important;
        cursor: pointer;
      }
    }

    .k-upload-files {
      .bz-file-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #008db9;
        color: #fff;
        padding: 0.25rem 1rem;
        border-radius: 2rem;
        font-size: 0.78rem;

        >.bz-file-info {
          display: flex;
          align-items: center;
        }

        .k-i-close {
          font-size: 0.78rem;
        }
      }
    }
  }

  .btn-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

//migration filters css
.bz-migration-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  >.bz-leftSide-filter,
  .bz-rightSide-filter {
    display: flex;
    align-items: center;
  }

  >.bz-leftSide-filter {
    justify-content: flex-start;

    .badge-filter {
      display: flex;
      align-items: center;

      >.k-badge-container {
        >.status-links {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          border: 1px solid #ced4da;
          padding: 0.25rem 0.75rem;
          border-radius: 3rem;
          margin-right: 0.625rem;

          >.title {
            font-weight: 500 !important;
            margin-left: 0.25rem;
          }
        }

        .k-badge-edge.k-top-end {
          -webkit-transform: none;
          transform: none;
          position: inherit;
        }
      }
    }

    .showAll-filter {
      align-items: center;
      display: flex;
      margin-right: 0.625rem;
      max-width: 8rem;
    }
  }

  >.bz-rightSide-filter {
    justify-content: flex-end;

    .search-filter {
      width: 16rem;
      margin-right: 0.625rem;

      .input-group {
        .k-button-icon {
          min-width: auto;
          height: 100%;
          background-color: #e9ecef;
          border-color: #e9ecef;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }

    .multiselect-filter {
      width: 17.5rem;
      margin-right: 0.625rem;

      >.k-multiselect {
        li.k-button {
          min-width: auto;
          margin: 0.25rem 0 0 0.25rem;
        }

        .k-clear-value {
          height: 2rem;
        }
      }
    }
  }
}

.bz-migration-filter-container+.k-tabstrip {
  .payment-transaction-container {
    .bzc-grid .k-grid-content td {
      white-space: break-spaces;
      word-wrap: break-word;
    }

    .bzc-grid {
      .k-grid-content-sticky {
        z-index: 999;
      }
    }
  }
}

.badge-offer {
  color: #318200;
  background-color: #eafcde;
  border: none;
  font-weight: 700;
}

.bz-surcharge-history {
  colgroup {
    >col {
      width: 11rem;
    }
  }

  .k-grid-content td {
    overflow-wrap: break-word;
  }
}

.session-redeem {
  font-size: 0.75rem !important;
}

// my profile edit email fiel css
.edit-email-group {
  >.k-form-field {
    width: calc(100% - 1.83rem);

    .k-textbox {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  >.input-group-append {
    >.email-edit-icon {
      min-width: auto;
      margin: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      background-color: #e4e7eb;
      border: 1px solid #ced4da;
      border-left: 0;
      color: #495057;

      &:hover {
        border-color: #c7cdd5;
        background-color: #ced3db;
      }
    }
  }
}

.edit-email-group .input-group-append div .email-edit-icon {
  min-width: auto;
  margin: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: #e4e7eb;
  border: 1px solid #ced4da;
  border-left: 0;
  color: #495057;
  min-height: 35px;

}

.edit-email-group .k-textbox:disabled,
.k-textbox[disabled] {
  opacity: 1;
}

.k-textbox#MemberNumber[disabled] {
  border-color: #E0E0E0;
  background-color: #e9ecef !important;
  cursor: not-allowed !important;
  pointer-events: initial;
}

.non-editable-email {
  >.k-form-field {
    width: 100%;
  }
}

.bz-edit-email-dialog {
  >.k-dialog {
    min-width: 28.125rem;
    max-width: 28.125rem;

    >.k-dialog-titlebar {
      justify-content: space-between;
    }

    .k-form-field {
      margin-top: 0.5rem;
    }
  }
}

.Cancellation-fee-tooltip {
  .k-tooltip-content {
    width: 450px;
  }
}

input[type="text"].font-size-40 {
  font-size: 40px !important;
}

.bz-notification-container {
  .bz-content-text-row {
    display: grid;
    grid-template-columns: calc(60% - 2.5rem) 2.5rem 40%;

    .bz-content-body.submit-btn {
      min-width: 200px;
    }
  }
}

.payNow-addCard-popup {
  >ul {
    >li.k-item {
      min-width: 9.7rem !important;
    }
  }
}

.notification-sub-heading {
  font-size: 0.9rem;
  font-weight: 600
}

.savingBankAccount {
  color: green !important;
}

.border-window {
  border: 1px solid #17a2b8;
}

//my profile grid
.bz-profile-activate {
  display: inline-flex;
  align-items: center;
  line-height: 1;

  >.btn-link {
    font-size: 90%;
    cursor: pointer;
    border: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bz-prorate-checkbox {
  >span {
    display: flex;
    align-items: center;

    .k-checkbox-label {
      .k-dropdown {
        height: 1.5rem
      }

      .k-dropdown+div {
        display: inline-block;
      }
    }
  }
}

.bz-prorate-checkbox.bz-error-msg {
  >span {
    align-items: flex-start;

    .k-checkbox-label {
      >span {
        align-items: flex-start !important;
      }
    }
  }
}


.k-card-body.pipedriveCard {
  max-height: 70vh;
  overflow: auto;
}

.bz-prorate-checkbox {
  >span {
    .k-checkbox-label {
      >span {}
    }
  }
}

.checkin-report-dialog .k-dialog {
  width: 95% !important;
  max-width: 95% !important;
}

//email log grid
.bz-email-logs-grid {
  .bz-payment-transanction-grid-blk {
    max-width: initial;
  }

  h5 {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    text-transform: capitalize;
    color: #172a3f;
  }

  .k-grid-content td {
    line-height: 1.5;
  }

  colgroup {
    >col:nth-child(1) {
      width: 230px;
    }

    >col:nth-child(2) {
      width: 190px;
    }

    >col:nth-child(3) {
      width: 190px;
    }

    >col:nth-child(4) {
      width: 190px;
    }

    >col:nth-child(5) {
      width: 130px;
    }

    >col:nth-child(6) {
      width: 130px;
    }

    >col:nth-child(7) {
      width: 110px;
    }

  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-not-allowed>* {
  pointer-events: none;
}

.promo-link-text {
  height: 60px;
  border: 1px solid grey;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.hover-underline-animation {
  position: relative;
  color: #008db9;
  text-decoration: none;
  width: fit-content
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #008db9;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.bz-affiliate-payout-grid.bzc-grid {
  colgroup {


    >col {
      &:nth-child(n) {
        width: 11rem;
      }

      &:nth-child(1) {
        width: 2.5rem;
      }
    }

    >col:last-child {
      width: 5.5rem;
    }
  }

  .k-filtercell-operator {
    .k-button {
      min-width: 2.2rem;
    }
  }
}

.bz-affiliate-payout-grid-no-checkbox.bzc-grid {
  colgroup {
    >col:last-child {
      width: 5.5rem;
    }

    >col {
      &:nth-child(n) {
        width: 11rem;
        text-align: center;
      }
    }
  }

  .k-filtercell-operator {
    .k-button {
      min-width: 2.2rem;
    }
  }
}

.k-menu.k-menu-horizontal:not(.k-context-menu) {
  color: #ffffff;
  background-color: inherit;

  .k-menu-item {
    color: #ffffff;
    font-size: 14px;

  }

  .k-menu-item.active {
    color: #ffffff;
    background-color: #32a2c7;
    border-radius: 0.25rem;
  }
}

.menu-card-horizontal {
  padding-bottom: 0rem;
}

.affliates .k-menu-item {
  margin-right: 0.5rem;
}



.Affiliate-body {
  background-color: #1999c0;
}

.bz-affiliate-content {
  background-color: #1999c0;
  padding: 0px;
  height: 100px;

  .bz-affiliate-content-area {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
  }
}

.metrics-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .metrics-card {
    border: 1px solid grey;
    width: 350px;
    height: 100px;
    padding: 5px;
    border-radius: 4px;

    .metrics-card-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    .bz-avatar {
      width: 3.5rem;
      height: 3.5rem;
      background-color: #CCF5F8;
      border-radius: 50%;
      color: #00CFDD !important;
    }
  }

  .metrics-card-affiliate {
    width: 415px;
  }
}

.top-grid-view {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.affiliate-dashboard-overview {
  gap: 1rem;
}

.summary-card-payout {
  border: 1px solid #eaedf2;
  padding: 16px;
  border-radius: 4px;
}

.menu-card-horizontal.affliates .k-menu-link {
  padding: 0;

  .linkaffliates {
    padding: .5rem;
    display: block;
  }

  a {
    padding: .5rem;
    display: block;
  }
}



.menu-card-horizontal.affliates {
  .k-menu.k-menu-horizontal:not(.k-context-menu) .k-menu-item.active {
    background-color: #31a3c7;

  }

  .k-menu.k-menu-horizontal:not(.k-context-menu) .k-menu-item:hover {
    background-color: #31a3c7;
  }

  .k-menu.k-menu-horizontal:not(.k-context-menu) .k-menu-item:focus {
    box-shadow: none;
  }
}



.metric-list-row {
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.metrics-list-bd-count-card {
  border-width: 0.0625rem !important;
  border-style: solid !important;
  border-radius: 0.5rem;
  border-color: #eaedf2 !important;
  box-shadow: 0 2px 18px rgba(0, 0, 0, .02) !important;
  margin-bottom: 20px;

  .metrics-list-bd-count-card-body {
    padding: 1rem !important;
  }

  .metrics-list-avatar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;

  }

  .metrics-list-badge-circle-light-info {
    background-color: #CCF5F8 !important;
    color: #00CFDD !important;
  }

  .metrics-list-circle-light-primary {
    background-color: #E2ECFF !important;
    color: #5A8DEE !important;
  }

  .metrics-list-circle-light-success {
    background-color: #D2FFE8 !important;
    color: #39DA8A !important;
  }

  .metrics-list-circle-light-warning {
    background-color: #FFEED9 !important;
    color: #FDAC41 !important;
  }

  .metrics-list-avatar-lg {
    height: 4.5rem;
    width: 4.5rem;
    font-size: 260%;
  }

  .metrics-list-avatar-sm {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 180%;
  }

  .metrics-list-card-content {
    padding-top: 0.5rem;
    margin: 0;
    font-size: 0.875rem;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .metrics-list-card-content-sm {
    font-size: 0.75rem;
    margin-bottom: 0.1rem;
  }

  .metrics-list-count-card-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .metrics-list-count-card-title-sm {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

.top-promotins-title,
.top-affiloates-title {
  font-size: 14px;
  margin: 0 0 7px 0;
  font-weight: 600;

}

.k-card>.k-card-header.top-promotins-title {
  border-bottom: 1px solid #e9ebec;
}

.affiliateicon,
.affiliateicon:hover,
.affiliateicon:focus {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  outline: 0;
  padding: 0;

}

.editprofilebtn {
  background: #13c66b;
  color: #fff;
  padding: 5px 7px;
  border: none;
  border-radius: 5px;
  outline: 0;

}


.editprofilebtn:focus {
  outline: none
}

.affiliatemainheading {
  font-weight: 600;
  color: #212529;
  font-size: 14px;
}

.manage-affiliate-container {

  .bzc-grid .k-grid-header {
    border: 0px solid #e9ebec;
    border-width: 1px 0;
  }

  .bzc-grid .k-grid-header .k-header {
    background-color: #F3F6F9 !important;
  }

  .bzc-grid .k-grid-header .k-header,
  .bzc-grid .k-grid-content {
    border: none;
  }

  .k-grid td {
    border-width: 0 0 1px 0;
  }

  .k-column-title {
    color: #878a99;
    font-weight: 600;
  }

  .k-cell-inner>.k-link {
    padding: 16px 10px
  }

  .k-button.k-primary.addaffiliatebtn {
    min-height: 30px;
    padding: 5px 10px !important;
  }



}

.manage-affiliate-container-grid {
  border: 1px solid #eaedf2 !important;
}

.manage-affiliate-container-grid.payout {
  .bzc-grid>.k-grid-toolbar {
    padding: 0 15px 10px;

  }

  .bzc-grid .k-grid-header .k-header {
    color: #878a99;
    font-weight: 600;
    background-color: #F3F6F9 !important;
    border: none
  }

  .k-cell-inner>.k-link {
    padding: 0 10px;
  }

  .bzc-grid .k-grid-content td {
    border-width: 0 0 1px 0;
    padding: 5px 15px;
  }

}

.section-bg {
  width: 100%;
  background-color: #fafafa !important;
  padding: 5px 10px 5px 5px;

  .section-1 {
    border: none
  }

  h6 {
    margin-bottom: 0;
  }


}

@media (max-width: 1300px) {}

@media (max-width: 1200px) {
  .metrics-list-bd-count-card {
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {}

.linkbg {
  background: #ffffff !important;
  padding: 7px 10px;
  border-radius: 3px;
  border: 1px solid #eaeaea;

  a {
    color: #008db9;
    font-weight: 600;
  }


}

.btn-primary-buzops {
  background-color: #1999c0;
}

.col-md-4.summary-card-payout.links {
  width: 31%;
  margin: 0 1%;
}

@media (max-width: 768px) {
  .col-md-4.summary-card-payout.links {
    width: 100%;
  }


}

.affliatesignup {
  margin-top: 10%;
  margin-bottom: 10%;
  border-radius: 1rem;
}

.affliatesignupheader {
  font-size: 24px;
  color: rgb(113, 113, 113);
  font-weight: 500;
}

.loginpopup {
  width: 200px !important;
  font-size: 16px;
  display: block;
  padding: 10px !important;
  position: absolute !important;
  right: -100px !important;
  z-index: 9999999 !important;
  font-weight: 500 !important;
}

.sharableIcons {
  display: flex;
  gap: 0.5rem;
}

.payout-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;


}

.payout-debit {
  color: #fff;
  background-color: #dc3545;
}

.amountreward {
  min-width: 55px;
  display: inline-block;
}

.payout-credit {
  color: #fff;
  background-color: #19c079;
}

.w-payouts-summary.col-md-4 {
  width: 31.3%;
  margin-right: 2%;
}

.w-payouts-summary.col-md-8 {
  width: 64.7%;
  margin-right: 2%;
}

.payoutinfo .w-30 span {
  padding: 5px;
  border-radius: 7px;
  background-color: #eee;
  margin-bottom: 10px;
  display: inline-block;
  min-width: 80px;
  text-align: center;
  font-weight: 600;
}

.payoutinfo .w-75 span {
  font-size: 14px;
}

.bgbackaffilates {
  color: #1999c0;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  display: inline-block;
  margin: 0 18px;
}

.affiliatesubscriptions {
  background-color: #f1f5fc;
  padding: 10px;
  border-radius: 7px;

  p {
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    width: 96%;
    font-weight: 600;
    color: #008db9;
    margin: auto;
  }
}

.manage-affiliate-container-grid.payout .bzc-grid.payouts .k-grid-content td {
  padding: 14px;
  font-size: 14px;
  line-height: 20px;
}

.payout-pending {
  color: #f4bd0e;
  background-color: #fef8e4 !important;
  border-color: #fef8e4;
}

.payout-approved-not-paid {
  color: #854fff;
  background-color: #f2ecff !important;
  border-color: #f2ecff;
}

.payout-overdue {
  color: #e85347;
  background-color: #fceceb !important;
  border-color: #fceceb;
}

.payout-paid {
  color: #1ee0ac;
  background-color: #e6fcf6 !important;
  border-color: #e6fcf6;
}

.payout-recjected {
  color: #364a63;
  background-color: #e9ebee !important;
  border-color: #e9ebee;
}

.desaffiliates {
  font-size: 14px;
  margin-bottom: 0;
}

.k-listview-pager-chargeback {

  .k-pager-input,
  .k-pager-sizes,
  .k-pager-info {
    margin-top: 0.5rem;
  }
}

.payout-total-item {
  display: flex;
  width: 100%;
  justify-content: center;
}

.payout-total-grid {
  td {
    font-size: 12px;
    font-weight: 500;
  }

  th {
    font-size: 12px;
    font-weight: 600;
  }
}

.bz-Manage-User-Img span.bz-upload-userImg-only {
  position: absolute;
  right: 3.1rem;
  bottom: -0.5rem;
  padding: 0.1rem 0.2rem;
}

//small button
.k-button.btn-sm {
  padding: 0.15rem 0.5rem !important;
  min-width: 4.5rem;
  min-height: initial;
}

.bz-btn-danger {
  color: #fff;
  border-color: #f1416c;
  background-color: #f1416c !important;
}

.cross-word {
  text-decoration: line-through;
}

// group waitlist css
.groupclass-session-list.bz-group-card.bz-group-waitlist {
  grid-template-columns: 0.9fr 0.7fr 0.9fr 0.4fr 3rem;

  .bz-attendees-blk {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.groupclass-session-list.bz-group-card.bz-group-waitlist.addgroupmultisolts {

  grid-template-columns: 1.5fr 1fr .8fr .5fr .5fr auto;
  border-color: #cfcece;
  box-shadow: none !important;
}

.font12 .k-button {
  font-size: 12px !important;
}


.k-tooltip {
  max-width: 250px;
}

.bz-manage-declines.decline .manage-declines {
  margin: 10px 0 20px 0;
}

.bz-manage-declines.decline .k-listview-content {
  padding-right: 0;
}

.manageclientsbtn .k-button {
  background-color: transparent;
  border-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.875rem;
  color: #212529
}

.mininster92 .k-button {
  min-width: 92px !important;
}

.trnasctiongaction .k-button {
  font-size: 12px !important;
}

.bz-schedule-link-btn2>.k-button {
  padding: 0 !important;
}

.bz-addCard-dialog.bz-credit-topup-modal {
  >.k-dialog {
    min-width: 38.5rem;
  }
}

.bz-addCard-dialog.bz-addCard-details {
  .pl-24 {
    padding-left: 0 !important;
  }
}

.credit-activity-list {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  max-height: 120px;
  background-color: #fff !important;

  .section-3 {
    justify-self: end;
  }

  .svg-inline--fa {
    color: #008db9
  }
}

.credit-payment-badges {
  font-size: 2rem;
  padding: 0.5rem;
}

#PaymentGatewayPaymentProfileId {
  min-width: 14rem;
  min-height: 36px;

  .k-input {
    height: auto;
  }
}

.mergebutton {
  min-width: 100px;
}

//credit activity
.bz-credit-activity {
  .credit-activity-list {
    grid-template-columns: 15rem 30rem 1fr;
    justify-content: flex-start;

    >.section-1,
    .section-3 {
      .bz-provider-name {
        min-width: 7rem;
        text-align: center;
        padding-left: 0;
      }
    }

    >.section-3 {
      display: flex;
      justify-content: flex-end;
      padding-right: 8rem;
    }
  }

}

.bz-credit-activity+.k-pager {
  padding-bottom: 0;
  padding-top: 0;
  background-color: transparent;
}

.bz-payment-profile-dropdown {
  >div:first-child {
    width: calc(100% - 5rem);

    >.k-dropdown {
      width: 100% !important;
    }
  }

  .addNewBtn {
    .k-button {
      margin-right: 0 !important;
    }
  }
}

.InActiveaffiliatesubscriptions {
  background-color: #ffc10742;

  p {
    color: #ff4e07;
  }

}

.agreement-view.emaillogs center a {
  background-color: #008db9 !important;
  border: 1px solid #008db9 !important;
}

.bz-summary-profile {
  padding: 10px 0;
}

.manage-affiliate-container .k-pager.k-grid-pager .k-pager-info,
.manage-affiliate-container-grid .k-pager.k-grid-pager .k-pager-info {
  margin-right: 1rem;
}

.payoutPerformActions {
  width: 150px !important;
  font-size: 14px;
  display: block;
  padding: 5px !important;
  z-index: 9999999 !important;
  font-weight: 500 !important;
}

.affliatesignup .password_item input.k-textbox,
.affliatesignup input.k-textbox.AffiliateEmail {
  padding-right: 30px;
}



.email_envelope_icon {
  position: absolute;
  top: 30px;
  right: 20px;
}

.filter-payouts.row .k-multiselect .k-multiselect-wrap {
  padding-right: 40px;
}

.affiliate-dashboard-overview .summary-card-payout .summary-card-heading,
.toppromolinks .summary-card-payout .affiliatemainheading {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.25rem;
  background: #f1f5fc !important;
  font-size: 14px;
  color: #008db9;
}

.rewardspayout {
  min-width: 50px;
  display: inline-block;
}

@media (max-width: 576px) {

  .addpromotions .k-button {
    margin: 10px 0 0;
  }
}

.bz-affiliate-content-area .k-pager-wrap {

  background: #fff;
  border-color: #fff;
}

@media (max-width: 991px) {
  .section-2.adminpromotions {
    display: block;
  }

  .md-mb-affialete {
    margin-bottom: 15px;
  }

  .addpromotions button.k-button.k-primary {
    margin: 0;
  }

  .promotions-list-view .section-2 .rewards-section {
    display: block;
    width: 100%;
    margin: 5px 0;
    text-align: center;

    .mobile .mobilesub {
      display: block;
    }
  }

  .adminaffialteheader {
    margin-top: 25px;

    .searchinput {
      float: none !important;
    }
  }

  .addpromotionsaffialtes {
    .adminaffialteheader {
      margin-top: 0px;
    }
  }

  .linkdescriptions {
    max-height: none !important;
    overflow-y: auto !important;
  }

  .col-lg-3.col-md-4.col-12.mylinkspadding {
    padding-left: 4px;
  }
}

.managerewards {
  align-items: center;
  gap: 15px;

  .affiliatemainheading {
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .affliatlist {
    justify-content: start !important;
    margin-top: 10px;
  }

  .bz-affiliate-content {
    padding: 0 10px;
  }

  .managerewards {
    display: block !important;

    .affilatestext {
      margin-bottom: 10px;
    }

    .addNewBtn {
      margin-top: 0px;
      text-align: center;
    }
  }

  .manage-rewards-container .k-listview-content {
    margin: 0 !important;
  }

  .padding-affiliates,
  .k-card-body .k-menu.k-menu-horizontal:not(.k-context-menu),
  .promotions-list-view .section-2 {
    padding: 0 !important;
  }

  .promotions-list-view {
    border-bottom: 0;
  }

  .promotions-list-view .section-2 .count-items,
  .promotion-promoters-list-view .section-2 .count-items {
    display: block;
  }

  .promotions-list-view .section-2 .count-items .child-count-item,
  .promotion-promoters-list-view .section-2 .count-items .child-count-item,
  .rewards-section {
    margin: 10px 0;
    border: 1px solid #eee;
    padding: 5px;
  }

  .rewardspayout {
    min-width: auto;
  }

  .d-flex.mainrewardspayout {
    display: block !important;
    margin-bottom: 10px;
  }

  .mainrewardspayout .rewardsclon {
    display: none;
  }

  .promotionsboddy {
    padding: 1rem !important;
  }

  .bz-promotions-list-view.affliate .section-2 {
    display: block;
  }

  .promotions-list-view .section-2 .rewards-section,
  .rewards-section .mobile,
  .rewards-section .mobile .mobilesub {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  .mylinkspadding {
    padding: 4px !important;
  }

}

@media (max-width: 680px) {
  .edit-btn-container {
    text-align: center;
    display: block;
    margin: 10px 0;
  }

  .overviewdashboard.edit-btn-container {
    display: flex;
  }

  .manage-affiliate-container-grid {
    .headingmobileview {
      display: block !important;
    }

    .addfillatecontanier {
      text-align: center;
    }


    .addfillatecontanier .addaffiliatebtn {
      margin: 10px 0;
    }

    .headingaffliate {
      width: 100%;
      justify-content: space-between;
    }

    .k-pager-wrap.k-pager,
    .k-pager-sizes.k-label,
    .k-pager-info.k-label {
      display: block;
      text-align: center;
    }

    .k-pager.k-grid-pager .k-state-disabled.k-link,
    .k-pager-numbers-wrap {
      display: inline-block;
    }

    .k-pager-sizes.k-label {
      margin: 15px 0 !important;
    }

    .k-pager-info.k-label {
      margin-bottom: 10px !important;
    }

  }

  .bz-affiliate-payout-grid {

    .k-pager-wrap.k-pager,
    .k-pager-sizes.k-label,
    .k-pager-info.k-label {
      display: block;
      text-align: center;
    }

    .k-pager.k-grid-pager .k-state-disabled.k-link,
    .k-pager-numbers-wrap {
      display: inline-block;
    }

    .k-pager-sizes.k-label {
      margin: 15px 0 !important;
    }

    .k-pager-info.k-label {
      margin-bottom: 10px !important;
    }
  }

  .includeactive {
    display: block;
    padding-left: 0 !important;
    margin: 5px 0;
  }
}

@media (max-width: 576px) {
  .affliates .k-menu-item {
    width: 50%;
    margin: 0;
  }


  .bz-classes-layout .searchinput input[type="search"] {
    width: 100% !important;
  }

  .adminaffialteheader .addpromotions {
    text-align: right;
  }

  .adminaffialteheader .blockserachaffilates .addpromotions {
    text-align: left;
  }

  .md-mb-affialete,
  .md-mb-affialete-search {
    margin-bottom: 15px;
  }

  .addpromotions button.k-button.k-primary {
    margin: 0;
  }

}

@media (max-width: 340px) {
  .paadingpayouts {
    padding: 0;
  }
}

@media (max-width: 310px) {
  .affliates .k-menu-item {
    width: 100%;
  }
}

@media (max-width: 480px) {

  .payment-card-input-new>.payment-card-input-blk {
    min-width: auto;
  }

  .managempromotions {
    display: block !important;

    .affiliatemainheading {
      margin-bottom: 5px !important;
    }

    .includeactive {
      padding-left: 0 !important;
    }

    .desaffiliates {
      padding: 5px 0;
    }

  }

  .adminaffialteheader {
    margin-top: 10px;
  }

  .menulinksaffilates {
    font-size: 12px;
  }

  .appttypesearchclient {
    display: block !important;
  }

  .bz-purchase-class-filter .k-dropdown {
    width: 100% !important;
  }
}

.promoterforminput {
  font-size: 14px !important;
}

.manage-affiliate-container .bzc-grid .k-grid-content td {
  font-size: 14px;
}

tr.k-grid-norecords {
  td {
    text-transform: capitalize;
  }
}

tr {
  td.highlight-payout {
    font-weight: 600;
  }
}

.k-datetime-wrap {
  .k-calendar-infinite .k-calendar-view {
    height: 230px;
  }

  button.k-button.k-calendar-title.k-flat {
    background: #fff;
    color: #008DB9;
  }

  .k-calendar-infinite .k-calendar-header {
    padding: 0;
  }

  .k-calendar-th {
    width: 32px;
    height: 32px;
    font-size: 0.75rem;
    line-height: 1;
  }

  .k-calendar-td {
    width: 30px;
    height: 30px;
    font-size: 0.75rem;
    padding: 0rem;
  }

  .k-time-part {
    font-size: 0.75rem;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 0 5px;
  }

  .k-datetime-footer.k-actions.k-hstack.k-justify-content-stretch {
    margin-top: 0px;
    padding-bottom: 5px;
  }

  .k-datetime-container .k-datetime-buttongroup {
    padding-top: 0.1rem;
  }
}

.bz-selected-appt-quick-check {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
  padding-right: 15px;

  .card.bz-shadow.bz-upcoming-apponments-sm-card {
    width: 250px;
    min-height: 60px;
  }

  .card.bz-shadow.bz-upcoming-apponments-sm-card.quickcheckout {
    width: 96%;
    min-height: 75px;
    margin: 0 2%;
    cursor: pointer;
  }

  .bz-border-blue {
    border-color: #008db9 !important;
  }

  .bz-border-grey {
    border-color: grey !important;
  }


}

@media screen and (max-height: 670px) {

  .addaffilatespromotions .k-window-content.k-dialog-content {

    height: 300px;
    overflow: scroll;
  }
}


@media (max-width: 400px) {

  .d-blockmanagepromotions {
    display: block !important;
  }

  .d-blockmanagepromotions .badge {
    margin: 0;
    margin-top: 5px;
  }
}

.manage-affiliate-container-grid.payout .bzc-grid.payouts .k-grid-content td.negative-value {
  padding: 10px;
}

@media (max-width: 480px) {
  .mobilestatusopps {
    display: block !important;
    text-align: center;

    p {
      text-align: center !important;
    }
  }

  .buttonmobilesttusopps {

    margin: auto;

    button {
      margin-top: 10px;
    }
  }

  .fc .fc-timeGridWeek-view .fc-col-header-cell {
    padding: 0;
  }

  .linkdescriptions .d-flex.linkbg {
    display: block !important;
  }
}


.bz-enrolled-btn {
  border-color: grey;
  background-color: grey;
  color: #fff !important;
  border: 1px solid #fff !important;
  letter-spacing: 1.73px;
  padding: 0.25rem 1.15rem !important;
  border-radius: 0.4rem !important;
}

.payouts.k-grid tbody .k-hierarchy-cell {
  display: none;
}

.rewardname::before {
  content: ":";
  font-weight: 500;
  font-size: 13px;
  padding-right: 3px;
  margin-left: -12px;
  width: 12px;
  display: inline-block;
}

.custom-service-item {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 0.75rem;
  width: 75%;
  color: #008db9;
}

div.btn.btn-link.modify-custom-service {
  font-size: 0.675rem;
}

.group-short-code-edit-input {
  .k-form-field {
    margin-top: 0px;
  }
}


.k-dialog-wrapper.add-checkout-multiple-appt {
  top: -35px;
}

.bz-sms-renewal-dialog .k-dialog {
  max-width: 24rem;

  >.k-dialog-content {
    text-align: center;
  }
}

@media only screen and(max-width:850px) {
  .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
    position: unset;
    height: auto;
  }
}

.tooltipach div {
  display: inline-block;
}

// .k-calendar-tbody .k-calendar-tr:last-child {
//   display: none !important;
// }

.manage-declines-sections h6 {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
}

.manage-declines-sections h6:last-child {
  margin-bottom: 10px;
}

.manage-declines-sections h6.headingaudit {
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: 0;
  color: #8f9bb3 !important;
  display: block;
  margin-bottom: 0;
}

.audittable-list table th,
.audittable-list table td {
  font-size: 14px;
}

.clientautocomplete.k-autocomplete {
  font-size: 14px !important;
  border: 1px solid #dae1ed;

}

.clientautocomplete input[type="text"],
.addclient input[type="text"] {
  font-size: 14px !important;
}

.calendar-section-heading h5,
.selected-slot-header h5,
.bz-selected-date,
.checkoutheading {
  font-size: 13px;
  font-weight: 600;
  color: #526484;
}

.timesoltsmain,
.bz-selected-appoinment-container {
  max-height: 450px;
  overflow-y: auto;
}

.selected-slot-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.hrbreak {
  margin: 1rem 0;
  color: #b7c2d0;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}

.buzopsraido input[type=radio] {
  display: none;
}

.buzopsraido input[type=radio]:checked~.buzopsbox {
  background-color: #059FCF;
  border: none;
}


.buzopsraido .buzopsbox {
  min-width: 100px;
  min-height: 32px;
  border: 1px solid #eee;
  transition: all 250ms ease;
  will-change: transition;
  display: block;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background: #fff;
  line-height: 32px;
}

.buzopsraido .buzopsbox span {
  transform: translate(0, -2px);
  left: 0;
  right: 0;
  transition: all 300ms ease;
  font-size: 12px;
  font-weight: 600 !important;
  user-select: none;
  color: #007e90;
  margin-left: 0 !important;
}





.buzopsraido input[type=radio]:checked~.buzopsbox span {
  color: white;
  transform: translateY(-2px);
}

.buzopsraido .k-radio-label {
  display: none;
}

.buzopsraido .form-check {
  padding: 0;
}


.addclientpopup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -300px;
  width: 300px;
  background-color: white;
  transition: left 0.3s ease;
  background: #ffffff;
  padding: 10px;
  z-index: 101;
}

.addclientpopup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  opacity: .5;
  background-color: #000;
  display: none;
}


.popup-visible-back {
  display: block;
}

.popup-visible {
  left: 0;
}

.font-appoitnments {
  font-size: 15px;
}

.multicalendarbuzops .k-calendar-title {
  font-weight: 600 !important;
}

.multicalendarbuzops .k-calendar {
  border-radius: 8px;
}

.groupappadmin .bzc-sessions-list-card-new {
  border: 1px solid #e7e2e2;
  border-radius: 5px;
  margin-right: 5px;
}


.groupdashboardpopup .groupclasslistadmin {
  height: calc(85vh - 20px);
  overflow: auto;
  padding-bottom: 40px;
}

b,
strong {
  font-weight: 600 !important;
}

.groupappadmin .bz-checkout-page .checkout-panelbar-wrapper {
  border: 0 !important;
  box-shadow: none !important;

}

.groupappadmin .bzc-sessions-list-card-new.bz-classes-checkout {
  border: 0 !important;
  box-shadow: none !important
}

.groupappadmin .payement-panel-header .k-panelbar-item-text {
  font-size: 14px;
}

.packagestabslist {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  flex-wrap: nowrap;
}

.packagestabslist li {
  width: 50%;
}

.packagestabslist li button.nav-link {
  width: 100%;
}

.calendarlist .k-button.k-button-icon {
  width: auto;
  min-width: auto;
}

.multicalendarbuzops .k-calendar {
  border-radius: 8px;
}


.schduleappointment .bz-checkout-page .checkout-panelbar-wrapper {

  border: 0px !important;
  box-shadow: none;
  padding: 0;

  label {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.groupappadmin {}

.schduleappointment .payement-panel-header .k-panelbar-item-text,
.schduleappointment .payment-txt,
.groupappadmin .payement-panel-header .k-panelbar-item-text,
.groupappadmin .payment-txt {
  font-weight: 500 !important;
  font-size: 14px;
}

.schduleappointment .payment-panel-form {
  padding: 0 25px 0 15px !important;
}

.schduleappointment .k-form,
.groupappadmin .k-form {
  padding: 0 20px !important;
}

.bz-step-type {
  background-color: #f1f5fc !important;
  color: #008db9;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px 5px 5px;
  align-self: center;

  &.stepLabel {
    min-width: 60px;
  }
}


.quickcheckout-payment-profile .payment-profile.d-flex {

  display: block !important;

}

.quickcheckout-payment-profile .payment-profile .addNewBtn {
  margin-left: 0;
  margin-top: 0.5rem;
}

.quickcheckout-selectedsolts {
  min-width: 280px;
  // max-width: 280px;
}

.quickcheckout-selectedsolts .k-dropdown {
  width: 100% !important;
}

.quickcheckout-selectedsolts .bz-selected-appt-quick-check .card.bz-shadow.bz-upcoming-apponments-sm-card {
  width: 100%;
  min-width: 250px;
}

.stepsappointments .bz-step-type {
  background: #eff3ff;
  width: 35px;
  height: 35px;
  line-height: 25px;
  border-radius: 50%;
  color: #05a0d0;
  text-align: center;
}

.buz-online-list-view.stepsappointments {
  background-color: #fff !important;
  border-radius: 10px;
}

.stepsappointments .stepLabel {
  background: #eff3ff;
  border-radius: 50px;
  line-height: 35px;
  height: 30px;
  min-width: 110px;
  text-transform: capitalize;
  font-size: 13px;
  text-align: center;
}

.apptsschedule .form-group .k-input {
  min-height: 32px;
  height: 2.6vh;
}


.fixed-calendarheader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  top: 0;
  padding: 0 10px;
}


.buzops-fullcalendar .fc .fc-toolbar.fc-header-toolbar {
  // margin-top: 60px;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  padding: 60px 10px 15px 10px;
}

.buzops-fullcalendar .fc .fc-view-harness-active>.fc-view {
  top: 115px
}

.buzops-fullcalendar ::-webkit-scrollbar {
  width: 0;
}

.buzops-fullcalendar .fc .fc-list-event-dot {
  display: none;
}

.nav-tabs.packagestabslist .nav-link.active {
  background-color: transparent !important;
  border-color: #008db9 !important;
  color: #008db9 !important;

  border: 0px solid #008db9 !important;
  border-bottom-width: 2px !important;

}

.nav-tabs.packagestabslist .nav-link:focus,
input:focus,
.k-input:focus {
  outline: 0;
}

.nav-tabs.packagestabslist .nav-link {

  background-color: transparent !important;
  border: 0px solid #008db9 !important;
  border-bottom: 2px solid transparent !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  display: inline-block !important;
  padding: 7px 8px !important;
  font-size: 0.78rem !important;
  font-weight: 600;
}

.tabbed-select-dropdown .nav-tabs.packagestabslist {
  background: #fff !important;
  -webkit-box-shadow: 0px 16px 10px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 16px 10px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 16px 10px -20px rgba(0, 0, 0, 0.75);
  padding-bottom: 1px;
}

.bzo-status-consultation {
  font-weight: 600;
  color: #1e1f21;

}

.bzo-status-Complimentary {
  font-weight: 600;
  color: #1e1f21;
}

.fc .fc-timegrid-axis-cushion::first-letter {
  text-transform: uppercase;
}

.buzops-fullcalendar .fc .fc-timegrid-event .fc-event-main {
  overflow: hidden;
}

.buzops-fullcalendar .fc-timegrid-event .fc-event-time,
.buzops-fullcalendar .fc-timegrid-event-short .fc-event-title,
.buzops-fullcalendar .fc-v-event .fc-event-title {
  font-size: 14px;
}


.success-component {
  background: #fcfcfc;
  padding: 3%;
  height: auto;
}

.success-component .success-info.display-in-calendar {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.success-component .success-info {
  padding: 0px;
  box-shadow: none;
}

.success-component .success-info .card-content {
  background: #fff;
  padding: 1.5rem;
}

.success-component .success-info .card-content .bzo-appoinmentBooked-grid {
  align-self: start;
  width: 100%;
}

.success-component .success-info .card-content .bzo-appoinmentBooked-grid .k-grid-container>.k-grid-content .k-grid-table {
  width: 100% !important;

}

.success-component .k-grid-header-wrap>table {
  width: 100% !important;
}

.my-bookings_booking-body_scheduler_label_desc :nth-child(2) {
  text-align: left;
}

.fc-list-event-title .fc-event.fc-unavailable {

  // background: #e5e7eb !important;
  // color: #4b5563 !important;
}

.fc-event-main .fc-event.fc-unavailable {
  // background: #e5e7eb !important;
  // color: #4b5563 !important;

}

.bz-checkout-page .order-summary-form .order-summary-card.bordernone {

  border: none !important;
}

.col-md-9.scheduleappointment+.row {
  margin-bottom: 0;
}

.k-calendar-range {
  width: 100%;
}

.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-yearview .k-calendar-td.k-today>.k-link::before,
.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-decadeview .k-calendar-td.k-today>.k-link::before {
  top: 3rem;
}

.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-yearview .k-calendar-td>.k-link,
.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-decadeview .k-calendar-td>.k-link {
  width: 46.5px;
  height: 46.5px;
}

.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-centuryview .k-link {
  width: 46.5px !important;
  height: 46.5px !important;
}

.k-calendar .k-calendar-view table,
.k-calendar .k-calendar-view.k-calendar-yearview .k-calendar-table,
.k-calendar .k-calendar-view table,
.k-calendar .k-calendar-view.k-calendar-decadeview .k-calendar-table {
  border-spacing: 10px
}

.k-calendar .k-calendar-view.k-calendar-centuryview .k-calendar-table {
  border-spacing: 10px;
}

.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-centuryview .k-calendar-td.k-today>.k-link::before {
  top: 3rem
}

.reccurencebtn .k-button {

  margin: 0;
  margin-top: 19px;
}

.apptsschedule .k-numerictextbox {
  width: auto;
}

.bz-calender-sec>.k-calendar>.k-calendar-view.k-calendar-centuryview {

  min-height: none
}

.k-calendar-infinite .k-calendar-view {
  width: auto;
  max-width: 300px;
}

.k-calendar-monthview .k-calendar-td,
.k-calendar-monthview .k-link {
  width: 42px;
  height: 42px;
}

.bz-dropdown-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  >.bz-schedule-btn {
    min-width: auto !important;

    >.k-button {
      margin-right: 0 !important;
    }
  }
}

.bz-trainers-card.bz-trainer-selected {
  background: #DEF7FF;
}

// .ScheduleAppointmentRightCard {
//   .timesoltsmain,
//   .bz-selected-appoinment-container {
//     max-height: 170px;
//     overflow-y: auto;
//   }
// }
.cancelrecurrenceappts {
  width: 100%;
  justify-content: start;
  gap: 10px;
  padding: 10px;
  background: #fafafa !important;
  margin-bottom: 14px;
  margin-top: -10px;
  border-radius: 5px;
}

.cancelappttimedate {
  padding: 10px;
  border: 1px solid #eee;
  text-align: center;
  border-radius: 3px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}

.cancelappttimedate .time {
  font-size: 12px;

  i {
    color: #000 !important
  }

}

.recurrencceappt {
  display: block;


}

.bz-cancellation-page .bz-cancellation-card-row.clientdetials {
  align-items: start;
}

.bz-pkg-dropdown {
  .k-dropdown-wrap {
    .k-input {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-align: left;
      padding-right: 1.4rem;
    }
  }
}

.pricesolt.bzc-input-group>.k-form-field {
  width: 100% !important;

}

.martn5 {
  margin-top: -5px;
}

.timesoltsadd {
  flex-grow: 1;
}

.buzops-window-title.fullcalendar img {
  border-radius: 5px;
  cursor: pointer;
  width: 25px !important;
}

.buzops-window-title.fullcalendar {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
  color: #172a3f;
  padding-top: 10px;
}

.availbalitystaffdropdown {
  position: sticky;
  top: 5.5rem;
  left: 330px;
  z-index: 999;
}

.bz-classes-layout .availbalitystaffdropdown .k-dropdown .k-dropdown-wrap {
  height: 30px;
}


.popudropdown {
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, .15);
  position: absolute;
  background: #fff;
  padding: 10px;
  width: 100%;
  max-width: 250px;
  right: 0;
  z-index: 99999;
  top: 50px
}

.btnavailabitlyclone {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
}

.btnavailabitlyclone .cancelclone,
.btnavailabitlyclone .applyclone {
  width: 100%;
}

.copy-time-slots-popup h6 {
  font-size: 14px;
  padding-bottom: 10px;
}

.border-client {
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 10px;
  max-width: 350px !important;

}

.ftclient {
  font-size: 13px;
  font-weight: 600;
}

.appointmentheader {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.scheduleappts *,
.purchasesummaryappts *,
.scheduledapptsheader *,
.packagelistview *,
.bzo-services-container * {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;

  .k-icon {
    font-family: "WebComponentsIcons" !important;
  }
}

.appointmentheader h5,
.selectprovider h5,
.providernameexisting {
  font-size: 12px;
  font-weight: 500;
}

.selectprovider .bz-trainers-card,
.existingslots {
  min-height: auto;
  max-height: none;
  border-radius: 5px;
  border: 1px solid #e7e2e2;
  box-shadow: none;
}

.selectprovider .bz-trainers-card.bz-trainer-selected {
  background: #f3fbfe;
  border-color: #008db9;
}

.appointmentlistview .card,
.packagelistview {
  border: 1px solid #e7e2e2;
  border-radius: 5px;

  h5,
  .primary-heading {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.selectprovider .bz-trainers-card .media-body h5 {
  font-size: 12px !important;

}

.selectprovider .bz-arrow-icon {
  font-size: 1rem;
  color: #000
}

.packagelistview {

  .package-schedules,
  .package-expiry {
    font-size: 12px;
    font-weight: 400;
  }

}

.packageavailable {
  color: green;
  font-size: 12px;
  margin-bottom: 5px;

}

.typeapptslocation {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.rateappts {
  font-weight: 700;
}

.appointmentlistview .location-icon-blk .align-baseline {
  margin-left: 0 !important;
}

.scheduletabs.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
  padding: 7px 0 !important;
  margin-right: 25px !important;
  font-weight: 600;
  font-size: 14px;
}

.appttitle {
  font-weight: 600;
}

.apptinfoLeft {
  max-width: calc(100% - 4rem);

  .appttitle {
    display: inline-block;
    width: 100%;

    .tax-name-clip {
      max-width: 96%;
    }

    >div {
      display: inline-flex;
      max-width: 100%;
    }
  }
}

.bz-upcoming-apponments-sm-card img.bz-user-img.w-65 {
  width: 45px;
}

.scheduleappts .flex-grow {
  flex-grow: 1;
}

.searchboxclient {
  width: 100%;
  margin: auto;
  padding-top: 10%;
  max-width: 600px;
}

.searchboxclientgroup {
  width: 100%;
  margin: auto;
  padding-top: 10%;
  max-width: 400px;
}

.searchboxclient.padded {
  padding-top: 1%;
}

.autocomplete-wrapper {
  border: 1px solid #dae1ed;
  padding: 0px;
  width: 100% !important;
  border-radius: 0.25rem;
  max-width: 360px;

}

.bz-selectClient-dialog .autocomplete-wrapper {
  width: 100% !important;
}

.autocomplete-wrapper .clientautocomplete.k-autocomplete {
  font-size: 13px !important;
  border: 0px solid #dae1ed;
  margin-bottom: 0 !important;
  outline: 0 !important;
  box-shadow: none;
  max-width: 400px !important;
  width: 100% !important;
  min-width: 350px !important;
}

.autocomplete-wrapper .clientautocomplete.k-autocomplete::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding-right: 8px;
  margin-left: 8px;
  padding: 10px 0;

}

.autocomplete-wrapper .clientautocomplete input[type="text"] {
  min-height: 40px;
}

.btn-sm-addnewclient {
  padding: 5px !important;
  width: 98%;
  background: none !important;
  color: #008DB9 !important;
  margin: 0;
  text-align: left !important;
  justify-content: start !important;
  border: none;
  font-weight: 600 !important;
}

.addnewclientdailog.k-dialog-wrapper .k-dialog {
  width: 100%;
  max-width: 350px;

  .k-maskedtextbox {
    border-width: 0px;
  }
}

.clientborder {
  border-radius: 7px;
}

.k-button.bnt-radiusaddpackage {
  border-radius: 50px !important;
  padding: 5px 10px !important;
  min-height: auto !important;
}

.k-button.bnt-radiusaddpackage.noaddpck {
  background: none !important;
  color: #008DB9 !important
}

.maxwithpackage {
  min-width: 120px;
}

.purchasesummaryappts .bz-checkout-page .checkout-panelbar-wrapper {
  border-width: 0 !important;

  box-shadow: none !important;
  height: 80vh;
  padding: 0 10px;
  overflow-y: auto;

  .row {
    margin: 0 !important;
  }

  .btn-icon svg {
    font-size: 1rem;
    height: 1rem;
    color: #6a7174 !important
  }
}

.purchasesummaryappts .adminschedule .payment-panel-form {
  width: 55%;
  margin-right: 5%;

  .order-summary-class-details {
    color: #000;
    font-weight: 600;
  }

  .order-summary-class-name {
    font-size: 13px;

    .d-sm-inline {
      display: block !important;
      color: #008db9
    }


  }


  .bz-packages-tags-new .k-chip {
    border-width: 0;
    color: #000;
    font-weight: 600;
    display: block;
  }

  .bz-packages-tags-new .k-chip .k-chip-label::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 8px;
    padding: 10px 0;
    padding-right: 8px;

  }


  .k-chip-list {
    display: block;
  }

  .bz-packages-tags-new .k-chip>.k-chip-content {
    margin: 0;
  }

  .bz-packages-tags-new .k-chip-list {
    margin-bottom: 10px;
  }
}

.purchasesummaryappts .bz-checkout-page .order-summary-form .k-card>.k-card-header {
  padding: 0rem 0 1rem;

  h6 {
    font-weight: 600;
  }
}

.purchasesummaryappts .checkout-panelbar-wrapper label {
  font-weight: 400;
  margin-bottom: 0px !important;
  font-size: 12px;
  min-height: 21px;

}

.purchasesummaryappts .checkout-panelbar-wrapper label.k-radio-label {
  min-height: auto;
}

.purchasesummaryappts .adminschedule .order-summary-form {
  width: 40%;
  border-width: 0 !important;

  .bz-salesPerson-select label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  .ft13 {
    font-size: 13px;
  }

  .couponsordersummary .k-searchbar .k-input {
    font-size: 14px !important;
  }

}



.packagescheduleappts .bz_fs_1_5 {
  font-size: 16px;
  font-weight: 600;
}

.packagedetails,
.packagedetails h6 {
  font-size: 12px;
}

.purchasesummaryappts .bz-checkout-page .adminschedule .payment-panel-form .k-panelbar {

  border-width: 0px !important;
  box-shadow: none;

  .k-link {
    padding: 10px 0 !important;
  }

  .k-link.k-state-selected {
    background: transparent !important;
    color: #008db9;
  }

  .k-group {
    padding-top: 0px !important;
    margin-top: 10px;
  }

  .pl-24 {
    padding-left: 5px !important;
  }

  .k-panelbar-content.k-content .px-4 {
    padding: 0 !important;
  }

  .k-widget.k-numerictextbox {
    max-height: 33px;

    .k-input.k-formatted-value {
      font-size: 13px !important;
    }

  }

}

.purchasesummaryappts .bz-checkout-page .adminschedule .payment-panel-form {

  .k-form-hint.k-text-start {
    display: none;
  }

  .k-input {
    font-size: 13px !important;
  }
}

.purchasepackageclient .bz-provider {
  font-size: 12px;
}

.ScheduleAppointmentRightCard .bz-calender-sec>.k-calendar>.k-calendar-view,
.ScheduleAppointmentRightCard .bz-timezone-dropdown {
  margin: 0;
  display: block;
}

.ScheduleAppointmentRightCard .k-calendar-header,
.ScheduleAppointmentRightCard .bz-timezone-dropdown {
  max-width: 364px;
}

.multislotsmain .slotselection .slotsbookings {
  width: 46%;
  float: left;
  margin: 0 2%;
  min-height: 40px;
}

.singlesoltsmain .slotselection .slotsbookings {
  width: 100%;
}

.singlesoltsmain .slotselection .slotsbookings,
.multislotsmain .slotselection .slotsbookings {
  color: #008db9;
  font-weight: 600;
  border-radius: 5px;
}

.singlesoltsmain .slotselection .slotsbookings .bz-time-card.active,
.multislotsmain .slotselection .slotsbookings .bz-time-card.active {
  font-weight: 600;
  border-radius: 5px;
}


.btnloading.fa {
  font-family: "Font Awesome 5 Free" !important;

}

.ScheduleAppointmentRightCard .bz-selected-appoinment-container .bz-border-blue {
  border-color: #a4adb0 !important;
  border-radius: 5px
}

.col-md-7.selectedslotscheckout .three-panel {
  width: 90%;
  padding-right: 5%;
  padding-left: 5%;
}

.bzo-class-window .ordersummaryappts .k-card>.k-card-header,
.ordersummaryappts .k-card>.k-card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .card-heading {
    font-weight: 600;
    padding: 0rem 0 5px;
  }
}

.couponsordersummary .k-searchbar .k-input {
  font-size: 14px !important;
}

// .popoverstaffirst{
//   top:5px !important;

// }


.startdate-group-popup,
.enddate-group-popup {

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {

    border-spacing: 0;
  }

  .k-calendar-monthview .k-calendar-td,
  .k-calendar-monthview .k-link {
    width: 36px;
    height: 36px;
  }
}

// .enddate-group-popup {
//   left: auto !important;
//   right: 15px ;}

.eventsborder {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

.eventsdatecalendarlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

.eventsdatecalendarlist .btncalendar {
  margin-left: 0px;
}

.k-calendar-monthview .k-calendar-td,
.k-calendar-monthview .k-link {
  width: 42px;
  height: 42px;
}


.bz-classList-container.k-card>.k-card-body {
  .k-listview {
    .bz-forms-list-new {
      box-shadow: 0px 3px 8px #00000029 !important;
      border: 1px solid #9f9f9f !important;
      border-radius: 7px !important;
      opacity: 1;
      // font-family: Helvetica;
      display: grid;
      // grid-template-columns: 2fr 2fr 3fr 7rem 4rem 4rem 4rem;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr auto;
      align-items: center;
      justify-content: center;
      max-height: 120px;
      background-color: #fff !important;
      padding: .8rem .5rem !important;

      .section-1 {
        .section-1-items {
          display: flex;
          flex-direction: column;
        }
      }

      .bz-updated-on {
        letter-spacing: 0px;
        color: #272727;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .bz-saved-forms-list-new {
      box-shadow: 0px 3px 8px #00000029 !important;
      border: 1px solid #9f9f9f !important;
      border-radius: 7px !important;
      opacity: 1;
      // font-family: Helvetica;
      display: grid;
      // grid-template-columns: 2fr 2fr 3fr 7rem 4rem 4rem 4rem;
      grid-template-columns: 2fr 2fr 2fr 1fr auto;
      align-items: center;
      justify-content: center;
      max-height: 120px;
      background-color: #fff !important;
      padding: .8rem .5rem !important;

      .section-1 {
        .section-1-items {
          display: flex;
          flex-direction: column;
        }
      }

      .bz-updated-on {
        letter-spacing: 0px;
        color: #272727;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .bz-saved-forms-referral {
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr auto;
    }
  }
}

.VersionsTree {
  z-index: 100 !important;
}

.addnewbtnforms.k-button.k-primary:hover {
  color: #fff !important;
}

.bzo-class-window .k-button.btncalendar {
  background: #fff;
  color: #000 !important
}

.opcal {
  opacity: 0.6;
}

.eventcalendarbz {
  top: 100px !important;

  left: 35% !important;
}


.oneononepresetdropdown {
  width: 220px !important;
}

.oneononepackagelistview .k-listview-content .packagelistitem:last-child {
  margin-bottom: 110px;
}

.k-card-titlepurchase {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0rem;
  text-transform: capitalize;
  color: #172a3f;
}

.group-event-flex .buttonsort .k-button {
  padding: 0 !important;
  min-width: 45px;
}

.apptsservices {
  min-width: 9.45rem;
}

.serviceListclass {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}


.timedatepickerdiv {

  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 25px;
  align-items: start;
}

.timepickerday {
  margin-bottom: 10px;
  justify-content: space-between;
  gap: 10px;
  min-width: 70px;
}

.scheduledapptsheader .calendartabsappts {
  position: fixed;
  background-color: #fff;
  width: 100%;
  display: block !important;
  z-index: 99;
  top: 45px;
  padding-top: 0px;
  left: 0;
}

.scheduledapptsheader .calendartabsappts.clientdashboardheaderqlinks {
  top: 12px;
}

.scheduledapptsheader .scheduleappts {
  padding-top: 40px;
}

.bz-classes-tag-card-container+div {
  width: 100%;
}

.k-window-content .scheduledapptsheader .scheduleappts {
  padding-top: 50px;
}

.ScheduleAppointmentRightCard .client-creation-page .form-group {

  margin-bottom: 0px
}

.ScheduleAppointmentRightCard .client-creation-page .form-group:last-child {

  margin-bottom: 1rem
}


.ScheduleAppointmentRightCard .client-creation-page .form-group .k-maskedtextbox {
  border-color: transparent;
}


.ScheduleAppointmentRightCard .client-creation-page .form-group .k-label {
  font-weight: 600 !important;
  color: #526484;
}

.ScheduleAppointmentRightCard .client-creation-page h4 {
  font-size: 14px;
  font-weight: 600;
  color: #526484;
  padding: 0 5px;
}

.filterpackages,
.k-button.k-primary.filterpackages {
  background-color: transparent !important;
  color: #5a5c5f !important;
  border-color: #e4e4e4 !important;
  padding: 10px !important;

}

.filterpackages {
  min-height: 36px;
}

.k-button.k-primary.filterpackages {
  min-width: auto;
  margin: 0;

  .k-icon {
    font-size: 14px;
  }

  .labelname {
    display: none;
  }
}


.bz-classes-search.searchclientpackages .k-autocomplete {
  height: 34px;
}

.bz-classes-action .bz-classes-search .fa-search.searchicons {
  font-size: 16px;
  color: #aaa;
}

.oneononepresetdropdown .k-list li:nth-child(4) {
  border-bottom: 1px solid #e9ecef !important;
}

.adminschedule .credit-card-input-form input[type="tel"] {
  font-size: 14px !important;
}

.appointmentlistview {
  height: 100%;
  max-height: calc(76vh - 32px);
  overflow-y: auto;
  padding-right: 15px;
}

.bz-groupServices-inhouse.headerpreset .k-card-header {
  overflow: visible;
}

.h80vh {
  height: 80vh;
}

.adminschedule .input-group-append.hide-no-labels {
  margin-left: 10px;
}

.adminschedule .pymentprfpadding {
  padding: 0 12px;
  margin-top: 0;
}

.adminschedule .k-radio-list.k-list-horizontal {
  gap: 7px;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-state-focus {
  box-shadow: none;
}

.order-summary-card .k-card-body {
  padding: 0;
}

.k-popup.k-child-animation-container .k-list .k-item[role=option] span {
  font-size: 14px !important;
}

.order-summary-card .k-card-header {
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  color: #172a3f;
}

.checkiconpayouts svg {
  color: #ede7e7;
  position: absolute;
  right: 10px;
  background: #b0b4b0;
  border-radius: 15px;
  font-size: 16px;
  width: 20px;
  height: 20px;
}

.selected-reward .checkiconpayouts svg {
  color: #fff;
  background: green;

}

.buzops-fullcalendar .weeklyhrs-cont {
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
}

.buzops-fullcalendar .weeklyhrs-cont::-webkit-scrollbar {
  width: .4rem;
}

.bz-schedule-link-btn2.packageoverveiw .k-button {
  background: #008db9;
  color: #fff;
  padding: .5rem !important;
}

.bz-schedule-link-btn2.packageoverveiw .svg-inline--fa,
.bz-schedule-link-btn2.packageoverveiw .k-button:hover>.svg-inline--fa {
  color: #fff
}

.bzo-services-container.audithistoryclient .k-listview-content {
  height: auto;
}

.pt10 {
  padding-top: 10px;
}

.bz-checkout-page .order-summary-form.groupordersummary .order-summary-card {
  border-width: 0 !important
}

.bz-checkout-page .order-summary-form.groupordersummary {

  .k-card-header {
    padding: 0 0 5px;
  }

  .k-card>.k-card-header .card-heading {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 13px !important;
  }

  .order-summary-card .k-card-body p {
    font-size: 13px !important;
    font-weight: 600;
  }
}

@media screen and (min-width: 1200px) {
  .addpackagesdatepickerEndate.limited-blk {
    left: auto !important;
    right: 20px
  }

}

@media screen and (min-width: 575px) and (max-width: 991px) {
  .addpackagesdatepickerEndate.limited-blk {
    left: auto !important;
    right: 20px
  }
}

@media (min-width: 992px) and (max-width: 1190px) {
  .addpackagesdatepickerEndate.UnLimited-blk {
    left: auto !important;
    right: 20px
  }

}

.FieldTemplateItems .svg-inline--fa.fa-phone {
  transform: scaleX(-1);
}

.payoutsmultiselect.k-multiselect.k-state-focused>.k-multiselect-wrap,
.payoutsmultiselect.k-multiselect>.k-multiselect-wrap {
  height: 34px;
}

.k-dialog-wrapper.payoutdailogbox .k-dialog {

  width: 100% !important;
  max-width: 600px;
}

.bz-affiliate-content .bz-date-filter-calendar.calendaraffialte .bz-dateFilter-container .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td {
  width: 32px;
  height: 32px;
}

.bz-affiliate-content .bz-date-filter-calendar.calendaraffialte .k-calendar .k-calendar-view table,
.k-calendar .k-calendar-view .k-calendar-table {
  border-spacing: 0px;
}

.dynamic-options-actions-buttons.fieldoptionbtns {
  display: flex;
}

.dynamic-options-actions-buttons.fieldoptionbtns .svg-inline--fa.fa-w-14 {
  width: 14px;
  height: 12px;
  vertical-align: 0;
}

.bz-affiliate-content .bz-content-text-row .bz-content-body {
  max-width: 475px;
}

.section-Description .k-checkbox {
  width: 16px;
  height: 16px;
}

.bz-updated-on.formlistheading {
  font-size: 13px;
  color: #607d8b;
  font-weight: 600 !important;
}

.rewardsname {
  font-weight: 600;
  font-size: 14px;
}

.rewards .bz-forms-list-new.formlist {
  grid-template-columns: 1fr;
  border-color: #eaeaea !important;
  box-shadow: none !important;
}

.rewards {
  margin-bottom: 20px;
  padding: 0;
}

.rewards .bz-forms-list-new {
  box-shadow: 0px 3px 8px #00000029 !important;
  border: 1px solid #9f9f9f !important;
  border-radius: 7px !important;
  opacity: 1;
  display: flex;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff !important;
  padding: .8rem .5rem !important;
  margin: 0 12px;
}

.groupappadmin .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-left-side-card {
  flex-grow: 1;
  flex-shrink: initial;
  flex-basis: auto;
  width: calc(100% - 280px);
}

.groupappadmin .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
  flex: 0 0 240px;
}

.groupappadmin .bz-purchase-class-filter .k-dropdown,
.groupappadmin .bz-purchase-class-filter .k-datepicker {
  width: 100%;
  min-height: 35px;
}

.groupappadmin .bzc-purchase-class-list-view .bzc-purchase-class-list-row {
  gap: 25px;
}

.groupappadmin .bzc-left-side-card.bz-groupservices-left-side-card .bz-purchase-class-list .k-listview .k-listview-content,
.bzc-left-side-card.bz-groupservices-left-side-card .bz-purchase-class-list .k-listview .k-listview-content {
  height: 70vh;
}

.groupappadmin input[type="text"] {
  font-size: 14px !important;
}

.pdx14 {
  padding: 0 16px;
}

.purshasenotifcation strong {
  font-size: 14px;
  font-weight: 600;
}

.scheduledapptsheader .k-tabstrip>.k-content {
  padding: 0;
}

.addpromotionsdiv .k-widget.k-calendar.k-calendar-infinite {
  border-radius: .3rem;
  position: relative;
  top: .25rem;
  display: flex;
  z-index: 9999;
  max-width: 480px;

}

.addpromotionsdiv .k-calendar-th {
  width: 40px;
  height: 29px;
  font-size: .75rem;
  font-weight: 600;
  color: #0a0a0a;
  padding: 3px !important;
  line-height: 1.5;
  border: 0;

}

.addpromotionsdiv .k-calendar-view .k-calendar-td>.k-link,
.addpromotionsdiv .k-calendar .k-nav-today {
  font-size: .75rem;
}

.addpromotionsdiv .k-calendar-infinite .k-calendar-header {
  padding: 0 1rem;
}

.addpromotionsdiv .k-calendar-monthview .k-calendar-td,
.k-calendar-monthview .k-link {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1499px) {

  .addpromotionsdiv .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 0 8px;
  }

}

.fc-theme-standard th {
  border-top-right-radius: 5px;
}

.purchasesummaryappts.packagerelated .adminschedule .payment-panel-form {
  width: 59%;
  margin-right: 0;
  padding: 0;
}

.k-window-content .purchasesummaryappts.packagerelated .adminschedule .payment-panel-form {
  width: 58%;
}

.purchasesummaryappts.packagerelated .bz-checkout-page .checkout-panelbar-wrapper {

  height: auto;
  padding: 0px;
}

.k-window-content .purchasesummaryappts.packagerelated .bz-checkout-page .checkout-panelbar-wrapper {

  // height: 85vh;
  padding: 0px;
}

.purchasesummaryappts .adminschedule .order-summary-form {
  border-width: 1px !important;
  padding: 15px;
}

.k-window-content .purchasesummaryappts.packagerelated .bz-checkout-page .checkout-panelbar-wrapper {
  border-width: 0px !important;
}

.k-window-content .purchasesummaryappts .adminschedule .order-summary-form {
  border-width: 0px !important;
  padding: 0px;
}

.purchasesummaryappts.packagerelated .paddingpackage {
  padding: 0px !important;
}

.purchasepackebtn .buz-back-btn {
  min-height: 34px;
}

.purchasesummaryappts.packagerelated .adminschedule .payment-panel-form {
  .k-form .k-form-field {
    margin-top: 10px;
  }
}

.groupclasslistadmin .bz-filled-btn,
.groupclasslistadmin .buz-select-button,
.groupclasslistadmin .bz-enrolled-btn {
  min-width: 110px;
}

.bzc-right-side-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.bzc-right-side-card .bz-upcoming-apponments-sm-card .card-body {
  overflow: hidden;

}

.appointmentheader .bz-purchase-class-filter {
  .form-control-feedback {
    height: auto;
    line-height: normal;
    top: 7px;
    font-size: 16px;
  }
}

.appointmentheader .bz-purchase-class-filter {
  .k-dropdown {
    max-width: 156px;
    max-height: 31px;
    width: 100%;
    min-width: 156px;
    border-radius: 5px;
  }

  .k-dropdown:focus {
    box-shadow: none;
  }

  .form-group.bz-packages-search {
    max-width: 220px;

    .form-control-feedback {
      height: auto;
      line-height: normal;
      top: 7px;
      font-size: 16px;
    }

    .form-control {
      max-height: 31px;
      border-color: #e4e7eb;
    }

    .form-control:focus {
      box-shadow: none;
      border-color: #e4e7eb;
    }

  }

}

.payment-profile {
  .paymentdropdown {
    flex-grow: 1;
    max-width: 280px;
  }

}

.col-md-9.schduleappointment.successboxconfirmation {
  max-width: 99%;
  width: 100%;
}

.mh18p {
  min-height: 18px;
}

.payoutdropdown .k-multiselect .k-multiselect-wrap .k-clear-value {
  top: -5px
}

.k-button.ft16 {
  font-size: 16px !important;
}

.performactionbtn .k-button {
  min-width: auto;
}

.purchaseapptsquickslinks .scheduledapptsheader .calendartabsappts {
  top: 0;
}

.purchaseapptsquickslinks .scheduledapptsheader .scheduleappts {
  padding-top: 50px;
}


@media screen and (max-width: 767.98px) {
  .buttons-container-button {
    top: 215px;
  }

  .border-client {
    height: auto;
    width: 100%;
    max-width: 100% !important;
  }


  .reports-date-range {
    margin-bottom: 10px;
  }

  .checkout-panelbar-wrapper .col-sm-12 {
    padding: 0 !important;
  }

  .checkout-panelbar-wrapper .col-12.paddingpackage .row .col-12 {
    padding: 0 !important;
  }

  .purchasesummaryappts .adminschedule .order-summary-form {
    width: 100%;
  }

  .purchasesummaryappts.packagerelated .adminschedule .payment-panel-form {
    width: 100% !important;
  }

  .k-window-content .purchasesummaryappts.packagerelated .bz-checkout-page .checkout-panelbar-wrapper {
    height: auto;
  }


  .scheduletabs.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
    margin-right: 15px !important;
    font-size: 13px;
  }

  .k-tabstrip-items-wrapper {
    margin-left: 0 !important;
  }

  .calendartabsappts .k-tabstrip-items-wrapper {
    margin-left: 15px !important;
  }


  .k-window-content .scheduledapptsheader .scheduleappts {
    padding-top: 45px;
  }

  .bz-purchase-class-filter.justify-content-end {
    justify-content: flex-start !important;
  }

  .d-flex.providerblock {
    display: block !important;
  }

  .providerblock .calendar-main {
    margin-top: 25px;
  }

  .col-md-7.selectedslotscheckout .three-panel {
    margin-top: 15px;
    width: 100%;
    padding: 0%;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card.bz-groupservices-left-side-card {
    width: 100%;
  }

  .groupappadmin.purchasesummaryappts .bz-checkout-page .checkout-panelbar-wrapper {
    height: auto;
    padding: 0;
  }

  .packagelistoverview {
    height: auto !important;
  }

  .fc-license-message {
    display: none;
  }

  .appointmentlistview {
    max-height: none !important;
    padding-right: 0;
  }


  .calendarmaincont {
    margin-top: 25px !important;
    display: block !important;
  }

  .calendar-main {
    display: block;
  }

  .groupapptssettings .groupdropwnlis {
    margin-bottom: 10px !important;
  }

  .bz-selected-appt-quick-check {
    max-height: none;
    padding-right: 0;

    .row {
      margin: 0;
    }

    .col-md-6 {
      margin: 0;
      padding: 0;
    }
  }

  .selected-slot-footer {
    margin-bottom: 25px;
  }

  .clientpurchase {
    display: block !important;
  }

  .packeandclient {
    margin-bottom: 20px;
  }

  .scheduledapptsheader .calendartabsappts.clientdashboardheaderqlinks {
    top: 0;
    padding: 10px;
  }

  .rewards .bz-forms-list-new.formlist {
    grid-template-columns: 1fr 1fr;
  }

  .bz-forms-list-new.formlist.share-forms-listview {
    grid-template-columns: 1fr !important;
    display: flex !important;
    gap: 10px;
  }
}

.pointer {
  cursor: pointer;
}

.bz-directLink {
  word-break: break-all;
  white-space: normal;
}

.packageselection input[type="text"] {
  font-size: 14px !important;
}

.packageselection .k-dropdown-wrap,
.form-group.bz-packages-search.bzo-search-service .form-control {
  max-height: 34px;
}

@media screen and (max-width: 991px) {
  .bz-classes-action.d-flex.justify-content-between.group-event-flex {
    justify-content: start !important;
    margin-top: 10px;
  }

  .packagesfindandtypepackage {
    justify-content: space-between !important;
    width: 100%;

  }

  .bz-classes-searchicon {
    flex-grow: 1;
  }
}

.customeapptcalendar {
  // min-width: 220px;
}

@media screen and (max-width: 600px) {
  .overview-grid.bz-subscription-overview .bz-content-body {
    grid-template-columns: 1fr !important;
  }

  .overview-grid.bz-subscription-overview .bz-payment-options {
    max-width: 100% !important;
  }

  .appointmentstabs {
    display: block !important;

    .bz-date-filter-main {
      display: block !important;
    }

    .ml-2.dropdown {
      margin-left: 0 !important;
    }
  }

  .update-recurrence.paynow {
    margin-top: 1rem !important;

    .bz-content-text-row-new {
      grid-template-columns: 1fr;
    }

    .bz-colon.text-center {
      display: none;
    }

    .bz-content-title.text-right {
      text-align: left !important;
    }
  }
}

@media screen and (max-width: 620px) {
  .bz-pkg-filter-group .group-event-top-blk .bz-packages-btn-container>.k-button {
    padding: 0.34rem 0.54rem !important;
  }

  .eventsdatecalendarlist {
    grid-template-columns: 1fr;
    grid-gap: 10px;

    .btncalendar,
    .mr-2.d-flex.align-items-center {
      margin-right: 0 !important;
    }
  }


  .eventsborder {
    padding: 10px;
  }

  .customeapptcalendar {
    .bz-date-filter-main {
      display: block;
    }
  }

  .bz-upcoming-appointments-list-view .k-listview-content {
    max-height: inherit;
  }

  .appointmentstabs {
    margin-top: 15px;
  }

  .searchboxclientgroup {
    padding-top: 2%;
    max-width: max-content;
  }

}

@media screen and (max-width: 500px) {
  .packageslistparent.d-flex {
    display: block !important;
  }

  .packageslistparent.d-flex .bz-classes-searchicon {
    margin-left: 0 !important;
  }

  .packageslistparent .k-card-titlepurchase {
    margin-bottom: 15px;
  }

  .group-event-top-blk {
    justify-content: start !important;
  }

  .calendarmaincont,
  .calendar-main {
    width: 300px !important;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td,
  .k-calendar-monthview .k-link {
    width: 28px !important;
    height: 28px !important;
  }

  .bz-classList-container.k-card>.k-card-header .packagesfindandtypepackage {
    display: block !important;

    .bz-classes-searchicon {
      margin: 0 0 15px 0;
    }
  }

  .packagesfindandtypepackage .k-dropdown {
    width: 100%;
  }



}

.purchasesummaryappts.packagerelated {
  height: 84vh;
}

@media screen and (max-width: 525px) {

  .timedatepickerdiv.main,
  .timeday {
    flex-direction: column;
  }

  .popudropdown {
    top: inherit
  }
}

@media screen and (max-width: 400px) {

  .timesolstamaincontainer {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 340px) {

  .calendarmaincont,
  .calendar-main {
    width: 280px !important;
  }

  .ScheduleAppointmentRightCard .k-calendar-header {
    min-width: 280px;
  }
}

@media screen and (min-width: 767.98px) and (max-width: 976px) {
  .border-client {
    width: 100%;
    max-width: 100% !important;
  }
}

.membershipdailogqlinks .autocomplete-wrapper {
  max-width: 100%;
}


@media screen and (min-width: 977px) {



  .classselectedslots.popoverstaff.staff2 {
    top: 10px !important
  }


  .noclassselectedslots.popoverstaff.staff4,
  .noclassselectedslots.popoverstaff.staff5 {
    top: 10px !important
  }

}

@media screen and (max-width: 360px) {

  .bz-packages-subscription .bz-classes-search .k-autocomplete,
  .purchaseapptsquickslinks .k-card.bz-classList-container>.k-card-header .bz-classes-action>.bz-classes-search {
    width: 100% !important;
  }

  .filterpackagesdiv {
    margin-top: 15px;

    .k-button.filterpackages {
      margin-left: 0px;
    }
  }

  .timedatepickerdiv.popup {
    flex-direction: column;
  }
}

@media screen and (max-width: 976px) {



  .scheduleappts .col-md-8.flex-grow,
  .scheduleappts .ScheduleAppointmentRightCard {
    padding: 0 !important;
    padding-top: 15px !important;
  }


}


.providernameexisting,
.selectprovider .bz-trainers-card .media-body h5 {
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groupclassname {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;

  .tax-name-clip {
    max-width: 100%;
  }
}

.k-clear-value {
  height: 32px;
}

.bzc-sessions-list-card-new .bzc-sessions-list-row-new .section-3 {
  border-left: 1px dotted #ccc;
  padding-left: 10px;
}

.bzc-sessions-list-card-new .bzc-sessions-list-row-new .section-6 {
  min-width: 120px;
}

.pricesoltsgroupadd {
  color: #262727 !important;
  font-weight: 500;
  letter-spacing: 0px;
  font-size: 0.78rem;
  opacity: 0.6;
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-groupServices-list-new.group-serv-list {
  padding-top: 25px !important;

}

.bz-groupServices-inhouse.bz-packages-subscription .k-tabstrip>.k-content {
  padding: 0 !important;
}

.bz-packages-list-new .k-tabstrip-top>.k-tabstrip-items-wrapper>.k-tabstrip-items {
  text-align: left;
}

.bz-summary-menus-card .k-card-body .k-menu:not(.k-context-menu)>.k-item:last-child {
  margin-bottom: 32px;
}

.purchasesummaryappts .checkout-panelbar-wrapper .bz-plan-order-summary-checkbox label.k-checkbox-label {
  min-height: auto;
}

.linknames {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  display: block;
  overflow: hidden;
}

.promotion-overview .metric-list-row .reports-date-range:last-child {
  margin-bottom: 0;
}

.rewards .bz-main-provider>.bz-provider-name {
  padding-left: 0;
}

.payoutnameslist {
  display: flex;
  gap: 10px;
}

.weekly-hours-container {
  h2 {
    font-size: 14px;
    padding: .65rem 0;
  }
}

.timepickerday {
  span {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  label {
    font-weight: 600 !important;
  }
}

.unavailable {
  font-size: 12px;
  opacity: 0.5;
  min-width: 284px;
  padding-top: 2px;
}

.mt-4avail {
  margin-top: 4px;
}


.mt-4avail {
  margin-top: 0px;
}

.timesoltsadd .k-combobox {

  width: 100px;
  line-height: normal;
  height: 31px;
}

.timesoltsadd .k-combobox .k-select {
  border-color: #ffffff;
  color: #212529;
  background-color: #ffffff;
  padding: 1px 5px;
}

.timesoltsadd .k-combobox:focus-within {
  box-shadow: none;
  border-color: #ced4da;
}

.mt-4avail {
  margin-top: 0px;
}

.dialogworkinghours {
  max-height: 400px;
  overflow-y: scroll;
}

.timesolstamaincontainer {
  display: flex;
  flex-grow: 1;
}

.timesoltsadd .k-combobox {

  width: 100px;
  line-height: normal;
  height: 31px;
}

.timesoltsadd .k-combobox .k-select {
  border-color: #ffffff;
  color: #212529;
  background-color: #ffffff;
  padding: 1px 5px;
}

.timedatepickerdiv .k-button:hover::before {
  opacity: 0;
}

.timedatepickerdiv .k-button.k-flat::after {
  box-shadow: none;
}

.timesoltsadd .k-combobox:focus-within {
  box-shadow: none;
  border-color: #ced4da;
}

.bz-classes-layout .timesoltsadd input,
.bz-classes-layout .timesoltsadd input[type=text],
.timesoltsadd input[type=text],
.timesoltsadd input {

  font-size: 13px !important;
  padding: 5px;
  height: 30px;
}

.bz-classes-layout .timesoltsadd input,
.bz-classes-layout .timesoltsadd input[type=text] {

  font-size: 13px !important;
  padding: 5px;
}

.bzo-class-window .mt-4avail .k-button:hover {
  color: inherit;
}

.time-slot {
  display: flex;
  justify-content: start;
  gap: 5px;
  align-items: center;
}

.timesoltsadd .time-slot {
  margin-bottom: 10px;
}

.timesoltsadd .time-slot:last-child {
  margin-bottom: 0px;
}

.fontpopings {
  font-family: "Poppins", sans-serif;
}

.timepickerday label,
.weekly-hours-container h2 {
  font-weight: 600 !important;
}

.valuememebership {

  text-align: right;
}

.additionalfees .valuememebership {
  width: 100%;
}

.valuememebership .k-numerictextbox {
  width: 100%;
  // max-width: 115px;
  height: 35px;
  font-size: 13px;
}

.valuememebership .k-numerictextbox .k-input {
  font-size: 13px !important;
  font-weight: 400;
}

.bz-payout-grid.bzc-grid.grouppayouts colgroup>col:nth-child(6) {

  width: 150px;
}

.bz-payout-grid.bzc-grid.grouppayouts colgroup>col:nth-child(7) {
  width: 280px;
}

.service-para {
  display: flex;
  align-items: normal;
  margin-bottom: 10px;
  justify-content: space-between;
  // flex-direction: column;
  align-content: flex-end;
  gap: 10px;
}

.service-para.additionalfees {
  flex-direction: column;
  margin-bottom: 15px !important;
}

span.texteditapp {
  padding-left: 20px;
}

.uploaddocument .bz-content-text-row {
  align-items: start;
}

.k-calendar-infinite .k-calendar-header {
  padding: 0 1rem;
}

.k-calendar-infinite .k-calendar-th {
  width: 28px;
  height: 28px;
}

.relatedtransactions .k-grid-table {
  width: 100% ! important;
}

.filtertextpresetheader {
  width: 35%;
}

.filtersearchprestheader {
  width: 65%;
  display: flex;
}

@media (max-width: 1200px) {
  .presetheadermain {
    flex-direction: column;
  }

  .filtertextpresetheader,
  .filtersearchprestheader {
    width: 100%;

  }

  .packackagespreset .bz-classes-action {
    justify-content: start;
  }

  .packackagespreset .bz-classes-action .bz-classes-search {
    margin-left: 0;

  }

  .packackagespreset .bz-classes-action.searchboxrigh {
    justify-content: end !important;
  }
}

@media (max-width: 800px) {
  .filtersearchprestheader {
    flex-direction: column;
  }

  .bz-packages-action {
    width: 100%;
  }

  .filterdownload,
  .filtersr {
    width: 100% !important;
  }

  .purchaseapptsquickslinks .bz-classes-action .bz-classes-search,
  .bz-classes-search.searchclientpackages .k-autocomplete {
    width: 100% !important;
  }
}

.promotion-form-flex-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  grid-auto-columns: auto;
}

.promotion-form-flex-item {
  padding: 10px;
  text-align: center;
  font-size: 0.85rem;
  border: 1px solid #ccc;
  max-width: 100%;
  max-height: 140px;
}

.m600 {
  min-height: 580px;
}


.fc-list-event-time {
  vertical-align: middle !important;
}

.appointment-rates-table {
  font-size: 12px;

  tr.theader {
    td {
      font-weight: 600;
    }
  }
}

.appointment-rates-table {
  max-height: 350px;
}

.appointmentmandiv {
  height: 100%
}

.appointment-table {
  height: 70%;
  overflow-y: auto;

}

.appointment-footer {
  height: 15%;
  text-align: right;
  padding: 10px 0px;
  background: #fff;
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
}

.appoitmentheader {
  height: 10%;

}

.paddingappointment-delte {
  padding: 10px 0;
}

.addbtnappointments {
  min-width: auto !important;
  width: 30px;
  height: 30px;
}

.k-button.k-primary.submitappts {
  margin-right: 0;
}

.marappts {
  margin-top: 27px;
}


.bz-mybookings-dialog-box.apptdailogrates .k-dialog .k-dialog-content {
  padding-top: 0;
}

.custom-staff-class .k-list .k-item:hover {
  background: none !important;
}

.staffprovidername:hover {
  background-color: #e9ecef !important;
}

.footerfixingscreen .bzo-services-container.bz-footer-adjustment .k-listview-content {
  height: auto;

}

.bz-footer-adjustment {
  min-height: calc(100vh - 320px - 420px);
}

// .footerfixingscreen .bzo-services-container .k-listview-content {
//   height: 90vh;
// }
.footerfixingscreen {
  .bzo-services-container {
    margin-bottom: 2rem;
  }
}

.footerfixingscreen .k-listview-content .card.mb-3.pointer:hover {
  background-color: #ecf5ff;
}

.manage-declines-sections.delinquency {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr auto;
  gap: 16px;
}

.manage-declines-sections.delinquency .k-dropdown,
.managedeclinepayments .k-dropdown {
  background: none;
  height: 36px;
}

.manage-declines-sections.delinquency>.section-3 {
  text-align: center;
}

.managedeclinepayments .btn.btn-primary {
  height: 36px;
}

.managedeclinepayments .dflexBtn {
  gap: 10px;
}

.manage-declines .k-grid-table,
.manage-declines .k-grid-header table {
  width: 100% !important;
}

@media (max-width: 768px) {
  .manage-declines-sections.delinquency {
    grid-template-columns: 1fr;
  }

  .manage-declines-sections.delinquency>.section-3,
  .manage-declines-sections.delinquency>.section-4 {
    text-align: left;
    grid-column: span 1;
  }

  .manage-declines-sections.delinquency>.section-1,
  .manage-declines-sections.delinquency>.section-2 {
    grid-column: span 2;
  }

  .manage-declines.p-3 {
    padding: 0.7rem !important;
  }

  .manage-declines-sections.delinquency .add-new-btn .k-button {
    margin-right: 0 !important;
  }
}

@media (max-width: 680px) {
  .managedeclinepayments {
    margin-bottom: 15px;
  }

  .bz-classes-search {
    width: 100% !important;
  }
}

.affilatepaymentsettings {
  width: 100px !important;
  position: absolute;
  right: -20px;
}

.k-animation-container-shown {
  box-shadow: none;
}

.k-switch-label-on,
.k-switch-label-off {
  display: none;
}

.MultiRowLabel div {
  background-color: transparent;
}

.formlist .bz-main-provider>.bz-provider-name>h6 {
  color: #262727 !important;
}

.formlist .bz-service-name {
  font-weight: 400;
}

.formlist .bz-hyperlink {
  text-decoration: none;
}

.formlist .bz-hyperlink:hover {
  text-decoration: underline;
}

.rewards-form {
  .bz-content-title.align-self-baseline {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .summardeclinedpayments {
    .k-grid-footer-wrap {
      border: 0;
    }

    hr {
      display: none;
    }

    .k-grid-content {
      overflow-y: auto;
    }

    .k-grid-header {
      border-color: transparent;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }


    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(even) {
      background: #ffffff;
    }

    tr:nth-child(odd) {
      background: #F8F9FA;
      border-bottom: 1px solid #fff;
    }

    tfoot {
      width: 100%;
      display: block;

      tr {
        margin-top: 10px;
      }
    }

    tfoot tr:last-child {
      margin: 0;

      td:before {
        top: 15px
      }

      td:nth-of-type(1) {
        display: none;
      }

      td:nth-of-type(2) {
        display: none;
      }

      td:nth-of-type(3) {
        display: none;
      }

      td:nth-of-type(4) {
        height: 50px;
        border: 0;
        border-bottom: none !important;
      }

      td:nth-of-type(4):before {
        content: "Total Amount";
      }
    }

    td {

      border: none;
      border-bottom: 1px solid #eee !important;
      position: relative;
      padding-left: 50%;
    }

    td:before {

      position: absolute;
      font-size: 13px;
      font-weight: 600;
      top: 10px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }


    td:nth-of-type(1):before {
      content: "Service Type";
    }

    td:nth-of-type(2):before {
      content: "Clients";
    }

    td:nth-of-type(3):before {
      content: "Transactions";
    }

    td:nth-of-type(4):before {
      content: "TotalAmount";
    }
  }



}


@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  .declineditemstransations {
    .k-grid-content {
      overflow-y: auto;
    }

    .k-grid-header {
      border-color: transparent;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }


    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }


    tr:nth-child(even) {
      background: #ffffff;
    }

    tr:nth-child(odd) {
      background: #F8F9FA;
      border-bottom: 1px solid #fff;
    }

    td {

      border: none;
      border-bottom: 1px solid #eee !important;
      position: relative;
      padding-left: 50%;
    }

    td:before {

      position: absolute;
      font-size: 13px;
      font-weight: 600;
      top: 10px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }


    td:nth-of-type(1):before {
      content: "Client Name";
    }

    td:nth-of-type(2):before {
      content: "Transaction Date";
    }

    td:nth-of-type(3):before {
      content: "Payment Made For";
    }

    td:nth-of-type(4):before {
      content: "Service Name";
    }

    td:nth-of-type(5):before {
      content: "Amount";
    }

    td:nth-of-type(6):before {
      content: "Status";
    }

    td:nth-of-type(7):before {
      content: "Transaction Id";
    }


  }

}

.ft-14 {
  font-size: 14px !important;
}

.bgtimesolts {
  background: #F5FDFF;

  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  ;
}

.ft-16 {
  font-size: 16px !important;

}

.colorgreyforms {
  color: #797F87;
}

button.actiobtnform.editform.align-items-center {
  padding-left: 0;
}



.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  cursor: pointer;
}

.form-category-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.form-category-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border: 1px solid #F1F1F4;
  border-radius: 8px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  gap: 15px;
}

.form-category-item:hover {
  background-color: #ecf5ff;
}

.form-category-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #008DB9;
  border-radius: 50%;
}

.form-category-icon {
  font-size: 1rem;
  color: #fff
}

.form-category-info {
  flex: 1;
}

.form-category-title {
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #071437;
  font-size: 14px;
}

.form-category-description {
  font-size: 13px;
  color: #263238;
  margin: 0;
}

.bz-forms-list-new.formlist.share-forms-listview {
  grid-template-columns: 1fr 1fr 1fr;
  border-color: #eaeaea !important;
  box-shadow: none !important;
  display: grid;
}

.manage-affiliate-container .k-grid-content {
  overflow-y: auto;
}

.payoutdailogbox.promotionaddpayouts {
  .bz-content-text-row {

    margin-bottom: 15px;
  }

  input[type="tel"],
  input[type="text"] {
    font-size: 14px !important;
  }

}

.rewards-modal-form.payoutreward {
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.payoutformbody {
  height: calc(100% - 35px);

  overflow-y: auto;
  padding: 0 15px;
}

.bz-form-label-new {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  display: block;
  margin-bottom: 8px !important;
  line-height: 16px;
  padding: 0;
}

.appsidebaronlinepage.App-body {
  padding: 0 2rem;
  height: calc(100% - 300px);
  overflow-y: auto;
  padding-bottom: 2rem !important;

  .bz-online-main-row {
    height: 100vh;
    position: relative;
  }

  .bz-online-main-row>.bz-content-area {
    top: 80px;
    position: relative;
    margin-left: 19.5rem;

  }

  .bz-online-main-row>.bz-main-menu-area {
    top: 80px;
    height: calc(100vh - 80px);
    position: fixed;
  }

  .bz-aboutus-page {
    margin-top: 80px;
    min-height: 450px;
  }

  .bz-noaboutus-page {
    margin-top: 80px;
    min-height: 450px;
  }

  .bz-provider-section {
    margin-top: 80px;
  }

  .scheduledapptsheader {
    margin-top: 80px;
  }

  .scheduledapptsheader .scheduleappts {
    padding-top: 20px;
  }

  .bz-checkout-service,
  .bz-checkout-online-class,
  .clientdashboardheaderqlinks {
    margin-top: 80px;
  }

  .purchasepackgecheckout {
    margin-top: 80px !important;
  }
}

.user-login.appsidebaronlinepage.App-body .clientdashboardheaderqlinks {
  margin-top: 0px;
}

.checkoutheading {
  padding: 0 10px;
}

.appsidebaronlinepage.App-body.user-login {
  height: auto;

}

.prospectdetails.bzc-grid .k-grid-header .k-header,
.manage-affiliate-container-grid.payout .prospectdetails.bzc-grid .k-grid-header .k-header {
  background: #fff !important;
  border-width: 0 0px 3px 0;
  font-size: 0.78rem;
  border-bottom: 3px solid #e7e7e7 !important;
  font-weight: 500;
  padding: 14px 8px;

}

.prospectdetails.bzc-grid .k-grid-content tr:hover,
.bzc-grid .k-grid-content tr.k-alt {
  background-color: #F5F5F5;
}

.prospectdetails.bzc-grid .k-grid-content td {
  padding: 14px 8px;
}

.prospectdetails.bzc-grid .k-grid-header,
.prospectdetails.bzc-grid .k-grid-content,
.prospectdetails.bzc-grid .k-grid-content td,
.manage-affiliate-container-grid.payout .prospectdetails.bzc-grid .k-grid-content td {
  border-width: 0;
}

.manage-affiliate-container-grid.payout .prospectdetails.bzc-grid.payouts .k-grid-content td {
  font-size: 0.8125rem;
}

.prospectdetails .k-cell-inner>.k-link {
  padding: 0 0.5rem;
}

.promotionsboddy .btn-link {
  font-size: 13px;
  font-weight: 600;
}

.card-affilates {
  background-clip: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
  border-radius: 0px;
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.5;
  box-shadow: 0 2px 18px rgba(0, 0, 0, .02) !important;
  border-bottom: 1px solid #eaedf2 !important;
  padding: 15px 0;
  margin: 0 15px 0px 15px;
}

.card-affiates-header {
  border: 1px solid #9F9F9F;
  border-radius: 7px;
  padding: 0.8rem 0.5rem !important;
  box-shadow: 0 3px 8px #00000029;
}

@media only screen and (min-width: 480px) {}

.bz-staff-details .d-inline-flex {
  gap: 10px;
}

.affialtenames {
  color: #262727 !important;
  font-size: 0.78rem !important;
  font-weight: 500 !important;
  color: #262727 !important;
}

.affilatetitle {
  color: #8f9bb3 !important;
  font-size: 0.78rem !important;
  font-weight: 500 !important;
  text-align: left;
}

.activeaffilates {
  font-size: 0.78rem !important;
  font-weight: 500 !important;
  color: #08ae0f !important;
}

.affiliate-badge {
  font-size: .78rem !important;
  font-weight: 500 !important;
  padding: .3125rem .375rem;
  border: 1px solid #acd9ae;
  border-radius: 0.25rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: default;
}

.affiliate-active-badge {
  color: #17c653 !important;
  background-color: #eafff1;
  border-color: #acd9ae;
}

.affiliate-inactive-badge {
  color: #78829d !important;
  background-color: #dbdfe9;
  border-color: #f9f9f9;
}

.affiliate-invited-badge {
  color: #f6b100 !important;
  background-color: #fff8dd;
  border-color: #f6b10033;
}

// .btn-white {
//   color: #4b5675;
//   background-color: #fff;
//   border-color: #dbdfe9;
//   border: 1px solid #dbdfe9;
// }

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .bz-staff-details {
    flex-direction: column;
    align-items: start !important;
  }
}

@media (max-width: 768px) {
  .affilatetitle {
    display: inline-block !important;
    padding-right: 10px !important;
    width: 120px !important;
  }

  .affilatetitle {
    margin-top: 10px !important;
  }

  .mainaffilageserach {
    flex-direction: column;
    gap: 15px;

    .bz-classes-search {
      width: 100%;
    }

    .blockserachaffilates {
      width: 100%;
      justify-content: space-between !important;
    }

    .promotiontype {
      width: 100%;
      margin-right: 0 !important;
    }

    .promotiontypesearch {
      width: 100%;
    }
  }

}

@media only screen and (min-width: 300px) {
  .affialtenames {
    word-break: break-all;
    margin-right: 3rem;
    display: inline-block;
  }
}

.min62px {
  min-width: 62px;
}

.border-none {
  border: none !important;
}


@media (max-width: 680px) {

  .affialtebackbtndiv {
    align-items: start !important;
  }

  .edit-btn-container {
    text-align: right;
  }
}

@media (max-width: 500px) {

  .affialtebackbtndiv {
    flex-direction: column;
  }

  .headerleftprofileback {
    padding-left: 1.5rem !important;
  }
}

@media (max-width: 400px) {
  .searchleft .k-dropdown {
    margin-bottom: 15px;
  }

  .searchleft,
  .searchright {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .bzo-services-container .k-listview-content .buz-online-list-view-new.bz-membership-rate {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.footerfixingscreen .bz-purchaseclass-scroll.bz-footer-adjustment>.row>.col-md-12.col-lg-12 .bz-purchase-class-list .k-listview .k-listview-content {
  height: auto;
}

.footerfixingscreen .bz-purchaseclass-scroll>.row>.col-md-12.col-lg-12 .bz-purchase-class-list .k-listview .k-listview-content {
  height: 90vh;
}

@media (max-width: 576px) {
  .rewards .bz-forms-list-new {
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .mainaffilageserach {
    justify-content: space-between !important;
  }
}

@media (max-width: 768px) {
  .mainaffilageserach .blockserachaffilates {
    display: block !important;
  }

  .bzo-footer {
    position: unset;
  }

  .bzo-footer .bzo-timings-sec {
    grid-template-columns: 1fr 1fr;
  }

  .public-layout .appsidebaronlinepage.App-body {
    padding-bottom: 0px !important;
  }

  .bzo-footer .bz-bussiness-hours-row {
    flex-direction: column;
  }

  .bzo-footer .bz-bussiness-hours-row>.section-1 {
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .bzo-footer .bz-bussiness-hours-row>.section-1>.hours-icon>.svg-inline--fa {
    font-size: 1.2rem;
  }

  .bzo-footer .bz-bussiness-hours-row>.section-2 {
    padding-left: 0;
    border-left: 0px solid #eee;
    margin-left: 0;
  }
}

.manage-affiliate-container-grid.payout .k-grid .k-grid-footer td {
  border: 0;
}

.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: .65rem;
}

.symbol.symbol-45px>img {
  width: 45px;
  height: 45px;
}

.symbol>img {
  width: 100%;
  flex-shrink: 0;
  display: inline-block;
  border-radius: .65rem;
}

.ftuserpromotions {
  color: #252f4a;
}

.bz-provider-desc {
  color: #99a1b7;
}

.bz-userpcont {
  border-bottom: 1px dashed #f1f1f4;
  padding: 15px 0;
}

.borderradiusshaow {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #F1F1F4;
  border-radius: 0.75rem;
  padding: 15px;
  max-height: 480px;
  overflow: hidden;
}

.w-100.height400px {
  max-height: 400px;
  overflow: auto;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .affilateprogrampayouts {
    .k-grid-footer-wrap {
      border: 0;
    }

    hr {
      display: none;
    }

    .k-grid-content {
      overflow-y: auto;
    }

    .k-grid-header {
      border-color: transparent;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }


    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(even) {
      background: #ffffff !important;
    }

    tr:nth-child(odd) {
      background: #F8F9FA;
      border-bottom: 1px solid #fff;
    }

    tfoot {
      width: 100%;
      display: block;

      tr {
        margin-top: 10px;
      }
    }

    tfoot tr:last-child {
      margin: 0;

      td:before {
        top: 15px
      }

      td:nth-of-type(1) {
        display: none;
      }

      td:nth-of-type(2) {
        display: none;
      }

      td:nth-of-type(3) {
        display: none;
      }



    }

    td {

      border: none;
      border-bottom: 1px solid #eee !important;
      position: relative;
      padding-left: 50% !important;
      min-height: 35px;
    }

    td:before {

      position: absolute;
      font-size: 13px;
      font-weight: 600;
      top: 10px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }


    td:nth-of-type(1):before {
      content: "Status";
    }

    td:nth-of-type(2):before {
      content: "Notes";
    }

    td:nth-of-type(3):before {
      content: "Updated On";
    }

    td:nth-of-type(4):before {
      content: "Updated By";
    }
  }



}

.phonenumberaffialtes {
  min-width: 160px;
}

.bz-joined-date {
  min-width: 12rem;
}

.statusaffilates {
  min-width: 7rem;
}

.actionaffilates {
  min-width: 8rem;
}

@media only screen and (min-width: 480px) {
  .bz-staff-details .mb-3.mb-sm-0.staff-name-blk:first-child {
    flex: 0 0 300px;
  }
}

.coloraffialteshover {
  color: #0082ca !important;
}

.bz-week-days {
  font-weight: 600;
}

@media only screen and (max-width: 360px) {
  .bzo-footer .bzo-timings-sec {
    grid-template-columns: 1fr;
  }

}

.buzops-fullcalendar .fc-timegrid-event .fc-event-time {
  flex-direction: column;
  align-items: start !important;
}

.buzops-fullcalendar .fc-v-event .fc-event-title {
  font-size: 12px !important;
  padding-left: 0 !important;
}

.fc .fc-timegrid-slot {
  height: 4em !important;
}

.fc-v-event .fc-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}

.timeslottext {
  font-weight: 300;
  font-size: 12px;
}

.manage-affiliate-container {
  max-height: 80vh;
  overflow-y: auto;
}

@media screen and (max-width: 991px) {

  .appsidebaronlinepage.App-body .bz-online-main-row>.bz-content-area {
    margin-left: 0;
    top: 62px
  }
}

.bz-manage-alert {
  .manage-tax-container {
    max-width: 80rem;

    .alert-form-sec:first-of-type {
      margin-bottom: 2rem;
    }
  }
}


.icon-container {
  position: relative;
  display: inline-block;
}

.dropbtn-buzops {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent !important;
}

.dropdown-buzops {
  position: relative;
  display: inline-block;
}

.dropdown-content-buzops {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
  border-radius: 5px;
  overflow: hidden;
  transition: opacity 0.3s ease;
  opacity: 0;
  right: 0;
  top: inherit !important;
  left: inherit !important
}

.dropdown-content-buzops a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s;
}

.dropdown-content-buzops a:hover {
  background-color: #f1f1f1;
}

.dropdown-content-buzops.show {
  display: block;
  opacity: 1;
}

.mih-26 {
  min-height: 25px;
}

.affilategridmain div.mb-0.py-0:last-child,
.affilate-payout-reward div.offeritemslist:last-child,
.affilate-payout-rewards div.payoutrewardsitems:last-child {
  margin-bottom: 3.5rem !important;
}

.header-class {
  text-transform: capitalize;
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  color: #008db9;
  font-weight: 500;
}

.searchclientglobal .k-list .k-item:hover {
  background: #f5f5f5;
}

.livestream-cont {
  padding-top: 1.5rem !important;

  .bzc-checkins-list.paddingRight {
    >.k-listview-content {
      padding-right: 0.5rem;
    }
  }
}

.live-stream-right-search .locationicon {
  padding-bottom: 10px;
}

// .share-client-form-sections {
//   display: grid;
//   grid-template-columns: 0.75fr 1.2fr;
// }

.clientshareformshareclient {
  display: flex;
  align-items: center;
  max-width: calc(100% - 9rem);

  .formshareclientname {
    white-space: nowrap;
    max-width: calc(100% - 8rem);
    line-height: 1.3;

    .tax-name-clip {
      max-width: 100%;
    }
  }
}

.shareclient-inlineblock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .column-1-section {
    min-width: 145px;
  }

  .column-2-section {
    flex-grow: 1;
  }
}

.minw110 {
  min-width: 110px;
}

@media screen and (max-width: 640px) {

  .shareclient-inlineblock {
    flex-direction: column;

    .purchased-dots {
      display: none;
    }

    .bdtitleforms {
      font-weight: 600;
    }
  }

}

@media screen and (max-width: 340px) {
  .clientshareformshareclient {
    flex-direction: column;
    gap: 0;
  }

  .minw110 {
    text-align: left;
    padding-left: 0 !important;
  }
}

.sharecustomerformtemplate .autocomplete-wrapper {
  max-width: none;
}

.bz-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setting-cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
}

.appsidebaronlinepage .bz-forms-list-new.formlist.share-forms-listview {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: start;

}

.appsidebaronlinepage .actiobtnform {
  padding: 0;
}

.bz-paynow-page .card .bz-cancellation-card-row.paynowcont .column-6 {
  width: 350px !important;
  padding-top: 1rem;
}

.bz-paynow-page .card .bz-cancellation-card-row.paynowcont .column-5 {
  padding-top: 1rem;
}

.bz-paynow-page .card .bz-cancellation-card-row.paynowcont .column-6 .k-upload .k-dropzone,
.card-body.groupcancellation .k-upload .k-dropzone,
.paynowinactive.k-upload .k-dropzone {
  padding: 0;
}

.bz-paynow-page .card .bz-cancellation-card-row.paynowcont .column-6 .k-upload .k-upload-button,
.card-body.groupcancellation .k-upload .k-upload-button {
  margin: 0;
}

.bz-paynow-page .card .bz-cancellation-card-row.paynowcont .column-6 .k-upload .k-dropzone .k-dropzone-hint {
  margin-left: 1rem;
}

.mt-15 {
  margin-top: 20px;
}

.referencedocument .k-upload .k-dropzone {
  padding: 0;
}

.referencedocument .k-upload .k-upload-button,
.paynowinactive.k-upload .k-upload-button {
  margin-left: 0;
}

.wmax320 {
  max-width: 320px;
}

.wmax320 .col-md-12 {
  margin-bottom: 10px;
}

.add-new-btn.otherpaybtn .k-button {
  margin-right: 0 !important;
}

.k-upload-status .k-button.k-flat.k-upload-action:hover {
  color: #6c757d;
  background: none !important;
}

.k-upload-status .k-upload-action:focus {
  border: 0;
}

.max280px {
  max-width: 305px;
}

.custom-upload-button .k-button {
  background-color: #008db9;
  border: none;
  color: white;
}

.custom-upload-button .k-button:hover {
  background-color: #008db9;
  border: none;
  color: white;
}

.bz-add-group-service fieldset,
.addsession fieldset {
  border: 1px solid #e4e4e4;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
}


.bz-add-group-service legend,
.addsession legend {
  font-weight: 600;
  font-size: 14px;
  background: #edf5f7;
  color: #265d6e;
  width: auto;
  min-height: 32px;
  padding: 7px;
  border-radius: 5px;
}

.addbtnclass.k-button {
  margin-left: 0;
}

.badge-status-members {
  background-color: #2d85a1;
  color: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: none;
  margin-left: 0;
  margin-top: 5px;
  min-width: 100px;
}

.client-book-online-checkbox {
  >div {
    margin-bottom: 0.25rem;

    >span {
      display: flex;

      >label {
        line-height: 20px;
      }
    }
  }
}

.filterpackagesdiv {
  .k-button.bz-icon-button {
    min-width: 2.125rem !important;
    min-height: 2.125rem;
  }

  .marginLeft10px {
    margin-left: 10px !important;
  }
}

.bz-confirm-dialog {
  .k-dialog {
    max-width: 27rem;
  }

  .k-actions {
    background-color: #F3F5F7;
    padding: 0.5rem 1rem !important;
    border: 0;
    gap: 0.75rem;
  }

  .dialog-btn {
    padding: 0.3rem 0.75rem !important;
    min-width: 5rem;
    min-height: initial;
  }
}

.appointmentlistview .card.appthover:hover,

.card.appthover:hover {
  border-color: #008db9;
}

.text-blue-500 {
  color: #008db9;
}

.existingslots:hover {
  border-color: #008db9 !important;
}

.troubleshootseetings {
  border: 1px solid #eee;
  padding: 10px 20px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.min170px.k-button.k-primary {
  min-width: 170px;
}


.settings-title,
.settings-title:focus {
  background: transparent;
  border: none;
  width: 100%;
  outline: 0;
}

.ghllevelbuttons {
  gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding-bottom: 15px;
}

.ghllevelbuttons:last-child {
  padding-bottom: 0;
}

.ghllevelbuttons .button-description {
  flex-grow: 1;
  color: #062a35;
  margin-bottom: 0;
  user-select: none;

}


.border-bottom-ghl {
  border-bottom: 1px solid #e7e7e7 !important
}

@media (max-width: 768px) {
  .ghllevelbuttons {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .button-description {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .ghllevelbuttons {
    gap: 8px;
  }

  .button-description {
    font-size: 14px;
  }
}


.nohover.bz-badge-btn.bz-badge-info:hover,
.nohover.bz-badge-btn.bz-badge-info:focus {
  border-color: #D7F2F9 !important;
}

.k-form .addappformfield .k-form-field {
  margin-top: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  gap: 10px;
}

@media (max-width:1024px) {

  .grid-container {
    grid-template-columns: 150px 1fr 1fr;
  }
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .k-form .nonpaid .k-form-field {
    margin-top: 0;
  }

  .selectlocation {
    margin-top: 0px;
  }

}



@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 420px) {

  .k-form .grid-container .k-textbox,
  .k-form .grid-container .k-dropdown {
    width: 80% !important;
  }
}

@media (max-width: 360px) {

  .k-form .grid-container .k-textbox,
  .k-form .grid-container .k-dropdown {
    width: 275px !important;
  }
}


.bzo-services-container .k-listview-content .buz-online-list-view-new.bz-membership-rate .bz-service-select-btn-new {
  float: none;
}

.apptcardslect {
  display: flex;
  gap: 20px;
}

.ft13 {
  font-size: 13px;
}

.popoverstaffirst,
.popoverstaff {
  max-width: 360px;
}

.lablesignoff {
  font-weight: 600;
  line-height: normal;
  display: inline-block;
  font-size: 13px;
  color: #262829;
  padding-bottom: 5px;
}

.k-button.k-state-selected,
.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-button.k-time-accept,
.k-calendar .k-calendar-td.k-state-selected:hover .k-link,
.k-calendar .k-calendar-td.k-state-selected.k-state-hover .k-link {
  border-color: #008db9;
  color: white;
  background-color: #008db9;
}

.k-calendar .k-nav-today {
  color: #008db9;
}

.viewmorecontent {
  color: #008db9 !important;
}


.tierbased-rewards-form {
  .k-numerictextbox {
    width: 100% !important;
    padding: 0 !important;
  }
}

.bz-addCard-dialog.managedeclinedailoog {
  z-index: 99999 !important;
}

.service-titlename2.client-order-summaryheading {
  width: 167px !important;
}


.add-appts-buttons-class {
  background-color: #F4F9FF;
  border-radius: 8px;

  button {
    border: none;
    padding: 2px;
    width: 150px;
    border-radius: 6px;
    font-size: 14px !important;
    font-weight: 600;
    padding: 6px;
  }
  button:focus{
    outline: none;
  }

  button.selected {
    background-color: $primaryColor;
    color: white;
  }


  button.notSelected {
    background-color: #E4F0FE;
    color: #000;
  }

  button.notSelected:hover {
    background-color: $primaryColor;
    color: white;
  }
}

.ft12 {
  font-size: 13px;
}
.ft500{
  font-weight: 500;
}

.addpaymentprofiledialog{
  border: 1px solid #eee;
    padding: 15px;
    margin: 15px 0;
}
@media screen and (max-width: 480px) {
.mobileapptsdialog{
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
}

}

@media screen and (max-width: 360px) {
  .w-100.paymentdropma{
    width: 170px !important;
  }
}

@media screen and (max-width: 320px) {
  .w-100.paymentdropma{
    width: 155px !important;
  }
}


.paymentdropcont{
  width: 60%;
}